//slugs constants here
export const JOB_TYPE_SLUG= "job_type"
export const EMPLOYMENT_STATUS_SLUG= "employment_status"
export const RECRUITMENT_STATUS_SLUG= "recruitment_status"
export const DESIGNATION_SLUG= "designation"
export const CURRENCY_SLUG= "currency"

// all regex constants will be declared here
export const EMAIL_REGEX =
  /^([a-zA-Z0-9_\-.]+)@([a-zA-Z0-9_\-.]+)\.([a-zA-Z]{2,5})$/;
export const PASSWORD_REGEX =
  /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
// export const PHONE_REGEX = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
export const PHONE_REGEX = /^(?:\+)?\d{1,}\s?\(?\d{1,}\)?[-.\s]?\d{1,}[-.\s]?\d{1,}[-.\s]?\d{1,}$/;

export const AADHAR_REGEX = /^[2-9]{1}[0-9]{3}\s{1}[0-9]{4}\s{1}[0-9]{4}$/;
export const PAN_REGEX = /^([a-zA-Z]{5})(\d{4})([a-zA-Z]{1})$/;
export const ACCOUNT_NO_REGEX = /^[0-9]{1,}$/;
export const CIN_REGEX =
  /^([L|U]{1})([0-9]{5})([A-Za-z]{2})([0-9]{4})([A-Za-z]{3})([0-9]{6})$/;
export const WO_PO_NO_REGEX = /(?=[A-Z0-9]*[A-Z]{3})[A-Z0-9]{4,}/;
export const IFSC_REGEX = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const SWIFT_REGEX = /^[a-zA-Z]{4}[a-zA-Z]{2}[a-zA-Z0-9]{2}[XXX0-9]{0,3}/;
export const UPI_REGEX = /^[\w.-]+@[\w.-]+$/;

export const NAME_REGEX = /^[A-Z ]{2,}$/i;
export const PINCODE_REGEX = /^[1-9][0-9]{5}$/;
export const GST_REGEX =
  /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/;
export const PRICE_REGEX = /^\d*\.?\d*$/;



export const BLOOD_GROUP_REGEX = /^(A|B|AB|O|a|b|ab|o)[+-]$/;

// MODULE SLUGS FOR CODE SETUP

export const QUOTATION = "QUOTATION";
export const INVOICE = "INVOICE";
export const EMPLOYEE = "EMPLOYEE";
export const PROJECT = "PROJECT";
export const TICKET_MANAGEMENT = "TICKET_MANAGEMENT";
export const SALARY_SLIP = "SALARY_SLIP";
export const POS = "POS";
export const PO = "PO";
