// eslint-disable-next-line import/no-anonymous-default-export
export default {
  translation: {
    title_company_profile: "Company Profile",
    title_bank_detail:"Bank Details",
    title_account_setup:"Account Setup",
    title_signin: "Sign in",
    title_signup: "Sign up",
    title_dashboard: "Dashboard",
    title_forget_pass: "Forget Password",
    title_reset_pass: "Reset your password",
    title_quotation_list: "Quotation List",
    title_quotation_form: "Quotation Form",
    title_user_list: "User List",
    title_user_form: "User Form",
    title_roles_list: "Roles And Rights List",
    title_roles_form: "Roles And Rights Form",
    title_customer_list: "Customer List",
    title_customer_form: "Customer Form",
    title_invoice_list: "Invoice List",
    title_invoice_form: "Invoice Form",
    title_invoices_list: "Invoices List",
    title_employ_onboarding_list: "Employ onboarding List",
    title_employ_onboarding_form: "Employ onboarding Form",
    title_holidays_list: "Holidays List",
    title_holidays_form: "Holidays Form",
    title_attendance: "Attendance",
    title_project_list: "Project List",
    title_project_form: "Project Form",
    title_ticket_list: "Tickets",
    title_ticket_form: "Tickets Form",
    title_recruit_list: "Recruit List",
    title_recruit_form: "Recruit Form",
    title_gst_master_list: "GST Master List",
    title_gst_master_form: "GST Master Form",
    title_reports: "Reports",
    title_code_setup_list: "Code setup List",
    title_code_setup_form: "Code setup Form",
    title_add_master_list: "Add Masters List",
    title_add_master_form: "Add Masters Form",
    title_admin_list: "Admin List",
    title_admin_form: "Admin Form",
    title_pdf: "PDF",
    title_download: "Download",

    next: "Next",
    prev: "Previous",
    verify_email: "Verify Email",
    change_pass: "Change Pass",
    send_link: "Send link",
    submit: "Submit",
    drag_here: "Drag here or click to upload",
    default_currency: "Default Currency",
    pan_no: "PAN No",

    //Common Labels, errors here
    save: "Save",
    menu: "Menu",
    read: "Read",
    create: "Create",
    update: "Update",
    delete: "Delete",
    edit: "Edit",
    start:"Start",
    end:"End",
    created_on: "Created Date",
    updated_on: "Last modified Date",
    action: "Action",
    total: "Total",
    date: "Date",
    phone: "Contact no.",
    country: "Country",
    state: "State",
    city: "City",
    pincode: "Pincode",
    please_select: "Please select",
    terms_and_conditions: "Terms & conditions",
    hourly: "Hourly",
    monthly: "Monthly",
    email: "Email Id",
    fixed: "Fixed",
    status: "Status",
    date_of_birth: "Date of Birth",
    user_name: "User name",
    account_setup: "Account setup",
    change_password: "Change password",
    name:"Name",
    start_date:"Start date",
    end_date:"End date",
    year:"Year",
    logout: "Logout",

    bank_name:"Bank name",
    branch_name:"Branch name",
    account_no:"Account number",
    account_name:"Account name",
    ifsc_code:"IFSC code",
    beneficiary_name:"Beneficiary name",
    swift_code:"Swift code",
    upi_id:"UPI ID",

    user_not_active: "User is not active",

    // PAYMENT RELATED
    overdue: "Overdue",
    paid: "Paid",
    unpaid: "Unpaid",
    debit: "Debit",
    amount_received: "Amount Received",
    amount_remaining: "Amount Remaining",
    amount_paid: "Amount Paid",

    please_enter_milestone: "Milestone Here...",

    select_all: "Select All",
    clear_all: "Clear All",

    ticket_dashboard: "Tickets Dashboard",
    account_department_module: "Account Department Module",
    account_department: "Account",
    user_management_module: "User Management Module",
    user_management: "User Management",
    hr_department: "HR Department",
    project_management: "Project Management",
    purchase_module: "Purchase Module",
    inventory_module: "Inventory module",

    // PROFILE RESOURCES
    message_login_successful: "Login success..",
    message_login_failed: "Login failed..",
    message_company_profile_update_successful: "Profile updated.",
    message_bank_details_update_successful: "Bank details updated.",
    message_verify_email_successful: "reset pass link sent to email",
    message_company_profile_not_found:"company profile not found",
    message_bank_details_not_found:"bank details not found",

    error_invalid_name:"Name should be 2 characters long and contain only letters",
    error_empty_phone: "Please enter contact no.",
    error_empty_address: "Please enter address",
    error_select_country: "Please choose country",
    error_select_state: "Please choose state",
    error_select_city: "Please choose city",
    error_empty_pincode: "Please enter pincode",
    error_empty_terms: "Please enter terms & conditions",
    error_empty_milestone: "Please enter milestone",
    error_select_working_policy: "Please select working policy",
    error_empty_price: "price should accept only numbers",
    error_invalid_price: "Enter valid price",
    error_empty_pan: "Please enter PAN No",
    error_invalid_pan: "Please enter valid PAN No",
    error_empty_default_currency: "Please select currency",

    error_empty_bank_name:"Please enter bank name",
    error_empty_branch_name: "Please enter branch",
    error_empty_account_number:"Please enter account number",
    error_empty_account_name:"Please enter account name",
    error_empty_ifsc_code:"Please enter IFSC code",
    error_empty_beneficiary_name:"Plase enter beneficiary name",
    error_empty_swift_code:"Please enter swift code",
    error_empty_upi_id:"Please enter upi id",

    error_invalid_bank_name:"Bank name should be 2 characters long and contain only letters",
    error_invalid_branch_name:"Branch name should be 2 characters long and contain only letters",
    error_invalid_account_number:"Please enter valid account number",
    error_invalid_account_name:"Account name should be 2 characters long and contain only letters",
    error_invalid_ifsc_code:"Please enter valid IFSC code",
    error_invalid_beneficiary_name:"Bank name should be 2 characters long and contain only letters",
    error_invalid_swift_code:"Please enter valid swift code",
    error_invalid_upi_id:"Please enter valid upi id",
    
    error_select_currency: "Please select currency",

    // error_invalid_phone: "Please enter valid 10 digit no.",
    error_invalid_phone: "Please enter valid contact no.",
    error_invalid_pincode: "Please enter valid pincode",

    //Auth labels
    signin: "Sign In",
    signup: "Sign Up",
    go_back: "Go Back !",
    donot_have_account: "Don't have any Account ?",
    already_have_account: "Already have Account ?",
    forgotPassword: "Forgot Password",

    /*Admin PAGE*/
    admin: "Admin Setup",
    add_new_admin: "Add Admin",
    update_admin: "Update Admin",

    message_admin_update_successful:"Admin updated",
    message_admin_not_found:"Admin not found",
    message_admin_create_successful: "Admin created Successfully.",
    message_admin_deleted_succeful: "Admin deleted Successfully.",

    /*Profile PAGE*/
    signature: "Signature",

    /*RESOURCES FOR DASHBOARD */
    total_tickets:"Total Tickets",

    /*Quotation PAGE*/
    quotation: "Quotation",
    add_new_quotation: "Add Quotation",
    update_quotation: "Update Quotation",
    quotation_items: "Quotation Items",
    company_name: "Company Name",
    address: "Address",
    project_name: "Project Name",
    project_description: "Project Description",
    technology: "Technology",
    project_timeline:"Project Timeline",
    quotation_no: "Quotation no.",
    gst_no: "GST No.",
    description: "Description",
    milestone: "Milestone",
    working_policy: "Working Policy",
    price: "Price",
    tax: "Tax",
    igst_percent: "IGST(%)",
    cgst_percent: "CGST(%)",
    sgst_percent: "SGST(%)",
    discount: "Discount",
    note: "Notes",

    error_empty_project_timeline_unit_qty:"Please enter timeline",

    error_invalid_project_timeline_unit_qty:"entered number is too long...",

    error_select_project_timeline_unit:"Please select unit",

    /**INVOICES PAGE */
    invoice: "Invoice",
    gst_and_branch: "GST/Branch",
    add_new_invoice: "Add New Invoice",
    update_invoice: "Update Invoice",
    entity_id: "Customer Name",
    invoice_no: "Invoice No.",
    invoice_date: "Invoice Date",
    due_date: "Due Date",
    po_number: "PO Number",
    payment_terms: "Payment terms",
    notes: "Notes",
    error_empty_customer_name: "Please select customer name",
    error_select_gst_and_branch: "Please select GST/Branch",
    error_empty_project_id: "Please select project",
    error_empty_project_name:"Please enter project name",
    error_empty_invoiceNo: "Please enter invoice no",
    error_empty_invoice_date: "Please select invoice date",
    error_empty_due_date: "Please select due date",
    error_empty_po_number: "Please enter po number",
    error_empty_payment_terms: "Please enter payment terms",
    update_payment: "Update Payment",
    error_empty_transactionNo: "Please enter transaction no or swift no",
    error_empty_transactionDate: "Please select transaction date",
    error_empty_amountReceived: "Please enter amount received",
    project_code_or_name: "Project Code / Project Name",
    transaction_or_swift_no: "Transaction No. / Swift No.",    
    add_payment: "Add Payment",
    error_empty_terms_condition: "Please enter terms and condition",
    error_empty_notes: "Please enter notes",
    error_range_advance_percentage: "Advance % must be between 0 to 100",
    error_range_discount_percentage: "Discount % must be between 0 to 100",
    client_name: "Client Name",
    select: "---Select---",
    inv_date: "Inv. Date",
    customer_name: "Customer Name",
    transaction_date: "Transaction date",

    item_name: "Item Name",
    unit: "Unit",
    qty: "Qty",
    invoice_items: "Invoice Items",
    invoices_list: "Invoices List",
    cancelled: "Cancelled",    
    days: "Days",
    week:"Week",
    month:"Month",
    subTotal: "SubTotal",
    taxes: "Taxes",
    advance_percentage: "Advance %",
    advance_amount: "Advance Amount",
    discount_percentage: "Discount %",
    advance: "Advance",
    discount_amount: "Discount Amount",
    final_amount_payable: "Final Amount Payable",
    info: "Info!",
    warn: "Warning!",
    this_invoice_has_been: "This Invoice has been ",
    and_changes_are_prohibited: " and changes are prohibited.",

    message_invoices_not_found: "Invoices not Found",
    message_invoice_new_successful: "Invoice created successfully.",
    message_invoice_update_successful: "Invoice updated successfully.",
    message_invoice_payment_update_successful:
      "Payment info updated successfully.",
    message_invoice_cancel_successful: "Invoice cancelled",
    message_invoice_restore_successful: "Invoice restored",
    invoices_deleted_succeful:"invoice deleted.",

    // RESOURCES FOR EMPLOY ONBOARDING MODULE.
    employ_onboarding: "Employ onboarding",
    update_employ_onboarding: "Update Employ Onboarding",
    add_new_employ_onboarding: "Add New Employ Onboarding",
    add_new_employ_employee: "Add New Employee", //added
    applicant: "Applicant Joined",
    employeeId: "Employ Id.",
    error_empty_employeeId: "Please enter employ id",
    error_empty_applicant: "Please select applicant joined",
    firstName: "First Name",
    error_empty_firstName: "Please enter first name",
    middleName: "Middle Name",
    error_empty_middleName: "Please enter middle name",
    surname: "Surname",
    error_empty_surname: "Please enter surname",
    error_invalid_surname:
      "Please enter valid surname. should be minimum 2 characters long and should not contain numbers [0-9]", //added
    dob: "DOB",
    error_empty_dob: "Please select dob",
    error_invalid_bloodGroup: "Please enter valid Blood group",
    dateOfJoining: "Date of Joining",
    error_empty_dateOfJoining: "Please select date of joining",
    actualDateOfJOining: "Actual Date of Joining",
    error_empty_actualDateOfJOining: "Please select actual date of joining",
    contactNumber: "Contact No.",
    error_empty_contactNumber: "Please enter contact no",
    emergencyContactNumber: "Emergency Contact",
    error_empty_emergencyContactNumber: "Please enter emergency contact",
    personalEmailId: "Email Id (Personal)",
    error_empty_personalEmailId: "Please enter personal email id",
    officialEmailId: "Email Id. (Official)",
    error_empty_officialEmailId: "Please enter official email id",
    panCard: "PAN Card No.",
    error_empty_panCard: "Please enter pan card no",
    aadharCard: "Aadhar Card No.",
    error_empty_aadharCard: "Please enter aadhar card no",
    error_invalid_aadharCard:"Please enter valid aadhar no",
    passportNumber: "Passport No.",
    error_empty_passportNumber: "Please enter passport no",
    bloodGroup: "Blood Group",
    error_empty_bloodGroup: "Please enter blood group",
    gender: "Gender",
    error_empty_gender: "Please select gender",
    bankAccountNumber: "Bank Account No.",
    error_empty_bankAccountNumber: "Please enter bank account no",
    jobType: "Job Type",
    error_empty_job_type1: "Please select job type",
    pfAccountNumber: "PF Account No.",
    error_empty_pfAccountNumber: "Please enter pf account no",
    employementStatus: "Employement Status",
    manager: "Manager",
    error_empty_employment_status1: "Please select employment status",
    error_select_designation: "Please select designation",
    resignationDate: "Resignation Date",
    error_empty_resignationDate: "Please select resignation date",
    lastWorkingDate: "Last Working Date",
    error_empty_lastWorkingDate: "Please select last working date",
    error_empty_manager: "Please select manager",
    doj: "DOJ",
    actual_doj: "Actual DOJ",
    data_created: "Data Created",
    email_id: "Email Id",
    created_by: "Created By", //added
    save_and_send_email: "Save & Send Email",
    address1: "Address1",
    error_empty_address1: "Please enter adress1",
    address2: "Address2",
    error_empty_city1: "Please enter city",
    error_empty_address2: "Please enter adress2",
    zipCode: "Zip Code",
    error_empty_zipCode: "Please enter zip code",
    same_as_above: "Same as above?",
    paddress1: "Address1",
    paddress2: "Address2",
    pcity: "City",
    pstate: "State",
    pzipCode: "Zip Code",
    pcountry: "Country",
    add_documents_for_employee: "Add Documents for Employee",
    update_documents_for_employee: "Update Documents for Employee",
    present_address: "PRESENT ADDRESS",
    permanent_address: "PERMANENT ADDRESS",
    employee_onboarding_checklist_section:
      "EMPLOYEE ONBOARDING CHECKLIST SECTION",
    employee_checklist_items: "Employee Onboarding Checklist Items",
    date_checked: "Date checked",
    employeeCTC: "Employee CTC",

    error_empty_employeeCTC: "Please enter employee CTC",
    message_official_email_exists:"Official email already exists",
    message_employ_onboarding_not_found:"No employ onboardings found",
    message_employ_onboarding_create_successful:"New employee add successful.",
    message_employ_onboarding_updated_successful:"Employ onboarding updated successfully.",
    employ_onboardings_deleted_succesful:"Employ onboarding deleted successfully.",

    // RESOURCES FOR ATTENDANCE MODULE.
    attendance: "Attendance",
    start_time:"Start time",
    start_date_and_time:"Start date & time",
    end_date_and_time:"End date & time",
    end_time:"End time",
    start_day:"Start day",
    end_day:"End day",
    employee_salary:"Employee salary",
    employee_name:"Employee name",
    is_present:"Is Present ?",
    present_status:"Present statue",
    attendance_report:"Attendance report",

    error_select_employee_name:"Please select employee",
    error_select_startDate:"Please select start date",
    error_select_endDate:"Please select end date",
    error_select_startTime:"Please select start time",
    error_select_endTime:"Please select end time",

    //for attendance module
    error_select_month:"Please choose month",
    salary_slip:"Salary Slip",

    message_employeeAttendance_update_successful:"Employee attendance updated",
    message_employee_attendances_not_found:"Employee attendance not found",
    message_employee_attendances_create_successful: "Employee attendance created Successfully.",
    employee_attendances_deleted_succeful: "Employee attendance deleted Successfully.",

    // RESOURCES FOR PROJECT MODULE.
    project: "Project",
    update_project: "Update Project",
    add_new_project: "Add New Project",
    
    project_no:"Project No.",
    quotation_amount:"Quotation amount",
    target_days:"Targeted days",
    assigned_to:"Assigned to",

    error_empty_quotation_amount:"Please enter quotation amount",
    error_empty_final_amount:"Please enter final amount",
    error_select_start_date:"Please choose start date",
    error_select_end_date:"Please choose end date",
    error_select_target_days:"Please choose target days",
    error_select_assigned_to:"Please choose project assigned to name",

    message_projects_not_found:"No projects found",
    message_project_new_successful:"New project add successful.",
    message_project_update_successful:"Project updated successfully.",
    projects_deleted_succeful: "Project deleted successfully",

    // RESOURCES FOR TICKETS MODULE
    ticket_management:"Ticket Management",
    update_ticket:"Update ticket",
    add_new_ticket:"Add New Ticket",
    ticket_id:"Ticket ID",
    subject:"Subject",
    ticket_assign_date:"Ticket assign date",
    ticket_assign_time:"Assign Time",
    priority:"Priority",
    add_ticket:"Add Ticket",
    action_status:"Action status",

    open:"Open",
    close:"Close",
    pending:"Pending",
    on_hold:"On-hold",
    re_open:"Re-open",

    received: "Received",

    low:"Low",
    medium:"Medium",
    high:"High",
    urgent:"Urgent",

    in_review:"In-Review",
    in_progress:"In-Progress",
    released:"Released",

    error_select_project:"Please select a project",
    error_select_ticket_assign_date:"Please select ticket assign date",
    error_empty_subject:"please enter subject",
    error_select_status:"Status is required",

    message_tickets_not_found : "No tickets found",
    message_ticket_new_successful: "New ticket added",
    message_ticket_update_successful:"Ticket updated",
    tickets_deleted_succeful: "Ticket deleted",


    // RESOURCES FOR RECRUIT MODULE.
    recruit: "Recruit",
    add_new_recruit: "Add New Recruit",
    update_recruit: "Update Recruit",
    applicants_name: "Applicant's Name",
    skill_and_technology: "Skill/Technology",
    date_created: "Date Created",
    skill_set_technology: "Skill Set / Technology",
    experience: "Experience",
    relevant_experience: "Relevant Experience",
    current_company: "Current Company",
    current_job_type: "Current Job Type",
    current_location: "Current Location",
    preferred_location: "Preferred Location",
    current_ctc: "Current Ctc",
    expected_ctc: "Expected Ctc",
    notice_period: "Notice Period",
    recruit_status:"Recruitment Status",
    source: "Source",
    date_joined_or_rejected: "Date Joined or Rejected",
    expected_date_of_joining: "Expected Date of Joining",
    interview_panel: "Interview Panel",
    reject_reason_comments: "Reject Reason / Comments",
    error_empty_applicants_name: "Please enter applicants name",
    error_empty_skillset_technology: "Please enter skill set or technology",
    error_empty_contact_no: " Please enter contact number",
    error_empty_experience: "Please enter experience",
    error_empty_relevant_experience: "Please enter relevent experience",
    error_empty_current_company: "Please enter current company",
    error_empty_emailId: "Please enter email id",
    error_empty_currentJobType: "Please select current job type",
    error_empty_current_location: "Please enter current location",
    error_empty_preferred_location: "Please enter preferred location",
    error_empty_current_ctc: "Please enter current ctc",
    error_empty_expected_ctc: "Please enter expected ctc",
    error_empty_source: "Please enter source",
    error_empty_status: "Please select status",
    error_empty_region: "Please enter region",
    error_empty_statusnotice_period: "Please enter notice period",
    error_empty_Expected_Date_Of_Joining:
      "Please select expected date of joining",
    error_empty_interview_Panel: "Please select interview panel",
    error_empty_reject_Reason_Comments:
      "Please enter reject reason or comments",
    error_empty_project: "Please enter project",
    error_empty_patchId: "Please enter patch id",
    error_empty_created_By: "Please select created by",
    error_invalid_applicants_name:
      "Please enter valid applicant name. should be minimum 2 characters long and should not contain numbers [0-9]",
    error_invalid_skillset_technology:
      "skillset or technology name should be atleast 2 characters long and should contain only letters [A-Za-z]",

    message_recruit_email_exists: "Recruit already exists with this email",
    message_recruit_contactNo_exists:"Recruit already exists with this contact number",
    message_recruits_create_successful: "New recruit added.",
    message_recruits_updated_successful: "Recruit updated successful.",
    message_recruits_not_found: "No recruits found.",
    recruits_deleted_succeful: "Recruits deleted successfully.",

    /*User management PAGE*/
    users: "Users",
    user_list: "User List",
    add_new_user: "Add New User",
    update_user: "Update User",
    user:"User",
    first_name: "First name",
    last_name: "Last name",
    role: "Role",

    error_select_role: "Please choose role",
    error_select_user:"Please choose employee",
    message_user_management_create_successful:
      "New user created successfully, tell user to reset password",
      user_management_deleted_succeful: "User deleted" ,
    message_user_update_successful: "User updated successfully.",
    message_users_fetch_successful: "fetch users successful.",
    message_users_not_found: "No users found",

    /*Roles and rights PAGE*/
    role_name: "Role name",
    roles_and_rights: "Roles and Rights",
    add_new_roles: "Add New Roles And Rights",
    update_roles: "Update Roles And Rights",
    role_title: "Role title",
    module_name: "Module name",

    error_empty_role_title: "Please enter role title",
    error_invalid_role_title: "Please enter valid role title",

    message_roles_rights_create_successful: "Role added successfully.",
    message_roles_rights_updated_successful: "Role updated successfully.",
    role_deleted_succeful: "Role deleted",
    message_roles_not_found: "Roles not found",

    /*Masters MENU RESOURCES*/
    id: "ID",
    masters: "Masters",
    master_job_type: "Job type",
    master_employment_status: "Employment status",
    master_recruitment_status: "Recruit status",
    master_designation: "Designation",
    master_currency: "Currency",
    activate: "Activate",
    is_master: "Is Master",
    add_more: "Add more",
    add_other: "Add other",
    master_menu:"Master menu",
    master_option:"Master option",

    state_branch: "Branch",
    gst_master_list: "GST Master List",
    gst_master_form: "GST Master Form",
    add_new_gst_master: "Add New GST Master",
    update_gst_master: "Update GST Master",
    gst_master: "GST Master",

    job_type: "Job type",
    create_job_type: "Create job type",
    add_job_type: "Add Job Type",
    error_empty_job_type: "Please enter job type",

    employment_status: "Employment status",
    create_employment_status: "Create employment status",
    add_employment_status: "Add employment status",
    error_empty_employment_status: "Please enter employment status",

    recruitment_status: "Recruit status",
    create_recruitment_status: "Create recruit status",
    add_recruitment_status: "Add recruit status",
    error_empty_recruitment_status: "Please enter recruit status",

    designation: "Designation",
    create_designation: "Create designation",
    add_designation: "Add designation",
    error_empty_designation: "Please enter designation",

    currency: "Currency",
    create_currency: "Create currency",
    add_currency: "Add currency",
    error_empty_currency: "Please enter currency",

    message_master_option_create_successful: "Master created successfully.",
    message_master_option_not_found: "Master option not found",
    message_master_option_exists: "Master option already exists.",
    message_master_option_update_successful: "Master updated successfully.",

    message_gst_master_new_successful: "New GSTMaster created successfully.",
    message_gst_master_not_found: "GST Master not found",
    message_gst_master_get_success: "GST Master fetch successfully...",
    message_gst_exists: "GST no already exists",
    message_gst_master_update_successful: "GSTMaster updated successfully.",
    gst_master_deleted_succeful:"GSTMaster deleted successfully.",

    // RESOURCES FOR HOLIDAYS MODULE.
    holiday: "Holidays",
    holiday_setup: "Holidays",
    create_holiday: "Create Holiday",
    update_holiday: "Update Holidays",
    add_new_holidays: "Add Holidays",

    fy: "Financial Year",
    holiday_name: "Name",
    holiday_type: "Holiday type",
    is_active: "Is Active",
    add_holiday: "Add Holiday", //added
    remove_holiday: "Remove Holiday",
    holiday_items: "Holiday items",

    error_empty_holiday_name: "required field",
    error_empty_date: "date is required",
    error_empty_holiday_type: "required field",
    
    holiday_exists_for_fy: "Holidays for this Financial Year are already set.",
    message_holidays_get_success: "Holidays fetched",
    message_holidays_not_found: "No holidays found",
    message_holiday_new_successful: "Holidays set for Financial Year successful.",
    message_holiday_update_successful: "Holidays updated successfully.",

    /* Reports */
    reports: "Reports",
    report_type: "Report type",
    inv_gst: "Invoice GST",
    export_to_csv: "Export To CSV",
    error_empty_startDate: "Please select start date",
    error_empty_endDate: "Please select end date",
    error_select_module: "Please choose a module",

    /*Code setup PAGE*/
    code_setup: "Code setup",
    code_setup_list: "Code setup List",
    code_setup_form: "Code setup Form",
    add_new_code_setup: "Add New Code setup",
    update_code_setup: "Update Code setup",
    module: "Module",
    prefix: "Prefix",
    suffix: "Suffix",
    separator: "Separator",
    padding: "Padding",
    start_no: "Start no",
    preview: "Preview",

    error_min_start_no: "Please enter start no",
    error_select_module_slug: "Please select a module",
    error_empty_prefix: "Please enter prefix",
    error_empty_separator: "Please enter separator",
    error_empty_padding: "Please enter padding",
    error_empty_start_no: "Please enter start no.",

    message_codeSetups_not_found: "No code setups found.",
    message_codeSetup_new_successful: "New code setup created.",
    message_codeSetup_update_successful: "Code setup updated successfully.",
    code_setup_deleted_succeful: "Code Setup deleted succcessfully",

    /*Add Masters PAGE*/
    add_master: "Add Masters",
    master_list: "Masters List",
    add_master_form: "Add Master Form",
    add_new_add_master: "Add New Master",
    update_add_master: "Update Add Master",

    error_empty_slug: "Please enter slug",
    error_empty_name: "Please enter Name",

    message_masters_not_found: "No Masters found.",
    message_add_master_new_successful: "New Master created.",
    message_master_updated_successful: "Master updated successfully.",

    // resources for PDF
    no_invoice_items: "No invoice Items",

    customer_info: "Customer Info",
    total_amount: "Total Amount",
    milestones: "Milestones",
    customer_sign: "Customer signature",
    provider_sign: "Provider's Signature",
    no_quotation_items: "No quotation Items",
    quotation_gst_note: " Note* : This Quotation not includes GST",

    message_quotation_new_successful: "New Quotation added successfully.",
    message_quotation_update_successful: "Quotation Updated successfully.",
    message_quotations_not_found: "Quotations not found",
    message_error_fetching_profile: "Error fetching profile info",
    message_profile_update_successful: "Profile Updated successfully.",
    message_quotations_get_success: "Quotations fetch successful.",
    quotations_deleted_succeful: "Quotation deleted successful.",

    error_select_project_name: "Please select a project",
    error_select_company_name: "Please select Company",
    error_empty_gst: "Please enter GST no.",

    error_invalid_company_name: "Company name should contain only letters",
    error_invalid_gst: "Please enter valid GST No",

    // resources for Customer, errors, form etc...
    error_empty_company_name: "Please enter company name",

    customer: "Customer",
    add_new_customer: "Add Customer",
    update_customer: "Update Customer",
    unique_id: "Unique ID",
    cin: "CIN",
    owner_name: "Owner Name",

    error_empty_owner_name: "Please enter owner name",

    message_customer_new_successful: "New Customer added successfully.",
    message_customer_update_successful: "Customer Updated successfully.",
    message_customers_not_found: "Customers not found",

    message_customers_get_success: "Customers fetch successful.",

    // resources for suppliers module, errors, form etc...
    suppliers: "Suppliers",
    add_new_supplier: "Add New Supplier",
    update_supplier: "Update Supplier",
    supplier_list: "Supplier List",
    add_supplier_form: "Add Supplier Form",
    title_supplier_list: "Supplier List",
    title_supplier_form: "Supplier Form",

    supplier_name: "Supplier Name",

    error_empty_supplier_name: "Please Enter Supplier Name.",

    message_supplier_new_successful: "New Supplier added successfully.",
    message_supplier_update_successful: "Supplier Updated successfully.",
    supplier_deleted_succeful: "Supplier deleted successfully.",

    // resources for Purchase, errors, form etc...
    purchases: "Purchases",
    purchase: "Purchase",
    add_new_purchase: "Add New Purchase",
    update_purchase: "Update Purchase",
    purchase_list: "Purchase List",
    add_purchase_form: "Add Purchase Form",
    title_purchase_form: "Purchase Form",
    
    supplier:"Supplier",
    purchase_date:"Purchase Date",
    reference_or_po_no: "PO No./ Reference No.",
    purchase_description:"Purchase Description",
    order_tax:"Order tax",
    shipping:"Shipping",
    // tax_amount: "Tax amount",
    // purchase_price: "Purchase price",
    // unit_cost: "Unit cost",
    this_purchase_has_been: "This Purchase has been ",
    
    error_empty_purchase_name: "Please Enter Purchase Name.",
    error_empty_purchase_date: "Please Enter Purchase Date.",
    
    error_select_products: "Please Select products",
    error_qty_range: "Enter minimum 1",

    message_purchase_new_successful: "New Purchase added successfully.",
    message_purchase_update_successful: "Purchase Updated successfully.",
    message_purchases_not_found: "Purchases not found",
    purchase_deleted_succeful: "Purchase deleted successfully.",
    message_purchases_get_success: "Purchases fetch successful.",

    // resources for Purchase Return, errors, form etc...
    purchase_return: "Purchase Return",
    add_new_purchase_return: "Add New Purchase Return",
    update_purchase_return: "Update Purchase Return",
    purchase_return_list: "Purchase Return List",
    add_purchase_return_form: "Add Purchase Return Form",
    title_purchase_return_form: "Purchase Return Form",
    this_purchase_return_has_been: "This Purchase Return has been ",
    
    purchase_return_date:"Purchase Return Date",
    purchase_return_description:"Purchase Return Description",
    // tax_amount: "Tax amount",
    // purchase_return_price: "Purchase Return price",
    // unit_cost: "Unit cost",
    
    error_empty_purchase_return_name: "Please Enter Purchase Return Name.",
    error_empty_purchase_return_date: "Please Enter Purchase Return Date.",

    message_purchase_return_new_successful: "New Purchase Return added successfully.",
    message_purchase_return_update_successful: "Purchase Return Updated successfully.",
    message_purchase_returns_not_found: "Purchase Returns not found",
    purchase_return_deleted_succeful: "Purchase Return deleted successfully.",
    message_purchase_returns_get_success: "Purchase Returns fetch successful.",

    // resources for Purchase Orders, errors, form etc...
    purchase_orders: "Purchase Orders",
    purchase_order: "Purchase Order",
    add_new_purchase_order: "Add New Purchase Order",
    update_purchase_order: "Update Purchase Order",
    purchase_order_list: "Purchase Order List",
    add_purchase_order_form: "Add Purchase Order Form",
    title_purchase_order_form: "Purchase Order Form",

    // resources for Stock Report, errors, form etc...
    stock_reports: "Stock Report",
    stock_report: "Stock Report",
    add_new_stock_report: "Add New Stock Report",
    update_stock_report: "Update Stock Report",
    stock_report_list: "Stock Report List",
    add_stock_report_form: "Add Stock Report Form",
    title_stock_report_form: "Stock Report Form",

    // resources for PO, errors, form etc...
    po: "PO",
    add_new_po: "Add New PO",
    update_po: "Update PO",
    po_list: "PO List",
    add_po_form: "Add PO Form",
    title_po_form: "PO Form",
    added_products: "Added products",
    
    supplier_information: "Supplier Information",
    po_no:"PO No.",
    po_date:"PO Date",
    order_list: "Order List",
    transaction_id: "Transaction ID",
    po_description:"PO Description",
    transactions: "Transactions",
    view_orders: "View Orders",
    select_from_below_categories: "Select From Below Categories",
    walk_in_customer: "Walk in customer",
    po_status: "PO Status",
    
    message_po_new_successful: "New PO added successfully.",
    message_po_update_successful: "PO Updated successfully.",
    message_po_not_found: "PO not found",
    po_deleted_succeful: "PO deleted successfully.",
    message_po_get_success: "PO fetch successful.",

    // resources for Sales, errors, form etc...
    sales_module: "Sales module",
    sales: "Sales",
    sale_no: "Sale No.",
    add_new_sales: "Add New Sales",
    update_sales: "Update Sales",
    sales_list: "Sales List",
    add_sales_form: "Add Sales Form",
    title_sales_form: "Sales Form",
    reference_no: "Reference No.",
    reference_or_pos_no: "POS No./ Reference No.",
    
    sales_date:"Sales Date",
    sales_description:"Sales Description",
    // tax_amount: "Tax amount",
    // sales_price: "Sales price",
    // unit_cost: "Unit cost",
    this_sales_has_been: "This Sales has been ",
    
    error_empty_sales_name: "Please Enter Sales Name.",
    error_empty_sales_date: "Please Enter Sales Date.",

    message_sales_new_successful: "New Sales added successfully.",
    message_sales_update_successful: "Sales Updated successfully.",
    message_sales_not_found: "Sales not found",
    sales_deleted_succeful: "Sales deleted successfully.",
    message_sales_get_success: "Sales fetch successful.",

    // resources for Sales Return, errors, form etc...
    sales_return: "Sales Return",
    add_new_sales_return: "Add New Sales Return",
    update_sales_return: "Update Sales Return",
    sales_return_list: "Sales Return List",
    add_sales_return_form: "Add Sales Return Form",
    title_sales_return_form: "Sales Return Form",
    this_sales_return_has_been: "This Sales Return has been ",
    
    sales_return_date:"Sales Return Date",
    sales_return_description:"Sales Return Description",
    // tax_amount: "Tax amount",
    // sales_return_price: "Sales Return price",
    // unit_cost: "Unit cost",
    
    error_empty_sales_return_name: "Please Enter Sales Return Name.",
    error_empty_sales_return_date: "Please Enter Sales Return Date.",

    message_sales_return_new_successful: "New Sales Return added successfully.",
    message_sales_return_update_successful: "Sales Return Updated successfully.",
    message_sales_returns_not_found: "Sales Returns not found",
    sales_return_deleted_succeful: "Sales Return deleted successfully.",
    message_sales_returns_get_success: "Sales Returns fetch successful.",

    // resources for Sales Orders, errors, form etc...
    sales_orders: "Sales Orders",
    sales_order: "Sales Order",
    add_new_sales_order: "Add New Sales Order",
    update_sales_order: "Update Sales Order",
    sales_order_list: "Sales Order List",
    add_sales_order_form: "Add Sales Order Form",
    title_sales_order_form: "Sales Order Form",

    // resources for Stock, errors, form etc...
    current_stock: "Current Stock",
    total_purchases: "Total purchases",
    total_purchase_returns: "Total purchase returns",
    total_sales: "Total sales",
    total_sale_returns: "Total sale returns",

    // resources for POS, errors, form etc...
    pos: "POS",
    add_new_pos: "Add New POS",
    update_pos: "Update POS",
    pos_list: "POS List",
    add_pos_form: "Add POS Form",
    title_pos_form: "POS Form",
    
    customer_information: "Customer Information",
    pos_no:"POS No.",
    pos_date:"POS Date",
    order_list: "Order List",
    transaction_id: "Transaction ID",
    pos_description:"POS Description",
    transactions: "Transactions",
    view_orders: "View Orders",
    select_from_below_categories: "Select From Below Categories",
    walk_in_customer: "Walk in customer",
    pos_status: "POS Status",
    
    message_pos_new_successful: "New POS added successfully.",
    message_pos_update_successful: "POS Updated successfully.",
    message_pos_not_found: "POS not found",
    pos_deleted_succeful: "POS deleted successfully.",
    message_pos_get_success: "POS fetch successful.",

    //  Inventory module
    inventory_management: "Inventory",
    // resources for brand, errors, form etc...

    brand: "Brand",
    add_new_brand: "Add New Brand",
    update_brand: "Update Brand",
    brand_list: "Brand List",
    add_brand_form: "Add Brand Form",
    title_brand_form: "Brand Form",
    brand_name:"Brand Name",
    brand_description:"Brand Description",

    error_empty_brand_name: "Please Enter Brand Name.",
    message_brand_new_successful: "New Brand added successfully.",
    message_brand_update_successful: "Brand Updated successfully.",
    message_brands_not_found: "Brands not found",
    brand_deleted_succeful: "Brand deleted successfully.",
    message_brands_get_success: "Brands fetch successful.",

    // resources for category, errors, form etc...
    category: "Category",
    categories: "Categories",
    parent_category: "Parent Category",
    add_new_category: "Add New Category",
    update_category: "Update Category",
    category_list: "Category List",
    add_category_form: "Add Category Form",
    title_category_form: "Category Form",
    category_name:"Category Name",
    category_description:"Category Description",
    category_code: "Category Code",
    have_subcategories: "Have Sub Categories?",

    error_empty_category_name: "Please Enter Category Name.",
    error_empty_category_code: "Please Enter Category code.",
    message_category_new_successful: "New Category added successfully.",
    message_category_update_successful: "Category Updated successfully.",
    message_categories_not_found: "Categories not found",
    category_deleted_succeful: "Category deleted successfully.",
    message_categories_get_success: "Categories fetch successful.",

    // resources for sub category, errors, form etc...
    sub_category: "Sub Category",
    add_new_sub_category: "Add New Sub Category",
    update_sub_category: "Update Sub Category",
    sub_category_list: "Sub Category List",
    add_sub_category_form: "Add Sub Category Form",
    title_sub_category_form: "Sub Category Form",
    sub_category_name:"Sub Category Name",
    sub_category_description:"Sub Category Description",
    
    error_empty_sub_category_name: "Please Enter Sub Category Name.",
    error_empty_description: "Please Enter Description",
    error_select_category: "Please Select Category",

    message_sub_category_new_successful: "New Sub Category added successfully.",
    message_sub_category_update_successful: "Sub Category Updated successfully.",
    message_sub_categories_not_found: "Categories not found",
    sub_category_deleted_succeful: "Sub Category deleted successfully.",
    message_sub_categories_get_success: "Sub Categories fetch successful.",

    // resources for products module, errors, form etc...
    product: "Product",
    products: "Products",
    add_new_product: "Add New Product",
    update_product: "Update Product",
    product_list: "Product List",
    add_product_form: "Add Product Form",
    title_product_list: "Product List",
    title_product_form: "Product Form",

    product_name: "Product Name",
    product_description: "Product Description",
    discount_type: "Discount type",
    purchase_qty: "Purchase qty",
    purchase_return_qty: "Purchase Return qty",
    sale_qty: "Sale qty",
    sale_return_qty: "Sale Return qty",
    available_qty: "Available qty",
    min_qty: "min qty",
    max_qty: "max qty",
    image_one: "Image 1",
    image_two: "Image 2",
    image_three: "Image 3",
    image_four: "Image 4",

    error_empty_product_name: "Please Enter Product Name.",
    error_empty_product_description: "Please Enter Product description.",
    error_empty_sku: "Please Enter SKU.",
    error_empty_min_qty: "Please Enter min qty",
    error_empty_max_qty: "Please Enter max qty",

    error_select_supplier: "Please select supplier.",
    error_select_customer: "Please select customer.",
    error_select_brand: "Please select brand.",
    error_select_sub_category: "Please select sub category.",
    error_select_unit: "Please select unit.",
    error_select_discount_type: "Please select discount type.",
    error_select_tax: "Please select a tax.",

    message_product_new_successful: "New Product added successfully.",
    message_product_update_successful: "Product Updated successfully.",
    product_deleted_succeful: "Product deleted successfully.",

     /*Add purchase PAGE*/
     grand_total: "Grand Total",
 
     message_purchase_not_found: "No Purchase found.",
     message_add_purchase_successful: "New Purchase created.",
     message_purchase_updated_successful: "Purchase updated successfully.",
 

    //messages
    message: "Loading...",
    user_not_found: "User not found with this email",

    //Form page title
    personal_details: "Personal Details",
    account_setup: "Account Setup",
    success: "Success",

    //Sign in page
    error_empty_email: "Please enter email",
    error_invalid_email: "Please enter valid email",
    error_empty_password: "Please enter Password",
    error_invalid_password: "Please enter valid password",
    error_empty_cpassword: "Please enter confirm Password",
    error_invalid_cpassword: "Please enter valid confirm password",
    error_cpassword_not_match: "password and confirm password must be same",

    //sign up page
    error_empty_firstname: "Please enter firstname",
    error_invalid_firstname:
      "First name should be minimum 2 characters long and contain only letters",
    error_empty_lastname: "Please enter lastname",
    error_invalid_lastname:
      "Last name should be minimum 2 characters long and contain only letters",

    //backend error messages
    error_user_disabled: "This account is disabled",
    error_user_not_found: "User do not exists with this email",
    error_wrong_email_or_password: "Email or password do not match",
    error_user_already_exists: "User already exists with this email",
    error_password_not_match: "Password not math for given email",

    error_weak_password: "Password is weak",

    //messages
    message_registration_successful: "Regiestration successful.",
    message_sign_in_successful: "Sign in successful.",
    message_user_verified: "User Verified",
    message_fetch_profile_successful: "User profile successful.",
    message_fetch_profile_failed: "Failed to fetch profile info",
    message_link_sent: "Password reset link is sent to your email",
    message_verification_email_sent: "Veification email sent",
    your_session_has_been_expired: "Your session has been expired Login again",
  },
};
