import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  timeout: 1000 * 30,
  headers: {
    "Content-Type": "application/json",
  },
});
export const generalAxios = axios.create({
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Content-Type": "application/octet-stream",
  },
  
});

export default instance;