import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  ADD_NEW_BRAND,
  ADD_NEW_BRAND_FAILURE,
  ADD_NEW_BRAND_SUCCESS,
  BRAND_DELETE,
  BRAND_DELETE_FAILURE,
  BRAND_DELETE_SUCCESS,
  BRAND_DETAIL,
  BRAND_DETAIL_FAILURE,
  BRAND_DETAIL_SUCCESS,
  BRAND_LIST,
  BRAND_LIST_BY_ROLE,
  BRAND_LIST_BY_ROLE_FAILURE,
  BRAND_LIST_BY_ROLE_SUCCESS,
  BRAND_LIST_FAILURE,
  BRAND_LIST_SUCCESS,
  BRAND_NEW,
  BRAND_NEW_FAILURE,
  BRAND_NEW_SUCCESS,
  BRAND_UPDATE,
  BRAND_UPDATE_FAILURE,
  BRAND_UPDATE_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createBrandAsync,
  deleteBrandAsync,
  fetchBrandAsync,
  fetchBrandByRoleAsync,
  fetchBrandDetailAsync,
  updateBrandAsync,
} from "../../../services/inventory/brand.service.js";
import { uploadFile } from "../../common/saga.js";

export function* watchFetchBrand() {
  yield takeLatest(BRAND_LIST, fetchBrandList);
}

export function* watchFetchBrandByRole() {
  yield takeLatest(BRAND_LIST_BY_ROLE, fetchBrandListByRole);
}

export function* watchCreateBrand() {
  yield takeLatest(BRAND_NEW, createNewBrand);
}

export function* watchAddNewBrand() {
  yield takeLatest(ADD_NEW_BRAND, addNewBrand);
}

export function* watchUpdateBrand() {
  yield takeLatest(BRAND_UPDATE, updateBrand);
}

export function* watchGetDetailBrand() {
  yield takeLatest(BRAND_DETAIL, fetchBrandDetail);
}

export function* watchDeleteBrand() {
  yield takeLatest(BRAND_DELETE, deleteBrand);
}

function* fetchBrandList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchBrandAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: BRAND_LIST_SUCCESS,
        payload: { brands: response.data },
      });
    } else {
      yield put({ type: BRAND_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: BRAND_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchBrandListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchBrandByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: BRAND_LIST_BY_ROLE_SUCCESS,
        payload: { brandsByRole: response.data },
      });
    } else {
      yield put({ type: BRAND_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: BRAND_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewBrand({ payload }) {
  const { data, logoFile } = payload;
  let brandName = data.brandName.trim().replace(" ", "_");
  let responseLogoFileUpload = null;

  if (logoFile.file) {
    const logoFormData = new FormData();
    logoFormData.append("files", logoFile.file);
    responseLogoFileUpload = yield call(
      uploadFile,
      brandName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (responseLogoFileUpload !== null) {
    // data.logo = responseLogoFileUpload.data.id;
    data.logo = responseLogoFileUpload.id;
  }

  try {
    const response = yield call(createBrandAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));      
      yield put({
        type: BRAND_NEW_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: BRAND_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: BRAND_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* addNewBrand({ payload }) {
  const { data, logoFile } = payload;
  let brandName = data.brandName.trim().replace(" ", "_");
  let responseLogoFileUpload = null;

  if (logoFile.file) {
    const logoFormData = new FormData();
    logoFormData.append("files", logoFile.file);
    responseLogoFileUpload = yield call(
      uploadFile,
      brandName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (responseLogoFileUpload !== null) {
    // data.logo = responseLogoFileUpload.data.id;
    data.logo = responseLogoFileUpload.id;
  }

  try {
    const response = yield call(createBrandAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: ADD_NEW_BRAND_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ADD_NEW_BRAND_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADD_NEW_BRAND_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateBrand({ payload }) {
  const { data, id, logoFile } = payload;
  let responseLogoFileUpload = null;
  
  if (logoFile && logoFile.file) {
    let brandName = data?.brandName?.trim().replace(" ", "_");
    const logoFormData = new FormData();
    logoFormData.append("files", logoFile.file);
    responseLogoFileUpload = yield call(
      uploadFile,
      brandName,
      "logo",
      "",
      "",
      logoFormData
    );
  }
  if (responseLogoFileUpload !== null) {
    // data.logo = responseLogoFileUpload.data.id;
    data.logo = responseLogoFileUpload.id;
  }

  try {
    const response = yield call(updateBrandAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: BRAND_UPDATE_SUCCESS,
        payload: { brand: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: BRAND_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: BRAND_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchBrandDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchBrandDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: BRAND_DETAIL_SUCCESS,
        payload: { brand: response.data },
      });
    } else {
      yield put({ type: BRAND_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: BRAND_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteBrand({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteBrandAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: BRAND_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: BRAND_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: BRAND_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchBrand),
    fork(watchFetchBrandByRole),
    fork(watchCreateBrand),
    fork(watchAddNewBrand),
    fork(watchUpdateBrand),
    fork(watchGetDetailBrand),
    fork(watchDeleteBrand),
  ]);
}
