import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  PURCHASE_RETURN_DELETE,
  PURCHASE_RETURN_DELETE_FAILURE,
  PURCHASE_RETURN_DELETE_SUCCESS,
  PURCHASE_RETURN_DETAIL,
  PURCHASE_RETURN_DETAIL_FAILURE,
  PURCHASE_RETURN_DETAIL_SUCCESS,
  PURCHASE_RETURN_LIST,
  PURCHASE_RETURN_LIST_BY_ROLE,
  PURCHASE_RETURN_LIST_BY_ROLE_FAILURE,
  PURCHASE_RETURN_LIST_BY_ROLE_SUCCESS,
  PURCHASE_RETURN_LIST_FAILURE,
  PURCHASE_RETURN_LIST_SUCCESS,
  PURCHASE_RETURN_NEW,
  PURCHASE_RETURN_NEW_FAILURE,
  PURCHASE_RETURN_NEW_SUCCESS,
  PURCHASE_RETURN_PAYMENT_UPDATE,
  PURCHASE_RETURN_PAYMENT_UPDATE_FAILURE,
  PURCHASE_RETURN_PAYMENT_UPDATE_SUCCESS,
  PURCHASE_RETURN_STATUS_UPDATE,
  PURCHASE_RETURN_UPDATE,
  PURCHASE_RETURN_UPDATE_FAILURE,
  PURCHASE_RETURN_UPDATE_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createPurchaseReturnAsync,
  deletePurchaseReturnAsync,
  fetchPurchaseReturnAsync,
  fetchPurchaseReturnByRoleAsync,
  fetchPurchaseReturnDetailAsync,
  updatePurchaseReturnAsync,
  updatePurchaseReturnPaymentInfoAsync,
  updatePurchaseReturnStatusAsync,
} from "../../../services/purchase/purchase-return.service.js";

export function* watchFetchPurchaseReturn() {
  yield takeLatest(PURCHASE_RETURN_LIST, fetchPurchaseReturnList);
}

export function* watchFetchPurchaseReturnByRole() {
  yield takeLatest(PURCHASE_RETURN_LIST_BY_ROLE, fetchPurchaseReturnListByRole);
}

export function* watchCreatePurchaseReturn() {
  yield takeLatest(PURCHASE_RETURN_NEW, createNewPurchaseReturn);
}

export function* watchUpdatePurchaseReturn() {
  yield takeLatest(PURCHASE_RETURN_UPDATE, updatePurchaseReturn);
}

export function* watchUpdatePurchaseReturnStatus() {
  yield takeLatest(PURCHASE_RETURN_STATUS_UPDATE, updatePurchaseReturnStatus);
}

export function* watchUpdatePurchaseReturnPaymentInfo() {
  yield takeLatest(PURCHASE_RETURN_PAYMENT_UPDATE, updatePurchaseReturnPaymentInfo);
}

export function* watchGetDetailPurchaseReturn() {
  yield takeLatest(PURCHASE_RETURN_DETAIL, fetchPurchaseReturnDetail);
}

export function* watchDeletePurchaseReturn() {
  yield takeLatest(PURCHASE_RETURN_DELETE, deletePurchaseReturn);
}

function* fetchPurchaseReturnList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPurchaseReturnAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PURCHASE_RETURN_LIST_SUCCESS,
        payload: { purchaseReturns: response.data },
      });
    } else {
      yield put({ type: PURCHASE_RETURN_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_RETURN_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPurchaseReturnListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPurchaseReturnByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PURCHASE_RETURN_LIST_BY_ROLE_SUCCESS,
        payload: { purchaseReturnsByRole: response.data },
      });
    } else {
      yield put({ type: PURCHASE_RETURN_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_RETURN_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewPurchaseReturn({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createPurchaseReturnAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_RETURN_NEW_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_RETURN_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_RETURN_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePurchaseReturn({ payload }) {
  const { data, id } = payload;
  
  try {
    const response = yield call(updatePurchaseReturnAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_RETURN_UPDATE_SUCCESS,
        payload: { purchaseReturn: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_RETURN_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_RETURN_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePurchaseReturnStatus({ payload }) {
  const { data, id} = payload;
  
  try {
    const response = yield call(updatePurchaseReturnStatusAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_RETURN_UPDATE_SUCCESS,
        payload: { purchaseReturn: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_RETURN_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_RETURN_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePurchaseReturnPaymentInfo({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updatePurchaseReturnPaymentInfoAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_RETURN_PAYMENT_UPDATE_SUCCESS,
        payload: { purchaseReturn: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_RETURN_PAYMENT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_RETURN_PAYMENT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPurchaseReturnDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchPurchaseReturnDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PURCHASE_RETURN_DETAIL_SUCCESS,
        payload: { purchaseReturn: response.data },
      });
    } else {
      yield put({ type: PURCHASE_RETURN_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_RETURN_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deletePurchaseReturn({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deletePurchaseReturnAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_RETURN_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_RETURN_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_RETURN_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchPurchaseReturn),
    fork(watchFetchPurchaseReturnByRole),
    fork(watchCreatePurchaseReturn),
    fork(watchUpdatePurchaseReturn),
    fork(watchUpdatePurchaseReturnStatus),
    fork(watchUpdatePurchaseReturnPaymentInfo),
    fork(watchGetDetailPurchaseReturn),
    fork(watchDeletePurchaseReturn),
  ]);
}
