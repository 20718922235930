import api from "./api";

export const invoicePDFAsync = async (id) =>
  await api
    .get(`/pdf/invoice/generate-pdf/${id}`)
    .then((res) => res)
    .catch((err) => err);

export const quotationPDFAsync = async (id) =>
  await api
    .get(`/pdf/quotation/generate-pdf/${id}`, {
      responseType: "arraybuffer",
    })
    .then((res) => res)
    .catch((err) => err);
