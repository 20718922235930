// import { Country } from "country-state-city";
import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  fetchUserProfileAsync,
  forgotPasswordAsync,
  loginAsync,
  logout,
  registerUserAsync,
  resetPasswordAsync,
  verifyUserAsync,
} from "../../services/auth.service";
import TokenService from "../../services/token.service";
import { hideLoader, showMessage } from "../lem/action";
import {
  FETCH_USER_PROFILE,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  FORGOT_PASSWORD,
  FORGOT_PASSWORD_FAILURE,
  FORGOT_PASSWORD_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_USER,
  LOGOUT,
  LOGOUT_FAILURE,
  LOGOUT_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_SUCCESS,
  REGISTER_USER,
  RESET_PASSWORD,
  RESET_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  VERIFY_USER,
  VERIFY_USER_FAILURE,
  VERIFY_USER_SUCCESS,
} from "../types";

export function* watchLoginUser() {
  yield takeLatest(LOGIN_USER, loginUser);
}
export function* watchForgotPassword() {
  yield takeLatest(FORGOT_PASSWORD, forgotPasswordUser);
}

export function* watchResetPassword() {
  yield takeLatest(RESET_PASSWORD, resetPasswordUser);
}

export function* watchVerifyUser() {
  yield takeLatest(VERIFY_USER, verifyUser);
}

export function* watchRegisterUser() {
  yield takeLatest(REGISTER_USER, registerUser);
}

export function* watchLogoutUser() {
  yield takeLatest(LOGOUT, logoutUser);
}

export function* watchFetchUserProfile() {
  yield takeLatest(FETCH_USER_PROFILE, fetchProfile);
}

/**
 *  Function for watching the saga for login user
 */
function* loginUser({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(loginAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      TokenService.setUser(response.data);
      yield put({
        type: LOGIN_SUCCESS,
        payload: { user: response.data },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: LOGIN_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: LOGIN_FAILURE });
    // yield put(showMessage("error", "Server error"));
  }
}

function* fetchProfile({ payload }) {
  // const { uid } = payload;
  try {
    // const response = yield call(fetchUserProfileAsync, uid);
    const response = yield call(fetchUserProfileAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: FETCH_USER_PROFILE_SUCCESS,
        payload: { user: response.data },
      });
    } else {
      yield put({ type: FETCH_USER_PROFILE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: FETCH_USER_PROFILE_FAILURE });
    yield put(showMessage("error", error));
  }
}
/**
 *  Function for watching saga for forgot password by user.
 */

function* forgotPasswordUser({ payload }) {
  const { email } = payload;
  try {
    const response = yield call(forgotPasswordAsync, email);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({ type: FORGOT_PASSWORD_SUCCESS });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: FORGOT_PASSWORD_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: FORGOT_PASSWORD_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* resetPasswordUser({ payload }) {
  const { email, password } = payload;
  try {
    const response = yield call(resetPasswordAsync, email, password);
    // yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({ type: RESET_PASSWORD_SUCCESS });
      //   yield put(showMessage("success", response.message));
    } else {
      yield put({ type: RESET_PASSWORD_FAILURE });
      //   yield put(showMessage("error", response.data.error[0]));
    }
  } catch (error) {
    yield put({ type: RESET_PASSWORD_FAILURE });
    // yield put(showMessage("error", error));
  }
}

function* verifyUser({ payload }) {
  const { token } = payload;
  try {
    const response = yield call(verifyUserAsync, token);
    // yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({ type: VERIFY_USER_SUCCESS });
      //   yield put(showMessage("success", response.message));
    } else {
      yield put({ type: VERIFY_USER_FAILURE });
      //   yield put(showMessage("error", response.data.error[0]));
    }
  } catch (error) {
    yield put({ type: VERIFY_USER_FAILURE });
    // yield put(showMessage("error", error));
  }
}

function* registerUser({ payload }) {
  const { user } = payload;
  try {
    const requestObject = {
      // id:user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      email: user.email,
      password: user.password,
      // createdBy: "",
      // parentId: "",
      socialType: user.socialType,
      role: {
        isActive: true,
        roleName: "admin",
        permissions: [
          "READ_DASHBOARD",
          "UPDATE_COMPANY_PROFILE",
          "READ_CUSTOMER",
          "CREATE_CUSTOMER",
          "UPDATE_CUSTOMER",
          "DELETE_CUSTOMER",
          "READ_QUOTATION",
          "CREATE_QUOTATION",
          "UPDATE_QUOTATION",
          "DELETE_QUOTATION",
          "READ_INVOICE",
          "CREATE_INVOICE",
          "UPDATE_INVOICE",
          "DELETE_INVOICE",
          "READ_USER_MANAGEMENT",
          "CREATE_USER_MANAGEMENT",
          "UPDATE_USER_MANAGEMENT",
          "DELETE_USER_MANAGEMENT",
          "READ_ROLE",
          "CREATE_ROLE",
          "UPDATE_ROLE",
          "DELETE_ROLE",
          "READ_MASTER_OPTION",
          "CREATE_MASTER_OPTION",
          "UPDATE_MASTER_OPTION",
          "DELETE_MASTER_OPTION",
          "READ_CODE_SETUP",
          "CREATE_CODE_SETUP",
          "UPDATE_CODE_SETUP",
          "DELETE_CODE_SETUP",
          "READ_MASTER_LIST",
          "CREATE_MASTER_LIST",
          "UPDATE_MASTER_LIST",
          "DELETE_MASTER_LIST",
          "READ_REPORT",
          "READ_PROJECT_MANAGEMENT",
          "READ_RECRUIT",
          "CREATE_RECRUIT",
          "UPDATE_RECRUIT",
          "DELETE_RECRUIT",
          "READ_EMPLOYEE",
          "CREATE_EMPLOYEE",
          "UPDATE_EMPLOYEE",
          "DELETE_EMPLOYEE",
          "READ_HR_DEPARTMENT",
          "READ_PROJECT",
          "CREATE_PROJECT",
          "UPDATE_PROJECT",
          "DELETE_PROJECT",
          "READ_TICKET",
          "CREATE_TICKET",
          "UPDATE_TICKET",
          "DELETE_TICKET",
          "READ_MASTERS",
          "READ_PURCHASE_MODULE",
          "READ_PURCHASE",
          "CREATE_PURCHASE",
          "UPDATE_PURCHASE",
          "DELETE_PURCHASE",
          "READ_PURCHASE_RETURN",
          "CREATE_PURCHASE_RETURN",
          "UPDATE_PURCHASE_RETURN",
          "DELETE_PURCHASE_RETURN",
          "READ_BRAND",
          "CREATE_BRAND",
          "UPDATE_BRAND",
          "DELETE_BRAND",
          "READ_SUPPLIER",
          "CREATE_SUPPLIER",
          "UPDATE_SUPPLIER",
          "DELETE_SUPPLIER",
          "READ_CATEGORY",
          "CREATE_CATEGORY",
          "UPDATE_CATEGORY",
          "DELETE_CATEGORY",
          "READ_PRODUCT",
          "CREATE_PRODUCT",
          "UPDATE_PRODUCT",
          "DELETE_PRODUCT",
        ],
      },
      //   socialToken: user.socialToken,
    };
    const response = yield call(registerUserAsync, requestObject);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({ type: REGISTER_SUCCESS });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: REGISTER_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: REGISTER_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* logoutUser() {
  try {
    const response = yield call(logout);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({ type: LOGOUT_SUCCESS });
      // yield put(showMessage("success", response.message));
      TokenService.removeUser()
    } else {
      yield put({ type: LOGOUT_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: LOGOUT_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchLoginUser),
    fork(watchFetchUserProfile),
    fork(watchForgotPassword),
    fork(watchResetPassword),
    fork(watchVerifyUser),
    fork(watchRegisterUser),
    fork(watchLogoutUser),
  ]);
}
