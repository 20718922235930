import { all } from "redux-saga/effects";

import authSagas from "./auth/saga";
import adminSagas from "./admin/saga";
import commonSagas from "./common/saga";
import masterSagas from "./masters/saga";
import profileSagas from "./profile/saga";
import employOnboardingSagas from "./employ_onboarding/saga";
import employAttendanceSagas from "./employeeAttendance/saga";
import projectSagas from "./project/saga";
import ticketSagas from "./ticket/saga";
import recruitSagas from "./recruit/saga";
import roleSagas from "./roles_and_rights/saga";
import userManagementSagas from "./user/saga";
import gstMasterSagas from "./gst_master/saga";
import codeSetupSagas from "./codesetup/saga";
import holidaySagas from "./holidays/saga";
import addMastersSaga from "./addMasters/saga";

import brandSagas from "./inventory/brand/saga";
import categorySagas from "./inventory/category/saga";
import subCategorySagas from "./inventory/subcategory/saga";
import productSagas from "./inventory/product/saga";

import supplierSagas from "./account/supplier/saga";
import purchaseSagas from "./purchase/purchases/saga";
import purchaseReturnSagas from "./purchase/purchaseReturns/saga";
import poSagas from "./purchase/po/saga";

import salesSagas from "./sales/sales/saga";
import salesReturnSagas from "./sales/salesReturns/saga";
import customerSagas from "./customer/saga";
import quotationSagas from "./quotation/saga";
import invoiceSagas from "./invoice/saga";
import posSagas from "./sales/pos/saga";

export default function* rootSaga() {
  yield all([
    authSagas(),
    adminSagas(),
    commonSagas(),
    masterSagas(),
    profileSagas(),
    customerSagas(),
    supplierSagas(),
    quotationSagas(),
    invoiceSagas(),
    employOnboardingSagas(),
    employAttendanceSagas(),
    projectSagas(),
    ticketSagas(),
    recruitSagas(),
    userManagementSagas(),
    roleSagas(),
    gstMasterSagas(),
    codeSetupSagas(),
    holidaySagas(),
    addMastersSaga(),
    purchaseSagas(),
    purchaseReturnSagas(),
    poSagas(),
    salesSagas(),
    posSagas(),
    salesReturnSagas(),
    brandSagas(),
    categorySagas(),
    subCategorySagas(),
    productSagas(),
  ]);
}
