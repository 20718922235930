import {
  ATTENDANCE_REPORT,
  ATTENDANCE_REPORT_SUCCESS,
  EMPLOYEE_ATTENDANCE_DELETE,
  EMPLOYEE_ATTENDANCE_DELETE_SUCCESS,
  EMPLOYEE_ATTENDANCE_DETAIL,
  EMPLOYEE_ATTENDANCE_DETAIL_SUCCESS,
  EMPLOYEE_ATTENDANCE_LIST,
  EMPLOYEE_ATTENDANCE_LIST_BY_ROLE,
  EMPLOYEE_ATTENDANCE_LIST_BY_ROLE_FAILURE,
  EMPLOYEE_ATTENDANCE_LIST_BY_ROLE_SUCCESS,
  EMPLOYEE_ATTENDANCE_LIST_FAILURE,
  EMPLOYEE_ATTENDANCE_LIST_SUCCESS,
  EMPLOYEE_ATTENDANCE_NEW,
  EMPLOYEE_ATTENDANCE_NEW_SUCCESS,
  EMPLOYEE_ATTENDANCE_UPDATE,
  EMPLOYEE_ATTENDANCE_UPDATE_SUCCESS,
  GENERATE_SALARY_SLIP_NO,
  GENERATE_SALARY_SLIP_NO_SUCCESS,
} from "../types.js";

const initialState = {
  employeeAttendances: [],
  attendanceReports: [],
  employeeAttendanceDetail: null,
  createOrUpdated: false,
  isDeleted: false,
  salarySlipNo: "",

  // Eemployee Attendances by role
  employeeAttendancesByRole: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EMPLOYEE_ATTENDANCE_LIST:
    case EMPLOYEE_ATTENDANCE_NEW:
    case EMPLOYEE_ATTENDANCE_UPDATE:
    case EMPLOYEE_ATTENDANCE_DETAIL:
    case EMPLOYEE_ATTENDANCE_DELETE:
    case GENERATE_SALARY_SLIP_NO:
    case ATTENDANCE_REPORT:
      return {
        ...state,
        createOrUpdated: false,
        employeeAttendanceDetail: null,
        employeeAttendances: [],
        attendanceReports: [],
        salarySlipNo: "",
      };

    case EMPLOYEE_ATTENDANCE_NEW_SUCCESS:
    case EMPLOYEE_ATTENDANCE_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }
    case EMPLOYEE_ATTENDANCE_DETAIL_SUCCESS: {
      return { ...state, employeeAttendanceDetail: payload.employeeAttendance };
    }

    case EMPLOYEE_ATTENDANCE_LIST_SUCCESS:
      return { ...state, employeeAttendances: payload.employeeAttendances };
    case EMPLOYEE_ATTENDANCE_LIST_FAILURE:
      return { ...state };

    case EMPLOYEE_ATTENDANCE_LIST_BY_ROLE:
      return { ...state, employeeAttendancesByRole: [] };
    case EMPLOYEE_ATTENDANCE_LIST_BY_ROLE_SUCCESS:
      return {
        ...state,
        employeeAttendancesByRole: payload.employeeAttendancesByRole,
      };
    case EMPLOYEE_ATTENDANCE_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case EMPLOYEE_ATTENDANCE_DELETE_SUCCESS:
      return {
        ...state,
        isDeleted: true,
        employeeAttendances: state.employeeAttendances.filter(
          (item) => item.id !== payload.id
        ),
      };
    case GENERATE_SALARY_SLIP_NO_SUCCESS: {
      return { ...state, salarySlipNo: payload.salarySlipNo };
    }
    case ATTENDANCE_REPORT_SUCCESS:
      return {
        ...state,
        attendanceReports: payload.attendanceReports,
      };

    default:
      return { ...state };
  }
}
