import {
  SUB_CATEGORY_DELETE_SUCCESS,
  SUB_CATEGORY_DETAIL,
  SUB_CATEGORY_DETAIL_SUCCESS,
  SUB_CATEGORY_LIST,
  SUB_CATEGORY_LIST_BY_ROLE,
  SUB_CATEGORY_LIST_BY_ROLE_FAILURE,
  SUB_CATEGORY_LIST_BY_ROLE_SUCCESS,
  SUB_CATEGORY_LIST_FAILURE,
  SUB_CATEGORY_LIST_SUCCESS,
  SUB_CATEGORY_NEW,
  SUB_CATEGORY_NEW_SUCCESS,
  SUB_CATEGORY_UPDATE,
  SUB_CATEGORY_UPDATE_SUCCESS,
} from "../../types.js";

const initialState = {
  subCategories: [],
  subCategoryDetail: null,
  createOrUpdated: false,

  // Sub categories by role
  subCategoriesByRole: [],

  subCategoryAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUB_CATEGORY_LIST:
    case SUB_CATEGORY_UPDATE:
    case SUB_CATEGORY_DETAIL:
      return {
        ...state,
        createOrUpdated: false,
        subCategoryDetail: null,
      };

    case SUB_CATEGORY_NEW:
      return {
        ...state,
        subCategoryAdded: false,
        newCategoryData: null,
      };

    case SUB_CATEGORY_NEW_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        createOrUpdated: true,
        subCategoryAdded: true,
        newCategoryData: data,
        subCategoriesByRole: [...state.subCategoriesByRole, data],
      };
    }

    case SUB_CATEGORY_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case SUB_CATEGORY_DETAIL_SUCCESS: {
      return { ...state, subCategoryDetail: payload.subCategory };
    }

    case SUB_CATEGORY_LIST_SUCCESS:
      return { ...state, subCategories: payload.subCategories };
    case SUB_CATEGORY_LIST_FAILURE:
      return { ...state };

    case SUB_CATEGORY_LIST_BY_ROLE:
      return { ...state, subCategoriesByRole: [] };
    case SUB_CATEGORY_LIST_BY_ROLE_SUCCESS:
      return { ...state, subCategoriesByRole: payload.subCategoriesByRole };
    case SUB_CATEGORY_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case SUB_CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        subCategories: state.subCategories.filter(
          (item) => item.id !== payload.id
        ),
      };

    default:
      return { ...state };
  }
}
