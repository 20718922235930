import {
  GENERATE_POS_ID,
  GENERATE_POS_ID_SUCCESS,
  POS_CATEGORIES_LIST,
  POS_CATEGORIES_LIST_FAILURE,
  POS_CATEGORIES_LIST_SUCCESS,
  POS_DELETE_SUCCESS,
  POS_DETAIL,
  POS_DETAIL_SUCCESS,
  POS_ID_GENERATION_CHECK,
  POS_ID_GENERATION_CHECK_SUCCESS,
  POS_LIST,
  POS_LIST_BY_ROLE,
  POS_LIST_BY_ROLE_FAILURE,
  POS_LIST_BY_ROLE_SUCCESS,
  POS_LIST_FAILURE,
  POS_LIST_SUCCESS,
  POS_NEW,
  POS_NEW_SUCCESS,
  POS_PAYMENT_UPDATE,
  POS_PAYMENT_UPDATE_SUCCESS,
  POS_STATUS_UPDATE,
  POS_STATUS_UPDATE_SUCCESS,
  POS_UPDATE,
  POS_UPDATE_SUCCESS,
} from "../../types.js";

const initialState = {
  pos: [],
  posDetail: null,
  createOrUpdated: false,

  // Brands by role
  posByRole: [],
  posCategory: null,

  posAdded: false,

  // below variables are to check any id is generated or not before
  idInfo: null,
  idIDGenarationChecked: false,
  posNo: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case POS_LIST:
    case POS_UPDATE:
    case POS_STATUS_UPDATE:
    case POS_DETAIL:
    case POS_PAYMENT_UPDATE:
      return {
        ...state,
        createOrUpdated: false,
        paymentUpdated: false,
        posDetail: null,
      };

    // case POS_ID_GENERATION_CHECK:
    //   return {
    //     ...state,
    //     idIDGenarationChecked: false,
    //     idInfo: null,
    //   };

    // case POS_ID_GENERATION_CHECK_SUCCESS: {
    //   return {
    //     ...state,
    //     idIDGenarationChecked: true,
    //     idInfo: payload.idInfo
    //   };
    // }

    case GENERATE_POS_ID: {
      return { ...state, posNo: "", idIDGenarationChecked: false };
    }

    case GENERATE_POS_ID_SUCCESS: {
      return { ...state, posNo: payload.posNo, idIDGenarationChecked: true };
    }

    case POS_NEW:
      return {
        ...state,
        posAdded: false,
        newProductData: null,
      };

    case POS_NEW_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        createOrUpdated: true,
        posAdded: true,
        newProductData: data,
        posByRole: [...state.posByRole, data],
      };
    }

    case POS_STATUS_UPDATE_SUCCESS:
    case POS_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case POS_PAYMENT_UPDATE_SUCCESS: {
      return { ...state, paymentUpdated: true };
    }

    case POS_DETAIL_SUCCESS: {
      return { ...state, posDetail: payload.pos };
    }

    case POS_LIST_SUCCESS:
      return { ...state, pos: payload.pos };
    case POS_LIST_FAILURE:
      return { ...state, pos: [] };

    case POS_LIST_BY_ROLE:
      return { ...state, posByRole: [] };
    case POS_LIST_BY_ROLE_SUCCESS:
      return { ...state, posByRole: payload.posByRole };
    case POS_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case POS_CATEGORIES_LIST:
      return { ...state, posCategory: null };
    case POS_CATEGORIES_LIST_SUCCESS:
      return { ...state, posCategory: payload.posCategory };
    case POS_CATEGORIES_LIST_FAILURE:
      return { ...state };

    case POS_DELETE_SUCCESS:
      return {
        ...state,
        pos: state.pos.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
