var CryptoJS = require("crypto-js");

export let CryptoJSAesJson = {
  /**
   * Encrypt any value
   * @param {*} value
   * @param {string} password
   * @return {string}
   */
  encrypt: function (value) {
    return CryptoJS.AES.encrypt(
      JSON.stringify(value),
      `${process.env.REACT_APP_KEY}`,
      { format: CryptoJSAesJson }
    ).toString();
  },
  /**
   * Decrypt a previously encrypted value
   * @param {string} jsonStr
   * @param {string} password
   * @return {*}
   */
  decrypt: function (jsonStr) {
    if (jsonStr != undefined && jsonStr != null) {
      let data = JSON.parse(
        CryptoJS.AES.decrypt(JSON.stringify(jsonStr), `${process.env.REACT_APP_KEY}`, {
          format: CryptoJSAesJson,
        }).toString(CryptoJS.enc.Utf8)
      );
      // console.log("JSON.parse(data) ", JSON.parse(data) );
      return JSON.parse(data)  
    }
  },
  /**
   * Stringify cryptojs data
   * @param {Object} cipherParams
   * @return {string}
   */
  stringify: function (cipherParams) {
    var j = { ct: cipherParams.ciphertext.toString(CryptoJS.enc.Base64) };
    if (cipherParams.iv) j.iv = cipherParams.iv.toString();
    if (cipherParams.salt) j.s = cipherParams.salt.toString();
    return JSON.stringify(j).replace(/\s/g, "");
  },
  /**
   * Parse cryptojs data
   * @param {string} jsonStr
   * @return {*}
   */
  parse: function (jsonStr) {
    var j = JSON.parse(jsonStr);
    var cipherParams = CryptoJS.lib.CipherParams.create({
      ciphertext: CryptoJS.enc.Base64.parse(j.ct),
    });
    if (j.iv) cipherParams.iv = CryptoJS.enc.Hex.parse(j.iv);
    if (j.s) cipherParams.salt = CryptoJS.enc.Hex.parse(j.s);
    return cipherParams;
  },
};

export const formatDate = (date, dateFormat = "dd/MM/yyyy") => {
  let dateFormatter = dateFormat;
  if (dateFormatter === null) {
    dateFormatter = "dd/MM/yyyy";
  }
};

function getCurrentFinancialYear(monthForFiscalYear) {
  var startYear = "";
  var endYear = "";
  var today = new Date();
  if (today.getMonth() + 1 <= monthForFiscalYear) {
    startYear = today.getFullYear() - 1;
    endYear = today.getFullYear();
  } else {
    startYear = today.getFullYear();
    endYear = today.getFullYear() + 1;
  }
  return { startYear, endYear };
}

export const generateFinancialYears = (
  numberOfYears = 5,
  monthForFiscalYear = 3
) => {
  const currentFinancialYear = getCurrentFinancialYear(monthForFiscalYear);
  const data = [];
  let currentStartYear = currentFinancialYear.startYear;
  let currentEndYear = currentFinancialYear.endYear;

  for (var i = 0; i < numberOfYears; i++) {
    const startDate = new Date(
      currentStartYear,
      monthForFiscalYear,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      currentEndYear,
      monthForFiscalYear - 1,
      31,
      0,
      0,
      0,
      0
    );

    data.push({
      startDate: startDate,
      endDate: endDate,
      text: currentStartYear + " - " + currentEndYear,
    });

    currentStartYear -= 1;
    currentEndYear -= 1;
  }
  return data;
};

export const generateYears = (
  numberOfYears = 5,
  monthForFiscalYear = 0
) => {
  const currentFinancialYear = getCurrentFinancialYear(monthForFiscalYear);
  const data = [];
  let currentStartYear = currentFinancialYear.startYear;
  let currentEndYear = currentFinancialYear.endYear;

  for (var i = 0; i < numberOfYears; i++) {
    const startDate = new Date(
      currentStartYear,
      monthForFiscalYear,
      1,
      0,
      0,
      0,
      0
    );
    const endDate = new Date(
      currentEndYear,
      monthForFiscalYear - 1,
      31,
      0,
      0,
      0,
      0
    );

    data.push({
      startDate: startDate,
      endDate: endDate,
      text: currentStartYear + " - " + currentEndYear,
    });

    currentStartYear -= 1;
    currentEndYear -= 1;
  }
  return data;
};

export function checkFileType(file) {
  // Retrieve the file's MIME type
  var fileType = file.type;
  let obj = {
    fileType: "",
    file: file,
  };

  if (fileType === "text/csv") {
    // console.log("The file is a CSV.");
    obj.fileType = "CSV";
  } else if (fileType === "application/pdf") {
    // console.log("The file is a PDF.");
    obj.fileType = "PDF";
  } else {
    // console.log("The file type is not recognized.");
    obj.fileType = "un-recognized";
  }
  return obj;
}

export const compareStateAndGST = (stateCode, gst) => {
  const stateAndCode = [
    { stateCode: "AN", gstCode: "35" },
    { stateCode: "AP", gstCode: "37" },
    { stateCode: "AR", gstCode: "12" },
    { stateCode: "AS", gstCode: "18" },
    { stateCode: "BR", gstCode: "10" },
    { stateCode: "CH", gstCode: "04" },
    { stateCode: "CG", gstCode: "22" },
    { stateCode: "DN", gstCode: "26" },
    { stateCode: "DD", gstCode: "25" },
    { stateCode: "DL", gstCode: "07" },
    { stateCode: "GA", gstCode: "30" },
    { stateCode: "GJ", gstCode: "24" },
    { stateCode: "HR", gstCode: "06" },
    { stateCode: "HP", gstCode: "02" },
    { stateCode: "JK", gstCode: "01" },
    { stateCode: "JH", gstCode: "20" },
    { stateCode: "KA", gstCode: "29" },
    { stateCode: "KL", gstCode: "32" },
    { stateCode: "LA", gstCode: "38" },
    { stateCode: "LD", gstCode: "31" },
    { stateCode: "MP", gstCode: "23" },
    { stateCode: "MH", gstCode: "27" },
    { stateCode: "MN", gstCode: "14" },
    { stateCode: "ML", gstCode: "17" },
    { stateCode: "MZ", gstCode: "15" },
    { stateCode: "NL", gstCode: "13" },
    { stateCode: "OR", gstCode: "21" },
    { stateCode: "OT", gstCode: "97" },
    { stateCode: "PY", gstCode: "34" },
    { stateCode: "PB", gstCode: "03" },
    { stateCode: "RJ", gstCode: "08" },
    { stateCode: "SK", gstCode: "11" },
    { stateCode: "TN", gstCode: "33" },
    { stateCode: "TS", gstCode: "36" },
    { stateCode: "TR", gstCode: "16" },
    { stateCode: "UP", gstCode: "09" },
    { stateCode: "UA", gstCode: "05" },
    { stateCode: "MN", gstCode: "14" },
    { stateCode: "WB", gstCode: "19" },
    { stateCode: "FC", gstCode: "96" },
  ];
  if (gst === "") {
    return true;
  }
  if (stateCode !== "" && gst !== "") {
    const item = stateAndCode.find((item) => item.stateCode === stateCode);
    if (item) {
      if (gst.substring(0, 2) === item.gstCode) {
        return true;
      } else {
        return false;
      }
    }
  }
  return false;
};

export function toWords(number) {
  let numberInput = number?.split(".")[0];

  let oneToTwenty = [
    "",
    "one ",
    "two ",
    "three ",
    "four ",
    "five ",
    "six ",
    "seven ",
    "eight ",
    "nine ",
    "ten ",
    "eleven ",
    "twelve ",
    "thirteen ",
    "fourteen ",
    "fifteen ",
    "sixteen ",
    "seventeen ",
    "eighteen ",
    "nineteen ",
  ];
  let tenth = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
  ];

  if (numberInput.toString().length > 7) return "overlimit";

  //let num = ('0000000000'+ numberInput).slice(-10).match(/^(\d{1})(\d{2})(\d{2})(\d{2})(\d{1})(\d{2})$/);
  let num = ("0000000" + numberInput)
    .slice(-7)
    .match(/^(\d{1})(\d{1})(\d{2})(\d{1})(\d{2})$/);

  if (!num) return;
  let outputText =
    num[1] != 0
      ? (oneToTwenty[Number(num[1])] ||
          `${tenth[num[1][0]]} ${oneToTwenty[num[1][1]]}`) + " million "
      : "";
  outputText +=
    num[2] != 0
      ? (oneToTwenty[Number(num[2])] ||
          `${tenth[num[2][0]]} ${oneToTwenty[num[2][1]]}`) + "hundred "
      : "";
  outputText +=
    num[3] != 0
      ? (oneToTwenty[Number(num[3])] ||
          `${tenth[num[3][0]]} ${oneToTwenty[num[3][1]]}`) + " thousand "
      : "";
  outputText +=
    num[4] != 0
      ? (oneToTwenty[Number(num[4])] ||
          `${tenth[num[4][0]]} ${oneToTwenty[num[4][1]]}`) + "hundred "
      : "";
  outputText +=
    num[5] != 0
      ? oneToTwenty[Number(num[5])] ||
        `${tenth[num[5][0]]} ${oneToTwenty[num[5][1]]} `
      : "";

  return outputText;
}

export const generateDate = (date) => {
  let newDateFormat = new Date(date);
  newDateFormat =
    newDateFormat.toDateString() + " At " + newDateFormat.toLocaleTimeString();
  return newDateFormat;
};

export const splitCSVString = (value, join) => {
  return String(value)
    .split("\n")
    .join(join ? join : "/");
};

// export const getFirebaseTime = (timeString)=>{
//   if(timeString !== ""){
//     let time = new Date(timeString.toDate())
//     return time.toLocaleTimeString()
//   }
//   return ""
// }

export const getFirebaseTime = (timeString) => {
  if (timeString !== "") {
    // Assuming timeString is a valid string representation of a date
    let time = new Date(timeString);
    return time.toLocaleTimeString();
  }
  return "";
};

export let mergeDateAndTime = (date, time) => {
  if (date !== "" && time !== "") {
    let modifiedDate = new Date(date).toDateString();
    let modifiedTime = new Date(time).toTimeString();
    return `${modifiedDate} ${modifiedTime}`;
  }
  if (date !== "") {
    let modifiedDate = new Date(date).toDateString();
    return modifiedDate;
  }
};

export function firstLetterCapital(letter) {
  if (letter != undefined && letter != null && letter != "") {
    return letter.charAt(0).toUpperCase() + letter.slice(1);
  }
}
