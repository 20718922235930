import api from "../api";

export const fetchSalesReturnAsync = async (uid) => await api.get(`/saleReturn`).then((res) => res.data).catch((err) => err);

export const fetchSalesReturnByRoleAsync = async (uid) => await api.get(`/saleReturn/assign`).then((res) => res.data).catch((err) => err);

export const createSalesReturnAsync = async (payload) => await api.post(`/saleReturn`, payload).then((res) => res.data).catch((err) => err);

export const updateSalesReturnAsync = async (payload, id) => await api.put(`/saleReturn/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updateSalesReturnStatusAsync = async (payload, id) => await api.put(`/saleReturn/status/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updateSalesReturnPaymentInfoAsync = async (payload, id) =>
  await api
    .put(`/saleReturn/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchSalesReturnDetailAsync = async (id) => await api.get(`/saleReturn/${id}`).then((res) => res.data).catch((err) => err);

export const deleteSalesReturnAsync = async (id) => await api.delete(`/saleReturn/${id}`).then((res) => res.data).catch((err) => err);