import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  ADMIN_DELETE,
  ADMIN_DELETE_FAILURE,
  ADMIN_DELETE_SUCCESS,
  ADMIN_DETAIL,
  ADMIN_DETAIL_FAILURE,
  ADMIN_DETAIL_SUCCESS,
  ADMIN_LIST,
  ADMIN_LIST_BY_ROLE,
  ADMIN_LIST_BY_ROLE_FAILURE,
  ADMIN_LIST_BY_ROLE_SUCCESS,
  ADMIN_LIST_FAILURE,
  ADMIN_LIST_SUCCESS,
  ADMIN_NEW,
  ADMIN_NEW_FAILURE,
  ADMIN_NEW_SUCCESS,
  ADMIN_STATUS_UPDATE,
  ADMIN_STATUS_UPDATE_FAILURE,
  ADMIN_STATUS_UPDATE_SUCCESS,
  ADMIN_UPDATE,
  ADMIN_UPDATE_FAILURE,
  ADMIN_UPDATE_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action.js";
import {
  createAdminAsync,
  deleteAdminAsync,
  fetchAdminAsync,
  fetchAdminDetailAsync,
  generateAdminNoAsync,
  updateAdminAsync,
  updateAdminStatusAsync,
} from "../../services/admin.service.js";

export function* watchFetchAdmin() {
  yield takeLatest(ADMIN_LIST, fetchAdmin);
}

// export function* watchFetchAdminByRole() {
//   yield takeLatest(ADMIN_LIST_BY_ROLE, fetchAdminByRole);
// }

// export function* watchGenerateAdminNo() {
//   yield takeLatest(GENERATE_ADMIN_ID, generateAdminNo);
// }

export function* watchCreateAdmin() {
  yield takeLatest(ADMIN_NEW, createNewAdmin);
}

export function* watchUpdateAdmin() {
  yield takeLatest(ADMIN_UPDATE, updateAdmin);
}

export function* watchGetDetailAdmin() {
  yield takeLatest(ADMIN_DETAIL, fetchAdminDetail);
}

export function* watchDeleteAdmin() {
  yield takeLatest(ADMIN_DELETE, deleteAdmin);
}

export function* watchUpdateAdminStatus() {
  yield takeLatest(ADMIN_STATUS_UPDATE, updateAdminStatus);
}

function* fetchAdmin({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchAdminAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: ADMIN_LIST_SUCCESS,
        payload: { admins: response.data },
      });
    } else {
      yield put({ type: ADMIN_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADMIN_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

// function* fetchAdminByRole({ payload }) {
//   // const { uid } = payload;
//   try {
//     const response = yield call(fetchAdminByRoleAsync);
//     yield put(hideLoader());
//     if (response.status === 200 || response.status === 201) {
//       yield put({
//         type: ADMIN_LIST_BY_ROLE_SUCCESS,
//         payload: { adminsByRole: response.data },
//       });
//     } else {
//       yield put({ type: ADMIN_LIST_BY_ROLE_FAILURE });
//       yield put(showMessage("error", response.message));
//     }
//   } catch (error) {
//     yield put({ type: ADMIN_LIST_BY_ROLE_FAILURE });
//     yield put(showMessage("error", error));
//   }
// }

// function* generateAdminNo({ payload }) {
//   const { uid } = payload;
//   try {
//     const response = yield call(generateAdminNoAsync, uid);
//     yield put(hideLoader());
//     if (response.status === 200 || response.status === 201) {
//       yield put({
//         type: GENERATE_ADMIN_ID_SUCCESS,
//         payload: { adminNo: response.data },
//       });
//     } else {
//       yield put({ type: GENERATE_ADMIN_ID_FAILURE });
//       yield put(showMessage("error", response.message));
//     }
//   } catch (error) {
//     yield put({ type: GENERATE_ADMIN_ID_FAILURE });
//     yield put(showMessage("error", error));
//   }
// }

function* createNewAdmin({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createAdminAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: ADMIN_NEW_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ADMIN_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADMIN_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateAdmin({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateAdminAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: ADMIN_UPDATE_SUCCESS,
        payload: { admin: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ADMIN_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADMIN_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function*  updateAdminStatus({ payload }) {
  const { data, id} = payload;
  
  try {
    const response = yield call(updateAdminStatusAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: ADMIN_STATUS_UPDATE_SUCCESS,
        payload: { admin: response.data },
      });
    } else {
      yield put({ type: ADMIN_STATUS_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADMIN_STATUS_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchAdminDetail({ payload }) {
  const { id, email } = payload;
  try {
    const response = yield call(fetchAdminDetailAsync, id, email);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: ADMIN_DETAIL_SUCCESS,
        payload: { admin: response.data },
      });
    } else {
      yield put({ type: ADMIN_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADMIN_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteAdmin({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteAdminAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: ADMIN_DELETE_SUCCESS,
        payload: { id: id },
      });
    } else {
      yield put({ type: ADMIN_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADMIN_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchAdmin),
    // fork(watchFetchAdminByRole),
    // fork(watchGenerateAdminNo),
    fork(watchCreateAdmin),
    fork(watchUpdateAdmin),
    fork(watchUpdateAdminStatus),
    fork(watchGetDetailAdmin),
    fork(watchDeleteAdmin),
  ]);
}
