import {
  PURCHASE_DELETE_SUCCESS,
  PURCHASE_DETAIL,
  PURCHASE_DETAIL_SUCCESS,
  PURCHASE_LIST,
  PURCHASE_LIST_BY_ROLE,
  PURCHASE_LIST_BY_ROLE_FAILURE,
  PURCHASE_LIST_BY_ROLE_SUCCESS,
  PURCHASE_LIST_FAILURE,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_NEW,
  PURCHASE_NEW_SUCCESS,
  PURCHASE_PAYMENT_UPDATE,
  PURCHASE_PAYMENT_UPDATE_SUCCESS,
  PURCHASE_STATUS_UPDATE,
  PURCHASE_STATUS_UPDATE_SUCCESS,
  PURCHASE_UPDATE,
  PURCHASE_UPDATE_SUCCESS,
  STOCK_REPORT_LIST,
  STOCK_REPORT_LIST_FAILURE,
  STOCK_REPORT_LIST_SUCCESS,
} from "../../types.js";

const initialState = {
  purchases: [],
  purchaseOrders: [],
  purchaseDetail: null,
  createOrUpdated: false,
  paymentUpdated: false,

  // Brands by role
  purchasesByRole: [],

  purchaseAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PURCHASE_LIST:
    case STOCK_REPORT_LIST:
    case PURCHASE_UPDATE:
    case PURCHASE_STATUS_UPDATE:
    case PURCHASE_DETAIL:
    case PURCHASE_PAYMENT_UPDATE:
      return {
        ...state,
        createOrUpdated: false,
        paymentUpdated: false,
        purchaseDetail: null,
        purchaseOrders: [],
      };

    case PURCHASE_NEW:
      return {
        ...state,
        purchaseAdded: false,
        newProductData: null,
      };

    case PURCHASE_NEW_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        createOrUpdated: true,
        purchaseAdded: true,
        newProductData: data,
        purchasesByRole: [...state.purchasesByRole, data],
      };
    }

    case PURCHASE_STATUS_UPDATE_SUCCESS:
    case PURCHASE_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case PURCHASE_PAYMENT_UPDATE_SUCCESS: {
      return { ...state, paymentUpdated: true };
    }

    case PURCHASE_DETAIL_SUCCESS: {
      return { ...state, purchaseDetail: payload.purchase };
    }

    case PURCHASE_LIST_SUCCESS:
      return { ...state, purchases: payload.purchases };
    case PURCHASE_LIST_FAILURE:
      return { ...state, purchases: [] };

    case STOCK_REPORT_LIST_SUCCESS:
      return { ...state, purchaseOrders: payload.purchaseOrders };
    case STOCK_REPORT_LIST_FAILURE:
      return { ...state, purchaseOrders: [] };

    case PURCHASE_LIST_BY_ROLE:
      return { ...state, purchasesByRole: [] };
    case PURCHASE_LIST_BY_ROLE_SUCCESS:
      return { ...state, purchasesByRole: payload.purchasesByRole };
    case PURCHASE_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case PURCHASE_DELETE_SUCCESS:
      return {
        ...state,
        purchases: state.purchases.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
