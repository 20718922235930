import jwtDecode from "jwt-decode";
import { logoutUser, refreshToken } from "../redux/auth/actions";
import axiosInstance from "./api";
import TokenService from "./token.service";
import { showMessage } from "../redux/lem/action";
import { t } from "i18next";

const setup = (store) => {

  axiosInstance.interceptors.request.use(
    (configuration) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        configuration.headers.Authorization = `Bearer ${token}`;
      }
      return configuration;
    },
    (error) => Promise.reject(error)
  );

  const { dispatch } = store;
  
  axiosInstance.interceptors.response.use(
    (res) => res,
    async (err) => {
      const originalConfig = err.config;
      // if (err.request) {
      //   // console.error('Network Error:', err.request);
      //   dispatch(showMessage("error", "Failed to load response from the server!"))
      // } else {
      //   // Something happened in setting up the request that triggered an Error
      //   // console.error('Error:', err.message);
      //   dispatch(showMessage("error", "Something went wrong!"))
      // }
      if(err.message == "Network Error"){
        dispatch(showMessage("error", "Failed to load response from the server", 10000))
      }

      if (originalConfig.url !== "/auth/login" && err.response) {
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            const rs = await axiosInstance.post("/auth/refresh-token", {
              refreshToken: TokenService.getLocalRefreshToken(),
            });
            const { data } = rs?.data;
            const decoded = jwtDecode(data.accessToken).role.permissions;

            dispatch(refreshToken(data.accessToken, data.refreshToken, decoded));
            TokenService.updateLocalAccessToken(
              data.accessToken,
              data.refreshToken
            );

            return axiosInstance(originalConfig);
          } catch (_error) {
            return Promise.reject(_error);
          }
        }

        if(err?.response?.status == 403 && err?.response?.data?.message == "user_not_active" && !originalConfig._retry){
          dispatch(showMessage("error", `${t(`${err?.response?.data?.message}`)} user will be logged out in 10 seconds`, 10000))
          setTimeout(() => {
            dispatch(logoutUser());
          }, 10000);          
        }
      }
      return Promise.reject(err.response);
    }
  );
};

export default setup;
