import api from "../api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchProductAsync = async (uid) =>
  await api
    .get(`/products`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchProductByRoleAsync = async (uid) =>
  await api
    .get(`/products/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const createProductAsync = async (payload) =>
  await api
    .post(`/products`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateProductAsync = async (payload, id) =>
  await api
    .put(`/products/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateProductStatusAsync = async (payload, id) =>
  await api
    .put(`/products/status/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchProductDetailAsync = async (id) =>
  await api
    .get(`/products/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteProductAsync = async (id) =>
  await api
    .delete(`/products/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
