import TokenService from "./token.service";
import { t } from "i18next";
import { generateMessage } from "./firebaseMessage";
import api from "./api"

let response = {
  status: null,
  data: null,
  message: "",
};

// export const fetchUserManagementAsync = async (uid) => {

//   try {
//     // const querySnapshot = await api.get(`/user-management?createdBy=${uid}`)
//     const querySnapshot = await api.get(`/user-management`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//     } else {
//       response = {
//         status: 400,
//         message: t("message_users_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const userManagementRef = collection(database, "users");

//   //   const q = query(userManagementRef, where("createdBy", "==", String(uid)));

//   //   const querySnapshot = await getDocs(q);

//   //   if (!querySnapshot.empty) {
//   //     const dataArr = [];
//   //     querySnapshot.forEach((doc) => {
//   //       dataArr.push(doc.data());
//   //     });
//   //     response = {
//   //       status: 200,
//   //       data: dataArr,
//   //       message: t("message_user_managements_get_success"),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 400,
//   //       message: t("message_users_not_found"),
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const createNewUserAsync = async (payload) => {

//   console.log("payload is", payload)
//   try {
//     const querySnapshot = await api.post(`/user-management`, payload)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_user_management_create_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: querySnapshot?.data?.message,
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);    
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // // const userObj = { ...request };
//   // const { email } = request;
//   // try {
//   //   const createUserResponse = await createUserWithEmailAndPassword(
//   //     authentication,
//   //     email,
//   //     String(Math.random())
//   //   );
//   //   let userId = createUserResponse.user.uid;
//   //   await sendPasswordResetEmail(authentication, email);
//   //   await setDoc(doc(database, "users", createUserResponse.user.uid), {
//   //     ...request,
//   //     userId: userId,
//   //     createdAt: new Date(),
//   //   });

//   //   const employOnboardingRef = collection(database, "employOnboardings");

//   //   const q = query(
//   //     employOnboardingRef,
//   //     where("officialEmailId", "==", String(email))
//   //   );

//   //   const querySnapshot = await getDocs(q);

//   //   if (!querySnapshot.empty) {
//   //     const dataArr = [];
//   //     querySnapshot.forEach((doc) => {
//   //       dataArr.push(doc.data());
//   //     });
//   //     let empObj = dataArr.find(item=>item.officialEmailId == email)
//   //     await updateDoc(doc(database, "employOnboardings", String(empObj._id)), {
//   //       isUserCreated:true,
//   //       lastModified: new Date(),
//   //     });      
//   //   }

//   //   response = {
//   //     status: 200,
//   //     message: t("message_user_new_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const updateUserManagementAsync = async (payload, id) => {

//   try {
//     const querySnapshot = await api.put(`/user-management/${id}`, payload)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_user_update_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_users_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await updateDoc(doc(database, "users", String(id)), {
//   //     ...payload,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_user_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const fetchUserManagementDetailAsync = async (id) => {

//   // console.log("id is = " , id)

//   try {
//     const querySnapshot = await api.get(`/user-management/${id}`)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//       console.log("payload is", querySnapshot.data)
//     } else {
//       response = {
//         status: 400,
//         message: t("message_users_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }


//   // try {
//   //   const docRef = doc(database, "users", id);
//   //   const docSnap = await getDoc(docRef);

//   //   if (docSnap.exists()) {
//   //     response = {
//   //       status: 200,
//   //       data: docSnap.data(),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 401,
//   //       data: null,
//   //       message: "user details not found",
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const deleteUserManagementAsync = async (id, userId) => {

//   try {
//     const querySnapshot = await api.delete(`/user-management?id=${id}&userId=${userId}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("user_management_deleted_succeful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_users_not_found"),
//       };
//     }
//     return response;
//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 400,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const docRef = doc(database, "users", String(id));
//   //   await deleteDoc(docRef);

//   //   response = {
//   //     status: 200,
//   //     message: "user deleted succesfully...",
//   //   };

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 400,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };


export const fetchUserManagementAsync = async (uid) => await api.get(`/user-management`).then((res) => res.data).catch((err) => err);

export const fetchUserManagementByRoleAsync = async (uid) => await api.get(`/user-management/assign`).then((res) => res.data).catch((err) => err);

export const createNewUserAsync = async (payload) => await api.post(`/user-management`, payload).then((res) => res.data).catch((err) => err);

export const updateUserManagementAsync = async (payload, id) => await api.put(`/user-management/${id}`, payload)

export const fetchUserManagementDetailAsync = async (id) => await api.get(`/user-management/${id}`).then((res) => res.data).catch((err) => err);

export const deleteUserManagementAsync = async (id, userId) => await api.delete(`/user-management?id=${id}&userId=${userId}`).then((res) => res.data).catch((err) => err);

export const logout = () => TokenService.removeUser();
