import {
  GENERATE_PO_ID,
  GENERATE_PO_ID_SUCCESS,
  PO_CATEGORIES_LIST,
  PO_CATEGORIES_LIST_FAILURE,
  PO_CATEGORIES_LIST_SUCCESS,
  PO_DELETE_SUCCESS,
  PO_DETAIL,
  PO_DETAIL_SUCCESS,
  PO_ID_GENERATION_CHECK,
  PO_ID_GENERATION_CHECK_SUCCESS,
  PO_LIST,
  PO_LIST_BY_ROLE,
  PO_LIST_BY_ROLE_FAILURE,
  PO_LIST_BY_ROLE_SUCCESS,
  PO_LIST_FAILURE,
  PO_LIST_SUCCESS,
  PO_NEW,
  PO_NEW_SUCCESS,
  PO_PAYMENT_UPDATE,
  PO_PAYMENT_UPDATE_SUCCESS,
  PO_STATUS_UPDATE,
  PO_STATUS_UPDATE_SUCCESS,
  PO_UPDATE,
  PO_UPDATE_SUCCESS,
} from "../../types.js";

const initialState = {
  po: [],
  poDetail: null,
  createOrUpdated: false,

  // Brands by role
  poByRole: [],
  poCategory: null,

  poAdded: false,

  // below variables are to check any id is generated or not before
  idInfo: null,
  idIDGenarationChecked: false,
  poNo: ""
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PO_LIST:
    case PO_UPDATE:
    case PO_STATUS_UPDATE:
    case PO_DETAIL:
    case PO_PAYMENT_UPDATE:
      return {
        ...state,
        createOrUpdated: false,
        paymentUpdated: false,
        poDetail: null,
      };

    // case PO_ID_GENERATION_CHECK:
    //   return {
    //     ...state,
    //     idIDGenarationChecked: false,
    //     idInfo: null,
    //   };

    // case PO_ID_GENERATION_CHECK_SUCCESS: {
    //   return {
    //     ...state,
    //     idIDGenarationChecked: true,
    //     idInfo: payload.idInfo
    //   };
    // }

    case GENERATE_PO_ID: {
      return { ...state, poNo: "", idIDGenarationChecked: false };
    }

    case GENERATE_PO_ID_SUCCESS: {
      return { ...state, poNo: payload.poNo, idIDGenarationChecked: true };
    }

    case PO_NEW:
      return {
        ...state,
        poAdded: false,
        newProductData: null,
      };

    case PO_NEW_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        createOrUpdated: true,
        poAdded: true,
        newProductData: data,
        poByRole: [...state.poByRole, data],
      };
    }

    case PO_STATUS_UPDATE_SUCCESS:
    case PO_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case PO_PAYMENT_UPDATE_SUCCESS: {
      return { ...state, paymentUpdated: true };
    }

    case PO_DETAIL_SUCCESS: {
      return { ...state, poDetail: payload.po };
    }

    case PO_LIST_SUCCESS:
      return { ...state, po: payload.po };
    case PO_LIST_FAILURE:
      return { ...state, po: [] };

    case PO_LIST_BY_ROLE:
      return { ...state, poByRole: [] };
    case PO_LIST_BY_ROLE_SUCCESS:
      return { ...state, poByRole: payload.poByRole };
    case PO_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case PO_CATEGORIES_LIST:
      return { ...state, poCategory: null };
    case PO_CATEGORIES_LIST_SUCCESS:
      return { ...state, poCategory: payload.poCategory };
    case PO_CATEGORIES_LIST_FAILURE:
      return { ...state };

    case PO_DELETE_SUCCESS:
      return {
        ...state,
        po: state.po.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
