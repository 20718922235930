import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  GENERATE_PO_ID,
  GENERATE_PO_ID_FAILURE,
  GENERATE_PO_ID_SUCCESS,
  PO_CATEGORIES_LIST,
  PO_CATEGORIES_LIST_FAILURE,
  PO_CATEGORIES_LIST_SUCCESS,
  PO_DELETE,
  PO_DELETE_FAILURE,
  PO_DELETE_SUCCESS,
  PO_DETAIL,
  PO_DETAIL_FAILURE,
  PO_DETAIL_SUCCESS,
  PO_ID_GENERATION_CHECK,
  PO_ID_GENERATION_CHECK_FAILURE,
  PO_ID_GENERATION_CHECK_SUCCESS,
  PO_LIST,
  PO_LIST_BY_ROLE,
  PO_LIST_BY_ROLE_FAILURE,
  PO_LIST_BY_ROLE_SUCCESS,
  PO_LIST_FAILURE,
  PO_LIST_SUCCESS,
  PO_NEW,
  PO_NEW_FAILURE,
  PO_NEW_SUCCESS,
  PO_PAYMENT_UPDATE,
  PO_PAYMENT_UPDATE_FAILURE,
  PO_PAYMENT_UPDATE_SUCCESS,
  PO_STATUS_UPDATE,
  PO_UPDATE,
  PO_UPDATE_FAILURE,
  PO_UPDATE_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createPOAsync,
  deletePOAsync,
  fetchPOAsync,
  fetchPOByRoleAsync,
  fetchPOCategoriesAsync,
  fetchPODetailAsync,
  generatePONoAsync,
  updatePOAsync,
  updatePOPaymentInfoAsync,
  updatePOtatusAsync,
} from "../../../services/purchase/po.service.js";
import { checkIDGeneratedAsync } from "../../../services/idGenerator.service.js";

export function* watchCheckIdGeneration() {
  yield takeLatest(PO_ID_GENERATION_CHECK, checkIDGenerated);
}

export function* watchGeneratePONo() {
  yield takeLatest(GENERATE_PO_ID, generatePONo);
}

export function* watchFetchPO() {
  yield takeLatest(PO_LIST, fetchPOList);
}

export function* watchFetchPOByRole() {
  yield takeLatest(PO_LIST_BY_ROLE, fetchPOListByRole);
}

export function* watchFetchPOCategories() {
  yield takeLatest(PO_CATEGORIES_LIST, fetchPOCategories);
}

export function* watchCreatePO() {
  yield takeLatest(PO_NEW, createNewPO);
}

export function* watchUpdatePO() {
  yield takeLatest(PO_UPDATE, updatePO);
}

export function* watchUpdatePOtatus() {
  yield takeLatest(PO_STATUS_UPDATE, updatePOtatus);
}

export function* watchUpdatePOPaymentInfo() {
  yield takeLatest(PO_PAYMENT_UPDATE, updatePOPaymentInfo);
}

export function* watchGetDetailPO() {
  yield takeLatest(PO_DETAIL, fetchPODetail);
}

export function* watchDeletePO() {
  yield takeLatest(PO_DELETE, deletePO);
}

function* checkIDGenerated({ payload }) {
  const { moduleSlug } = payload;

  try {
    const response = yield call(checkIDGeneratedAsync, moduleSlug);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PO_ID_GENERATION_CHECK_SUCCESS,
        payload: { idInfo: response.data },
      });
    } else {
      yield put({ type: PO_ID_GENERATION_CHECK_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_ID_GENERATION_CHECK_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* generatePONo({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(generatePONoAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: GENERATE_PO_ID_SUCCESS,
        payload: { poNo: response.data },
      });
    } else {
      yield put({ type: GENERATE_PO_ID_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GENERATE_PO_ID_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPOList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPOAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PO_LIST_SUCCESS,
        payload: { po: response.data },
      });
    } else {
      yield put({ type: PO_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPOListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPOByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PO_LIST_BY_ROLE_SUCCESS,
        payload: { poByRole: response.data },
      });
    } else {
      yield put({ type: PO_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPOCategories({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchPOCategoriesAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PO_CATEGORIES_LIST_SUCCESS,
        payload: { poCategory: response.data },
      });
    } else {
      yield put({ type: PO_CATEGORIES_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_CATEGORIES_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewPO({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createPOAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PO_NEW_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PO_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePO({ payload }) {
  const { data, id } = payload;
  
  try {
    const response = yield call(updatePOAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PO_UPDATE_SUCCESS,
        payload: { po: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PO_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePOtatus({ payload }) {
  const { data, id} = payload;
  
  try {
    const response = yield call(updatePOtatusAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PO_UPDATE_SUCCESS,
        payload: { po: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PO_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePOPaymentInfo({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updatePOPaymentInfoAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PO_PAYMENT_UPDATE_SUCCESS,
        payload: { po: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PO_PAYMENT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_PAYMENT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPODetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchPODetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PO_DETAIL_SUCCESS,
        payload: { po: response.data },
      });
    } else {
      yield put({ type: PO_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deletePO({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deletePOAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PO_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PO_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PO_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchCheckIdGeneration),
    fork(watchGeneratePONo),
    fork(watchFetchPO),
    fork(watchFetchPOByRole),
    fork(watchFetchPOCategories),
    fork(watchCreatePO),
    fork(watchUpdatePO),
    fork(watchUpdatePOtatus),
    fork(watchUpdatePOPaymentInfo),
    fork(watchGetDetailPO),
    fork(watchDeletePO),
  ]);
}
