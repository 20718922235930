import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  USER_DELETE,
  USER_DELETE_FAILURE,
  USER_DELETE_SUCCESS,
  USER_DETAIL,
  USER_DETAIL_FAILURE,
  USER_DETAIL_SUCCESS,
  USER_LIST,
  USER_LIST_BY_ROLE,
  USER_LIST_BY_ROLE_FAILURE,
  USER_LIST_BY_ROLE_SUCCESS,
  USER_LIST_FAILURE,
  USER_LIST_SUCCESS,
  USER_NEW,
  USER_NEW_FAILURE,
  USER_NEW_SUCCESS,
  USER_UPDATE,
  USER_UPDATE_FAILURE,
  USER_UPDATE_SUCCESS,
} from "../types";

import {
  createNewUserAsync,
  deleteUserManagementAsync,
  fetchUserManagementAsync,
  fetchUserManagementByRoleAsync,
  fetchUserManagementDetailAsync,
  updateUserManagementAsync,
} from "../../services/user.service";
import { hideLoader, showMessage } from "../lem/action";

export function* watchFetchUser() {
  yield takeLatest(USER_LIST, fetchUser);
}

export function* watchFetchUserByRole() {
  yield takeLatest(USER_LIST_BY_ROLE, fetchUserByRole);
}

export function* watchCreateNewUser() {
  yield takeLatest(USER_NEW, createNewUser);
}

export function* watchUpdateUser() {
  yield takeLatest(USER_UPDATE, updateUser);
}

export function* watchGetDetailUser() {
  yield takeLatest(USER_DETAIL, fetchUserDetail);
}

export function* watchDeleteUser() {
  yield takeLatest(USER_DELETE, deleteUser);
}

function* fetchUser({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchUserManagementAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: USER_LIST_SUCCESS,
        payload: { users: response.data },
      });
    } else {
      yield put({ type: USER_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: USER_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchUserByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchUserManagementByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: USER_LIST_BY_ROLE_SUCCESS,
        payload: { usersByRole: response.data },
      });
    } else {
      yield put({ type: USER_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: USER_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewUser({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createNewUserAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: USER_NEW_SUCCESS,
        payload: { user: response.data },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: USER_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: USER_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateUser({ payload }) {
  const { data, userid } = payload;
  try {
    const response = yield call(updateUserManagementAsync, data, userid);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: USER_UPDATE_SUCCESS,
        payload: { user: response.data },
      });
      // showMessage("success", response.message);
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: USER_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: USER_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchUserDetail({ payload }) {
  
  const { userid } = payload;
  try {
    const response = yield call(fetchUserManagementDetailAsync, userid);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: USER_DETAIL_SUCCESS,
        payload: { user: response.data },
      });
    } else {
      yield put({ type: USER_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: USER_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteUser({ payload }) {
  const { id , userId } = payload;
  try {
    const response = yield call(deleteUserManagementAsync, id, userId);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: USER_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: USER_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: USER_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchUser),
    fork(watchFetchUserByRole),
    fork(watchCreateNewUser),
    fork(watchUpdateUser),
    fork(watchGetDetailUser),
    fork(watchDeleteUser),
  ]);
}
