import api from "../api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchCategoryAsync = async (uid) =>
  await api
    .get(`/category`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchCategoryByRoleAsync = async (uid) =>
  await api
    .get(`/category/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const createCategoryAsync = async (payload) =>
  await api
    .post(`/category`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateCategoryAsync = async (payload, id) =>
  await api
    .put(`/category/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchCategoryDetailAsync = async (id) =>
  await api
    .get(`/category/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteCategoryAsync = async (id) =>
  await api
    .delete(`/category/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
