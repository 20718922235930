import api from "../api";

export const generatePONoAsync = async (uid) => await api.get(`/purchasesPo/poNo/generatePoNo`).then((res) => res.data).catch((err) => err);

export const fetchPOAsync = async (uid) => await api.get(`/purchasesPo`).then((res) => res.data).catch((err) => err);

export const fetchPOByRoleAsync = async (uid) => await api.get(`/purchasesPo/assign`).then((res) => res.data).catch((err) => err);

export const fetchPOCategoriesAsync = async (id) => await api.get(`/po/categories?supplierId=${id}`).then((res) => res.data).catch((err) => err);

export const createPOAsync = async (payload) => await api.post(`/purchasesPo`, payload).then((res) => res.data).catch((err) => err);

export const updatePOAsync = async (payload, id) => await api.put(`/purchasesPo/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updatePOtatusAsync = async (payload, id) => await api.put(`/purchasesPo/status/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updatePOPaymentInfoAsync = async (payload, id) =>
  await api
    .put(`/purchasesPo/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchPODetailAsync = async (id) => await api.get(`/purchasesPo/${id}`).then((res) => res.data).catch((err) => err);

export const deletePOAsync = async (id) => await api.delete(`/purchasesPo/${id}`).then((res) => res.data).catch((err) => err);