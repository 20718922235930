import {
  SALES_RETURN_DELETE_SUCCESS,
  SALES_RETURN_DETAIL,
  SALES_RETURN_DETAIL_SUCCESS,
  SALES_RETURN_LIST,
  SALES_RETURN_LIST_BY_ROLE,
  SALES_RETURN_LIST_BY_ROLE_FAILURE,
  SALES_RETURN_LIST_BY_ROLE_SUCCESS,
  SALES_RETURN_LIST_FAILURE,
  SALES_RETURN_LIST_SUCCESS,
  SALES_RETURN_NEW,
  SALES_RETURN_NEW_SUCCESS,
  SALES_RETURN_PAYMENT_UPDATE,
  SALES_RETURN_PAYMENT_UPDATE_SUCCESS,
  SALES_RETURN_STATUS_UPDATE,
  SALES_RETURN_STATUS_UPDATE_SUCCESS,
  SALES_RETURN_UPDATE,
  SALES_RETURN_UPDATE_SUCCESS,
} from "../../types.js";

const initialState = {
  salesReturns: [],
  salesReturnDetail: null, 
  createOrUpdated: false,

  // Brands by role
  salesReturnsByRole: [],

  saleReturnAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SALES_RETURN_LIST:
    case SALES_RETURN_UPDATE:
    case SALES_RETURN_STATUS_UPDATE:
    case SALES_RETURN_DETAIL:
    case SALES_RETURN_PAYMENT_UPDATE:
      return {
        ...state,
        createOrUpdated: false,
        paymentUpdated: false,
        salesReturnDetail: null,
      };

    case SALES_RETURN_NEW:
      return {
        ...state,
        saleReturnAdded: false,
        newProductData: null,
      };

    case SALES_RETURN_NEW_SUCCESS: {
      const { data } = action.payload;
      return { ...state, createOrUpdated: true, saleReturnAdded: true, newProductData: data, salesReturnsByRole: [...state.salesReturnsByRole, data] };
    }
    
    case SALES_RETURN_STATUS_UPDATE_SUCCESS:
    case SALES_RETURN_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case SALES_RETURN_PAYMENT_UPDATE_SUCCESS: {
      return { ...state, paymentUpdated: true };
    }

    case SALES_RETURN_DETAIL_SUCCESS: {
      return { ...state, salesReturnDetail: payload.salesReturnDetail };
    }

    case SALES_RETURN_LIST_SUCCESS:
      return { ...state, salesReturns: payload.salesReturns };
    case SALES_RETURN_LIST_FAILURE:
      return { ...state, salesReturns: [] };

    case SALES_RETURN_LIST_BY_ROLE:
      return { ...state, salesReturnsByRole: [] };
    case SALES_RETURN_LIST_BY_ROLE_SUCCESS:
      return { ...state, salesReturnsByRole: payload.salesReturnsByRole };
    case SALES_RETURN_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case SALES_RETURN_DELETE_SUCCESS:
      return {
        ...state,
        salesReturns: state.salesReturns.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
