import { all, call, put, fork, takeLatest } from "redux-saga/effects";

import {
  PROFILE_GET,
  PROFILE_GET_SUCCESS,
  PROFILE_GET_FAILURE,
  PROFILE_DETAIL,
  PROFILE_DETAIL_SUCCESS,
  PROFILE_DETAIL_FAILURE,
  PROFILE_BANK_DETAIL,
  PROFILE_BANK_DETAIL_SUCCESS,
  PROFILE_BANK_DETAIL_FAILURE,
  PROFILE_GET_BY_ROLE,
  PROFILE_GET_BY_ROLE_SUCCESS,
  PROFILE_GET_BY_ROLE_FAILURE,
} from "../types";
import {
  addProfileBankDetailsAsync,
  addProfileDetailsAsync,
  fetchProfileByRoleAsync,
  fetchProfilesAsync,
} from "../../services/profile.service";
import { hideLoader, showMessage } from "../lem/action";
import { uploadFile } from "../common/saga";

export function* watchFetchProfiles() {
  yield takeLatest(PROFILE_GET, fetchProfileInfo);
}

export function* watchFetchProfileByRole() {
  yield takeLatest(PROFILE_GET_BY_ROLE, fetchProfileInfoByRole);
}

export function* watchAddProfileDetails() {
  yield takeLatest(PROFILE_DETAIL, addProfileDetails);
}

export function* watchAddProfileBankDetails() {
  yield takeLatest(PROFILE_BANK_DETAIL, addProfileBankDetails);
}

function* fetchProfileInfo({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchProfilesAsync, String(uid));
    hideLoader();
    // console.log("response", response)
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PROFILE_GET_SUCCESS,
        payload: { data: response.data },
      });
    } else {
      yield put({ type: PROFILE_GET_FAILURE });
      // yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROFILE_GET_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchProfileInfoByRole({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchProfileByRoleAsync, String(uid));
    hideLoader();
    // console.log("response", response)
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PROFILE_GET_BY_ROLE_SUCCESS,
        payload: { data: response.data },
      });
    } else {
      yield put({ type: PROFILE_GET_BY_ROLE_FAILURE });
      // yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROFILE_GET_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* addProfileDetails({ payload }) {
  try {
    const { data, logoFile, signatureFile } = payload;
    let companyName = data.companyName.trim().replace(" ", "_");
    let responseLogoFileUpload = null;
    let responseSignatureFileUpload = null;

    if (logoFile.file) {
      const logoFormData = new FormData();
      logoFormData.append("files", logoFile.file);
      responseLogoFileUpload = yield call(
        uploadFile,
        companyName,
        "profile",
        "",
        "",
        logoFormData
      );
    }

    if (signatureFile.file) {
      const signatureFormData = new FormData();
      signatureFormData.append("files", signatureFile.file);
      responseSignatureFileUpload = yield call(
        uploadFile,
        companyName,
        "profile",
        "",
        "",
        signatureFormData
      );
    }

    //conditionlly set data url of logofile or signaturefile
    if (responseLogoFileUpload !== null) {
      // data.logo = responseLogoFileUpload.data.id;
      data.logo = responseLogoFileUpload.id;
    }

    if (responseSignatureFileUpload !== null) {
      // data.signature = responseSignatureFileUpload.data.id;
      data.signature = responseSignatureFileUpload.id;
    }
    const response = yield call(addProfileDetailsAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PROFILE_DETAIL_SUCCESS,
        // payload: { profile: response.data },
      });
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PROFILE_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROFILE_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}
function* addProfileBankDetails({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(addProfileBankDetailsAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PROFILE_BANK_DETAIL_SUCCESS,
        payload: { bankDetails: response.data?.bankDetails },
      });
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PROFILE_BANK_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PROFILE_BANK_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchProfiles),
    fork(watchFetchProfileByRole),
    fork(watchAddProfileDetails),
    fork(watchAddProfileBankDetails),
  ]);
}
