import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchGSTMasterListAsync = async (id) =>
  await api
    .get(`/gst-master`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchGSTMasterListByRoleAsync = async (id) =>
  await api
    .get(`/gst-master/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const createGSTMasterAsync = async (payload) =>
  await api
    .post(`/gst-master`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateGSTMasterAsync = async (payload, id) =>
  await api
    .put(`/gst-master/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchGSTMasterDetailAsync = async (id) =>
  await api
    .get(`/gst-master/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteGSTMasterAsync = async (id) =>
  await api
    .delete(`/gst-master/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
