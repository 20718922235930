import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  ADD_NEW_PRODUCT,
  ADD_NEW_PRODUCT_FAILURE,
  ADD_NEW_PRODUCT_SUCCESS,
  PRODUCT_DELETE,
  PRODUCT_DELETE_FAILURE,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_FAILURE,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_LIST,
  PRODUCT_LIST_BY_ROLE,
  PRODUCT_LIST_BY_ROLE_FAILURE,
  PRODUCT_LIST_BY_ROLE_SUCCESS,
  PRODUCT_LIST_FAILURE,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_NEW,
  PRODUCT_NEW_FAILURE,
  PRODUCT_NEW_SUCCESS,
  PRODUCT_STATUS_UPDATE,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_FAILURE,
  PRODUCT_UPDATE_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createProductAsync,
  deleteProductAsync,
  fetchProductAsync,
  fetchProductByRoleAsync,
  fetchProductDetailAsync,
  updateProductAsync,
  updateProductStatusAsync,
} from "../../../services/inventory/product.service.js";
import { uploadFile } from "../../common/saga.js";

export function* watchFetchProduct() {
  yield takeLatest(PRODUCT_LIST, fetchProductList);
}

export function* watchFetchProductByRole() {
  yield takeLatest(PRODUCT_LIST_BY_ROLE, fetchProductListByRole);
}

export function* watchCreateProduct() {
  yield takeLatest(PRODUCT_NEW, createNewProduct);
}

export function* watchAddNewProduct() {
  yield takeLatest(ADD_NEW_PRODUCT, addNewProduct);
}

export function* watchUpdateProduct() {
  yield takeLatest(PRODUCT_UPDATE, updateProduct);
}

export function* watchUpdateProductStatus() {
  yield takeLatest(PRODUCT_STATUS_UPDATE, updateProductStatus);
}

export function* watchGetDetailProduct() {
  yield takeLatest(PRODUCT_DETAIL, fetchProductDetail);
}

export function* watchDeleteProduct() {
  yield takeLatest(PRODUCT_DELETE, deleteProduct);
}

function* fetchProductList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchProductAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PRODUCT_LIST_SUCCESS,
        payload: { products: response.data },
      });
    } else {
      yield put({ type: PRODUCT_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PRODUCT_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchProductListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchProductByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PRODUCT_LIST_BY_ROLE_SUCCESS,
        payload: { productsByRole: response.data },
      });
    } else {
      yield put({ type: PRODUCT_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PRODUCT_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewProduct({ payload }) {
  const { data, image1File, image2File, image3File, image4File } = payload;
  let image1FileUpload = null;
  let image2FileUpload = null;
  let image3FileUpload = null;
  let image4FileUpload = null;

  if (image1File.file) {
    let productName = data.productName.trim().replace(" ", "_image1");
    const logoFormData = new FormData();
    logoFormData.append("files", image1File.file);
    image1FileUpload = yield call(
      uploadFile,
      productName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (image2File.file) {
    let productName = data.productName.trim().replace(" ", "_image2");
    const logoFormData = new FormData();
    logoFormData.append("files", image2File.file);
    image2FileUpload = yield call(
      uploadFile,
      productName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (image3File.file) {
    let productName = data.productName.trim().replace(" ", "_image3");
    const logoFormData = new FormData();
    logoFormData.append("files", image3File.file);
    image3FileUpload = yield call(
      uploadFile,
      productName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (image4File.file) {
    let productName = data.productName.trim().replace(" ", "_image4");
    const logoFormData = new FormData();
    logoFormData.append("files", image4File.file);
    image4FileUpload = yield call(
      uploadFile,
      productName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (image1FileUpload !== null) {
    // data.logo = image1FileUpload.data.id;
    data.image1 = image1FileUpload.id;
  }
  if (image2FileUpload !== null) {
    // data.logo = image2FileUpload.data.id;
    data.image2 = image2FileUpload.id;
  }

  if (image3FileUpload !== null) {
    // data.logo = image3FileUpload.data.id;
    data.image3 = image3FileUpload.id;
  }

  if (image4FileUpload !== null) {
    // data.logo = image4FileUpload.data.id;
    data.image4 = image4FileUpload.id;
  }

  try {
    const response = yield call(createProductAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: PRODUCT_NEW_SUCCESS,
        payload: {data: response.data},
      });
    } else {
      yield put({ type: PRODUCT_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PRODUCT_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* addNewProduct({ payload }) {
  const { data, image1File, image2File, image3File, image4File } = payload;
  let image1FileUpload = null;
  let image2FileUpload = null;
  let image3FileUpload = null;
  let image4FileUpload = null;

  if (image1File.file) {
    let productName = data.productName.trim().replace(" ", "_image1");
    const logoFormData = new FormData();
    logoFormData.append("files", image1File.file);
    image1FileUpload = yield call(
      uploadFile,
      productName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (image2File.file) {
    let productName = data.productName.trim().replace(" ", "_image2");
    const logoFormData = new FormData();
    logoFormData.append("files", image2File.file);
    image2FileUpload = yield call(
      uploadFile,
      productName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (image3File.file) {
    let productName = data.productName.trim().replace(" ", "_image3");
    const logoFormData = new FormData();
    logoFormData.append("files", image3File.file);
    image3FileUpload = yield call(
      uploadFile,
      productName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (image4File.file) {
    let productName = data.productName.trim().replace(" ", "_image4");
    const logoFormData = new FormData();
    logoFormData.append("files", image4File.file);
    image4FileUpload = yield call(
      uploadFile,
      productName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (image1FileUpload !== null) {
    // data.logo = image1FileUpload.data.id;
    data.image1 = image1FileUpload.id;
  }
  
  if (image2FileUpload !== null) {
    // data.logo = image2FileUpload.data.id;
    data.image2 = image2FileUpload.id;
  }

  if (image3FileUpload !== null) {
    // data.logo = image3FileUpload.data.id;
    data.image3 = image3FileUpload.id;
  }

  if (image4FileUpload !== null) {
    // data.logo = image4FileUpload.data.id;
    data.image4 = image4FileUpload.id;
  }

  try {
    const response = yield call(createProductAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: ADD_NEW_PRODUCT_SUCCESS,
        payload: {data: response.data},
      });
    } else {
      yield put({ type: ADD_NEW_PRODUCT_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADD_NEW_PRODUCT_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateProduct({ payload }) {
  const { data, id, image1File, image2File, image3File, image4File } = payload;
  let image1FileUpload = null;
  let image2FileUpload = null;
  let image3FileUpload = null;
  let image4FileUpload = null;

  if (image1File && image1File.file) {
    let productName = data.productName.trim().replace(" ", "_image1");
    const image1FormData = new FormData();
    image1FormData.append("files", image1File.file);
    image1FileUpload = yield call(
      uploadFile,
      productName,
      "image1",
      "",
      "",
      image1FormData
    );
  }

  if (image2File && image2File.file) {
    let productName = data.productName.trim().replace(" ", "_image2");
    const image2FormData = new FormData();
    image2FormData.append("files", image2File.file);
    image2FileUpload = yield call(
      uploadFile,
      productName,
      "image2",
      "",
      "",
      image2FormData
    );
  }

  if (image3File && image3File.file) {
    let productName = data.productName.trim().replace(" ", "_image3");
    const image3FormData = new FormData();
    image3FormData.append("files", image3File.file);
    image3FileUpload = yield call(
      uploadFile,
      productName,
      "image3",
      "",
      "",
      image3FormData
    );
  }

  if (image4File && image4File.file) {
    let productName = data.productName.trim().replace(" ", "_image4");
    const image4FormData = new FormData();
    image4FormData.append("files", image4File.file);
    image4FileUpload = yield call(
      uploadFile,
      productName,
      "image4",
      "",
      "",
      image4FormData
    );
  }

  if (image1FileUpload !== null) {
    // data.logo = image1FileUpload.data.id;
    data.image1 = image1FileUpload.id;
  }
  if (image2FileUpload !== null) {
    // data.logo = image2FileUpload.data.id;
    data.image2 = image2FileUpload.id;
  }

  if (image3FileUpload !== null) {
    // data.logo = image3FileUpload.data.id;
    data.image3 = image3FileUpload.id;
  }

  if (image4FileUpload !== null) {
    // data.logo = image4FileUpload.data.id;
    data.image4 = image4FileUpload.id;
  }
  
  try {
    const response = yield call(updateProductAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: PRODUCT_UPDATE_SUCCESS,
        payload: { product: response.data },
      });
    } else {
      yield put({ type: PRODUCT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PRODUCT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateProductStatus({ payload }) {
  const { data, id} = payload;
  
  try {
    const response = yield call(updateProductStatusAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: PRODUCT_UPDATE_SUCCESS,
        payload: { product: response.data },
      });
    } else {
      yield put({ type: PRODUCT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PRODUCT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchProductDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchProductDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PRODUCT_DETAIL_SUCCESS,
        payload: { product: response.data },
      });
    } else {
      yield put({ type: PRODUCT_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PRODUCT_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteProduct({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteProductAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PRODUCT_DELETE_SUCCESS,
        payload: { id: id },
      });      
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PRODUCT_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PRODUCT_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchProduct),
    fork(watchFetchProductByRole),
    fork(watchCreateProduct),
    fork(watchAddNewProduct),
    fork(watchUpdateProduct),
    fork(watchUpdateProductStatus),
    fork(watchGetDetailProduct),
    fork(watchDeleteProduct),
  ]);
}
