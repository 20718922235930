import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  RECRUIT_DELETE,
  RECRUIT_DELETE_FAILURE,
  RECRUIT_DELETE_SUCCESS,
  RECRUIT_DETAIL,
  RECRUIT_DETAIL_FAILURE,
  RECRUIT_DETAIL_SUCCESS,
  RECRUIT_LIST,
  RECRUIT_LIST_BY_ROLE,
  RECRUIT_LIST_BY_ROLE_FAILURE,
  RECRUIT_LIST_BY_ROLE_SUCCESS,
  RECRUIT_LIST_FAILURE,
  RECRUIT_LIST_SUCCESS,
  RECRUIT_NEW,
  RECRUIT_NEW_FAILURE,
  RECRUIT_NEW_SUCCESS,
  RECRUIT_UPDATE,
  RECRUIT_UPDATE_FAILURE,
  RECRUIT_UPDATE_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action";
import {
  createRecruitAsync,
  deleteRecruitAsync,
  fetchRecruitAsync,
  fetchRecruitByRoleAsync,
  fetchRecruitDetailAsync,
  updateRecruitAsync,
} from "../../services/recruit.service";

export function* watchFetchRecruit() {
  yield takeLatest(RECRUIT_LIST, fetchRecruit);
}

export function* watchFetchRecruitByRole() {
  yield takeLatest(RECRUIT_LIST_BY_ROLE, fetchRecruitByRole);
}

export function* watchCreateRecruit() {
  yield takeLatest(RECRUIT_NEW, createNewRecruit);
}

export function* watchUpdateRecruit() {
  yield takeLatest(RECRUIT_UPDATE, updateRecruit);
}

export function* watchGetDetailRecruit() {
  yield takeLatest(RECRUIT_DETAIL, fetchRecruitDetail);
}

export function* watchDeleteRecruit() {
  yield takeLatest(RECRUIT_DELETE, deleteRecruit);
}

function* fetchRecruit({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchRecruitAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: RECRUIT_LIST_SUCCESS,
        payload: { recruits: response.data },
      });
    } else {
      yield put({ type: RECRUIT_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: RECRUIT_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchRecruitByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchRecruitByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: RECRUIT_LIST_BY_ROLE_SUCCESS,
        payload: { recruitsByRole: response.data },
      });
    } else {
      yield put({ type: RECRUIT_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: RECRUIT_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}


function* createNewRecruit({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createRecruitAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: RECRUIT_NEW_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: RECRUIT_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: RECRUIT_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateRecruit({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateRecruitAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: RECRUIT_UPDATE_SUCCESS,
        payload: { recruit: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: RECRUIT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: RECRUIT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchRecruitDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchRecruitDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: RECRUIT_DETAIL_SUCCESS,
        payload: { recruit: response.data },
      });
    } else {
      yield put({ type: RECRUIT_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: RECRUIT_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteRecruit({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteRecruitAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: RECRUIT_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: RECRUIT_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: RECRUIT_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchRecruit),
    fork(watchFetchRecruitByRole),
    fork(watchCreateRecruit),
    fork(watchUpdateRecruit),
    fork(watchGetDetailRecruit),
    fork(watchDeleteRecruit),
  ]);
}
