import {
  LOGOUT,
  REFRESH_TOKEN,
  LOGIN_USER,
  RESET_PASSWORD,
  VERIFY_USER,
  FORGOT_PASSWORD,
  REGISTER_USER,
  LOGIN_COUNT_RESET,
  RESET_PERMISSION,
  FETCH_USER_PROFILE,
} from "../types";

export const loginUserRequest = (values) => ({
  type: LOGIN_USER,
  payload: { data: values },
});

export const fetchUserProfile = (uid) => ({
  type: FETCH_USER_PROFILE,
  // payload: { uid: uid },
  payload: { },
});

export const resetLoginCount = () => ({
  type: LOGIN_COUNT_RESET,
});

export const forgotPasswordRequest = (email) => ({
  type: FORGOT_PASSWORD,
  payload: { email : email },
});

export const resetPasswordRequest = (email, newPassword) => ({
  type: RESET_PASSWORD,
  payload: { email: email, password: newPassword },
});

export const verifyUserRequest = (token) => ({
  type: VERIFY_USER,
  payload: { token: token },
});

export const registerRequest = (user) => ({
  type: REGISTER_USER,
  payload: { user: user },
});

export const logoutUser = () => ({
  type: LOGOUT,
  payload: {},
});

export const refreshToken = (accessToken, refreshToken, decoded) => ({
  type: REFRESH_TOKEN,
  payload: {
    accessToken: accessToken,
    refreshToken: refreshToken,
    decoded: decoded,
  },
});

export const resetPermissions = () => ({
  type: RESET_PERMISSION,
  payload: {},
});
