import {
  PROFILE_BANK_DETAIL,
  PROFILE_BANK_DETAIL_SUCCESS,
  PROFILE_DETAIL,
  PROFILE_DETAIL_SUCCESS,
  PROFILE_GET,
  PROFILE_GET_BY_ROLE_SUCCESS,
  PROFILE_GET_SUCCESS,
} from "../types";

const initialState = {
  profile: null,
  profileByRole: null,
  changePassword: false,
  createOrUpdate: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PROFILE_GET:
    case PROFILE_DETAIL:
    case PROFILE_BANK_DETAIL:
      return { ...state, changePassword: false, createOrUpdate: false };

    case PROFILE_DETAIL_SUCCESS: {
      return { ...state, createOrUpdate: true };
    }

    case PROFILE_BANK_DETAIL_SUCCESS:{
      return { ...state, createOrUpdate: true };
    }

    case PROFILE_GET_SUCCESS: {
      //console.log("DATA", payload.data);
      return { ...state, profile: payload.data,bankDetails:payload.data.bankDetails, createOrUpdate: false };
    }
    case PROFILE_GET_BY_ROLE_SUCCESS: {
      //console.log("DATA", payload.data);
      return { ...state, profileByRole: payload.data,bankDetails:payload.data.bankDetails, createOrUpdate: false };
    }
    default:
      return { ...state, createOrUpdate: false };
  }
}
