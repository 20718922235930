import api from "../api";

export const fetchPurchaseReturnAsync = async (uid) => await api.get(`/purchaseReturn`).then((res) => res.data).catch((err) => err);

export const fetchPurchaseReturnByRoleAsync = async (uid) => await api.get(`/purchaseReturn/assign`).then((res) => res.data).catch((err) => err);

export const createPurchaseReturnAsync = async (payload) => await api.post(`/purchaseReturn`, payload).then((res) => res.data).catch((err) => err);

export const updatePurchaseReturnAsync = async (payload, id) => await api.put(`/purchaseReturn/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updatePurchaseReturnStatusAsync = async (payload, id) => await api.put(`/purchaseReturn/status/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updatePurchaseReturnPaymentInfoAsync = async (payload, id) =>
  await api
    .put(`/purchaseReturn/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchPurchaseReturnDetailAsync = async (id) => await api.get(`/purchaseReturn/${id}`).then((res) => res.data).catch((err) => err);

export const deletePurchaseReturnAsync = async (id) => await api.delete(`/purchaseReturn/${id}`).then((res) => res.data).catch((err) => err);