export const SET_LOADER = "SET_LOADER";
export const SET_MESSAGE = "SET_MESSAGE";

export const LOGIN_USER = "LOGIN_USER";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
export const LOGIN_COUNT_RESET = "LOGIN_COUNT_RESET";
export const RESET_PERMISSION = "RESET_PERMISSION";

export const FETCH_USER_PROFILE = "FETCH_USER_PROFILE";
export const FETCH_USER_PROFILE_SUCCESS = "FETCH_USER_PROFILE_SUCCESS";
export const FETCH_USER_PROFILE_FAILURE = "FETCH_USER_PROFILE_FAILURE";

export const FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
export const FORGOT_PASSWORD_FAILURE = "FORGOT_PASSWORD_FAILURE";

export const VERIFY_USER_FOR_FOGET_PASS = "VERIFY_USER_FOR_FOGET_PASS";
export const VERIFY_USER_FOR_FOGET_PASS_SUCCESS =
  "VERIFY_USER_FOR_FOGET_PASS_SUCCESS";
export const VERIFY_USER_FOR_FOGET_PASS_FAILURE =
  "VERIFY_USER_FOR_FOGET_PASS_FAILURE";

export const RESET_PASSWORD = "RESET_PASSWORD";
export const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
export const RESET_PASSWORD_FAILURE = "RESET_PASSWORD_FAILURE";

export const MASTER_GET_ALL = "MASTER_GET_ALL";
export const MASTER_GET_ALL_SUCCESS = "MASTER_GET_ALL_SUCCESS";
export const MASTER_GET_ALL_FAILURE = "MASTER_GET_ALL_FAILURE";

export const MASTER_LIST = "MASTER_LIST";
export const MASTER_LIST_SUCCESS = "MASTER_LIST_SUCCESS";
export const MASTER_LIST_FAILURE = "MASTER_LIST_FAILURE";

export const MASTER_LIST_BY_ROLE = "MASTER_LIST_BY_ROLE";
export const MASTER_LIST_BY_ROLE_SUCCESS = "MASTER_LIST_BY_ROLE_SUCCESS";
export const MASTER_LIST_BY_ROLE_FAILURE = "MASTER_LIST_BY_ROLE_FAILURE";

export const CREATE_MASTER = "CREATE_MASTER";
export const CREATE_MASTER_SUCCESS = "CREATE_MASTER_SUCCESS";
export const CREATE_MASTER_FAILURE = "CREATE_MASTER_FAILURE";

export const ADD_NEW_MASTER = "ADD_NEW_MASTER";
export const ADD_NEW_MASTER_SUCCESS = "ADD_NEW_MASTER_SUCCESS";
export const ADD_NEW_MASTER_FAILURE = "ADD_NEW_MASTER_FAILURE";

export const UPDATE_MASTER = "UPDATE_MASTER";
export const UPDATE_MASTER_SUCCESS = "UPDATE_MASTER_SUCCESS";
export const UPDATE_MASTER_FAILURE = "UPDATE_MASTER_FAILURE";

export const UPDATE_MASTER_STATUS = "UPDATE_MASTER_STATUS";
export const UPDATE_MASTER_STATUS_SUCCESS = "UPDATE_MASTER_STATUS_SUCCESS";
export const UPDATE_MASTER_STATUS_FAILURE = "UPDATE_MASTER_STATUS_FAILURE";

// Resources for Admin module
export const ADMIN_NEW = "ADMIN_NEW";
export const ADMIN_NEW_SUCCESS = "ADMIN_NEW_SUCCESS";
export const ADMIN_NEW_FAILURE = "ADMIN_NEW_FAILURE";

export const ADMIN_UPDATE = "ADMIN_UPDATE";
export const ADMIN_UPDATE_SUCCESS = "ADMIN_UPDATE_SUCCESS";
export const ADMIN_UPDATE_FAILURE = "ADMIN_UPDATE_FAILURE";

export const ADMIN_STATUS_UPDATE = "ADMIN_STATUS_UPDATE";
export const ADMIN_STATUS_UPDATE_SUCCESS = "ADMIN_STATUS_UPDATE_SUCCESS";
export const ADMIN_STATUS_UPDATE_FAILURE = "ADMIN_STATUS_UPDATE_FAILURE";

export const ADMIN_LIST = "ADMIN_LIST";
export const ADMIN_LIST_SUCCESS = "ADMIN_LIST_SUCCESS";
export const ADMIN_LIST_FAILURE = "ADMIN_LIST_FAILURE";

export const ADMIN_LIST_BY_ROLE = "ADMIN_LIST_BY_ROLE";
export const ADMIN_LIST_BY_ROLE_SUCCESS = "ADMIN_LIST_BY_ROLE_SUCCESS";
export const ADMIN_LIST_BY_ROLE_FAILURE = "ADMIN_LIST_BY_ROLE_FAILURE";

export const ADMIN_DETAIL = "ADMIN_DETAIL";
export const ADMIN_DETAIL_SUCCESS = "ADMIN_DETAIL_SUCCESS";
export const ADMIN_DETAIL_FAILURE = "ADMIN_DETAIL_FAILURE";

export const ADMIN_DELETE = "ADMIN_DELETE";
export const ADMIN_DELETE_SUCCESS = "ADMIN_DELETE_SUCCESS";
export const ADMIN_DELETE_FAILURE = "ADMIN_DELETE_FAILURE";

// Resources for Quotation module
export const GENERATE_QUOTATION_ID = "GENERATE_QUOTATION_ID";
export const GENERATE_QUOTATION_ID_SUCCESS = "GENERATE_QUOTATION_ID_SUCCESS";
export const GENERATE_QUOTATION_ID_FAILURE = "GENERATE_QUOTATION_ID_FAILURE";

export const QUOTATION_PDF = "QUOTATION_PDF";
export const QUOTATION_PDF_SUCCESS = "QUOTATION_PDF_SUCCESS";
export const QUOTATION_PDF_FAILURE = "QUOTATION_PDF_FAILURE";

export const QUOTATION_NEW = "QUOTATION_NEW";
export const QUOTATION_NEW_SUCCESS = "QUOTATION_NEW_SUCCESS";
export const QUOTATION_NEW_FAILURE = "QUOTATION_NEW_FAILURE";

export const QUOTATION_UPDATE = "QUOTATION_UPDATE";
export const QUOTATION_UPDATE_SUCCESS = "QUOTATION_UPDATE_SUCCESS";
export const QUOTATION_UPDATE_FAILURE = "QUOTATION_UPDATE_FAILURE";

export const QUOTATION_LIST = "QUOTATION_LIST";
export const QUOTATION_LIST_SUCCESS = "QUOTATION_LIST_SUCCESS";
export const QUOTATION_LIST_FAILURE = "QUOTATION_LIST_FAILURE";

export const QUOTATION_LIST_BY_ROLE = "QUOTATION_LIST_BY_ROLE";
export const QUOTATION_LIST_BY_ROLE_SUCCESS = "QUOTATION_LIST_BY_ROLE_SUCCESS";
export const QUOTATION_LIST_BY_ROLE_FAILURE = "QUOTATION_LIST_BY_ROLE_FAILURE";

export const QUOTATION_DETAIL = "QUOTATION_DETAIL";
export const QUOTATION_DETAIL_SUCCESS = "QUOTATION_DETAIL_SUCCESS";
export const QUOTATION_DETAIL_FAILURE = "QUOTATION_DETAIL_FAILURE";

export const QUOTATION_DELETE = "QUOTATION_DELETE";
export const QUOTATION_DELETE_SUCCESS = "QUOTATION_DELETE_SUCCESS";
export const QUOTATION_DELETE_FAILURE = "QUOTATION_DELETE_FAILURE";

// Resources for Invoice module
export const GENERATE_INVOICE_ID = "GENERATE_INVOICE_ID";
export const GENERATE_INVOICE_ID_SUCCESS = "GENERATE_INVOICE_ID_SUCCESS";
export const GENERATE_INVOICE_ID_FAILURE = "GENERATE_INVOICE_ID_FAILURE";

export const INVOICE_NEW = "INVOICE_NEW";
export const INVOICE_NEW_SUCCESS = "INVOICE_NEW_SUCCESS";
export const INVOICE_NEW_FAILURE = "INVOICE_NEW_FAILURE";

export const INVOICE_PDF = "INVOICE_PDF";
export const INVOICE_PDF_SUCCESS = "INVOICE_PDF_SUCCESS";
export const INVOICE_PDF_FAILURE = "INVOICE_PDF_FAILURE";

export const INVOICE_UPDATE = "INVOICE_UPDATE";
export const INVOICE_UPDATE_SUCCESS = "INVOICE_UPDATE_SUCCESS";
export const INVOICE_UPDATE_FAILURE = "INVOICE_UPDATE_FAILURE";

export const INVOICE_PAYMENT_UPDATE = "INVOICE_PAYMENT_UPDATE";
export const INVOICE_PAYMENT_UPDATE_SUCCESS = "INVOICE_PAYMENT_UPDATE_SUCCESS";
export const INVOICE_PAYMENT_UPDATE_FAILURE = "INVOICE_PAYMENT_UPDATE_FAILURE";

export const INVOICE_LIST = "INVOICE_LIST";
export const INVOICE_LIST_SUCCESS = "INVOICE_LIST_SUCCESS";
export const INVOICE_LIST_FAILURE = "INVOICE_LIST_FAILURE";

export const INVOICE_LIST_BY_ROLE = "INVOICE_LIST_BY_ROLE";
export const INVOICE_LIST_BY_ROLE_SUCCESS = "INVOICE_LIST_BY_ROLE_SUCCESS";
export const INVOICE_LIST_BY_ROLE_FAILURE = "INVOICE_LIST_BY_ROLE_FAILURE";

export const INVOICE_FOR_REPORT_LIST = "INVOICE_FOR_REPORT_LIST";
export const INVOICE_FOR_REPORT_LIST_SUCCESS =
  "INVOICE_FOR_REPORT_LIST_SUCCESS";
export const INVOICE_FOR_REPORT_LIST_FAILURE =
  "INVOICE_FOR_REPORT_LIST_FAILURE";

export const INVOICE_DETAIL = "INVOICE_DETAIL";
export const INVOICE_DETAIL_SUCCESS = "INVOICE_DETAIL_SUCCESS";
export const INVOICE_DETAIL_FAILURE = "INVOICE_DETAIL_FAILURE";

export const INVOICE_DELETE = "INVOICE_DELETE";
export const INVOICE_DELETE_SUCCESS = "INVOICE_DELETE_SUCCESS";
export const INVOICE_DELETE_FAILURE = "INVOICE_DELETE_FAILURE";

export const INVOICE_CANCEL = "INVOICE_CANCEL";
export const INVOICE_CANCEL_SUCCESS = "INVOICE_CANCEL_SUCCESS";
export const INVOICE_CANCEL_FAILURE = "INVOICE_CANCEL_FAILURE";

export const INVOICE_RESTORE = "INVOICE_RESTORE";
export const INVOICE_RESTORE_SUCCESS = "INVOICE_RESTORE_SUCCESS";
export const INVOICE_RESTORE_FAILURE = "INVOICE_RESTORE_FAILURE";

/** Employ Onboarding module related types */
export const EMPLOY_ONBOARDING_LIST = "EMPLOY_ONBOARDING_LIST";
export const EMPLOY_ONBOARDING_LIST_SUCCESS = "EMPLOY_ONBOARDING_LIST_SUCCESS";
export const EMPLOY_ONBOARDING_LIST_FAILURE = "EMPLOY_ONBOARDING_LIST_FAILURE";

export const EMPLOY_ONBOARDING_LIST_BY_ROLE = "EMPLOY_ONBOARDING_LIST_BY_ROLE";
export const EMPLOY_ONBOARDING_LIST_BY_ROLE_SUCCESS =
  "EMPLOY_ONBOARDING_LIST_BY_ROLE_SUCCESS";
export const EMPLOY_ONBOARDING_LIST_BY_ROLE_FAILURE =
  "EMPLOY_ONBOARDING_LIST_BY_ROLE_FAILURE";

export const EMPLOY_ONBOARDING_NEW = "EMPLOY_ONBOARDING_NEW";
export const EMPLOY_ONBOARDING_NEW_SUCCESS = "EMPLOY_ONBOARDING_NEW_SUCCESS";
export const EMPLOY_ONBOARDING_NEW_FAILURE = "EMPLOY_ONBOARDING_NEW_FAILURE";

export const EMPLOY_ONBOARDING_UPDATE = "EMPLOY_ONBOARDING_UPDATE";
export const EMPLOY_ONBOARDING_UPDATE_SUCCESS =
  "EMPLOY_ONBOARDING_UPDATE_SUCCESS";
export const EMPLOY_ONBOARDING_UPDATE_FAILURE =
  "EMPLOY_ONBOARDING_UPDATE_FAILURE";

export const EMPLOY_ONBOARDING_DETAIL = "EMPLOY_ONBOARDING_DETAIL";
export const EMPLOY_ONBOARDING_DETAIL_SUCCESS =
  "EMPLOY_ONBOARDING_DETAIL_SUCCESS";
export const EMPLOY_ONBOARDING_DETAIL_FAILURE =
  "EMPLOY_ONBOARDING_DETAIL_FAILURE";

export const EMPLOY_ONBOARDING_DELETE = "EMPLOY_ONBOARDING_DELETE";
export const EMPLOY_ONBOARDING_DELETE_SUCCESS =
  "EMPLOY_ONBOARDING_DELETE_SUCCESS";
export const EMPLOY_ONBOARDING_DELETE_FAILURE =
  "EMPLOY_ONBOARDING_DELETE_FAILURE";

export const GENERATE_EMPLOY_ONBOARDING_ID = "GENERATE_EMPLOY_ONBOARDING_ID";
export const GENERATE_EMPLOY_ONBOARDING_ID_SUCCESS =
  "GENERATE_EMPLOY_ONBOARDING_ID_SUCCESS";
export const GENERATE_EMPLOY_ONBOARDING_ID_FAILURE =
  "GENERATE_EMPLOY_ONBOARDING_ID_FAILURE";

// Resources for Employee attendance module

export const EMPLOYEE_ATTENDANCE_NEW = "EMPLOYEE_ATTENDANCE_NEW";
export const EMPLOYEE_ATTENDANCE_NEW_SUCCESS =
  "EMPLOYEE_ATTENDANCE_NEW_SUCCESS";
export const EMPLOYEE_ATTENDANCE_NEW_FAILURE =
  "EMPLOYEE_ATTENDANCE_NEW_FAILURE";

export const EMPLOYEE_ATTENDANCE_UPDATE = "EMPLOYEE_ATTENDANCE_UPDATE";
export const EMPLOYEE_ATTENDANCE_UPDATE_SUCCESS =
  "EMPLOYEE_ATTENDANCE_UPDATE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_UPDATE_FAILURE =
  "EMPLOYEE_ATTENDANCE_UPDATE_FAILURE";

export const EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE =
  "EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE";
export const EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE_SUCCESS =
  "EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE_FAILURE =
  "EMPLOYEE_ATTENDANCE_PAYMENT_UPDATE_FAILURE";

export const EMPLOYEE_ATTENDANCE_LIST = "EMPLOYEE_ATTENDANCE_LIST";
export const EMPLOYEE_ATTENDANCE_LIST_SUCCESS =
  "EMPLOYEE_ATTENDANCE_LIST_SUCCESS";
export const EMPLOYEE_ATTENDANCE_LIST_FAILURE =
  "EMPLOYEE_ATTENDANCE_LIST_FAILURE";

export const EMPLOYEE_ATTENDANCE_LIST_BY_ROLE =
  "EMPLOYEE_ATTENDANCE_LIST_BY_ROLE";
export const EMPLOYEE_ATTENDANCE_LIST_BY_ROLE_SUCCESS =
  "EMPLOYEE_ATTENDANCE_LIST_BY_ROLE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_LIST_BY_ROLE_FAILURE =
  "EMPLOYEE_ATTENDANCE_LIST_BY_ROLE_FAILURE";

export const EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST =
  "EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST";
export const EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST_SUCCESS =
  "EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST_SUCCESS";
export const EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST_FAILURE =
  "EMPLOYEE_ATTENDANCE_FOR_REPORT_LIST_FAILURE";

export const EMPLOYEE_ATTENDANCE_DETAIL = "EMPLOYEE_ATTENDANCE_DETAIL";
export const EMPLOYEE_ATTENDANCE_DETAIL_SUCCESS =
  "EMPLOYEE_ATTENDANCE_DETAIL_SUCCESS";
export const EMPLOYEE_ATTENDANCE_DETAIL_FAILURE =
  "EMPLOYEE_ATTENDANCE_DETAIL_FAILURE";

export const EMPLOYEE_ATTENDANCE_DELETE = "EMPLOYEE_ATTENDANCE_DELETE";
export const EMPLOYEE_ATTENDANCE_DELETE_SUCCESS =
  "EMPLOYEE_ATTENDANCE_DELETE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_DELETE_FAILURE =
  "EMPLOYEE_ATTENDANCE_DELETE_FAILURE";

export const EMPLOYEE_ATTENDANCE_RESTORE = "EMPLOYEE_ATTENDANCE_RESTORE";
export const EMPLOYEE_ATTENDANCE_RESTORE_SUCCESS =
  "EMPLOYEE_ATTENDANCE_RESTORE_SUCCESS";
export const EMPLOYEE_ATTENDANCE_RESTORE_FAILURE =
  "EMPLOYEE_ATTENDANCE_RESTORE_FAILURE";

export const ATTENDANCE_REPORT = "ATTENDANCE_REPORT";
export const ATTENDANCE_REPORT_SUCCESS = "ATTENDANCE_REPORT_SUCCESS";
export const ATTENDANCE_REPORT_FAILURE = "ATTENDANCE_REPORT_FAILURE";

export const GENERATE_SALARY_SLIP_NO = "GENERATE_SALARY_SLIP_NO";
export const GENERATE_SALARY_SLIP_NO_SUCCESS =
  "GENERATE_SALARY_SLIP_NO_SUCCESS";
export const GENERATE_SALARY_SLIP_NO_FAILURE =
  "GENERATE_SALARY_SLIP_NO_FAILURE";

// Resources for Project module
export const GENERATE_PROJECT_ID = "GENERATE_PROJECT_ID";
export const GENERATE_PROJECT_ID_SUCCESS = "GENERATE_PROJECT_ID_SUCCESS";
export const GENERATE_PROJECT_ID_FAILURE = "GENERATE_PROJECT_ID_FAILURE";

export const PROJECT_NEW = "PROJECT_NEW";
export const PROJECT_NEW_SUCCESS = "PROJECT_NEW_SUCCESS";
export const PROJECT_NEW_FAILURE = "PROJECT_NEW_FAILURE";

export const ADD_NEW_PROJECT = "ADD_NEW_PROJECT";
export const ADD_NEW_PROJECT_SUCCESS = "ADD_NEW_PROJECT_SUCCESS";
export const ADD_NEW_PROJECT_FAILURE = "ADD_NEW_PROJECT_FAILURE";

export const PROJECT_UPDATE = "PROJECT_UPDATE";
export const PROJECT_UPDATE_SUCCESS = "PROJECT_UPDATE_SUCCESS";
export const PROJECT_UPDATE_FAILURE = "PROJECT_UPDATE_FAILURE";

export const PROJECT_PAYMENT_UPDATE = "PROJECT_PAYMENT_UPDATE";
export const PROJECT_PAYMENT_UPDATE_SUCCESS = "PROJECT_PAYMENT_UPDATE_SUCCESS";
export const PROJECT_PAYMENT_UPDATE_FAILURE = "PROJECT_PAYMENT_UPDATE_FAILURE";

export const PROJECT_LIST = "PROJECT_LIST";
export const PROJECT_LIST_SUCCESS = "PROJECT_LIST_SUCCESS";
export const PROJECT_LIST_FAILURE = "PROJECT_LIST_FAILURE";

export const PROJECT_LIST_BY_ROLE = "PROJECT_LIST_BY_ROLE";
export const PROJECT_LIST_BY_ROLE_SUCCESS = "PROJECT_LIST_BY_ROLE_SUCCESS";
export const PROJECT_LIST_BY_ROLE_FAILURE = "PROJECT_LIST_BY_ROLE_FAILURE";

export const PROJECT_DETAIL = "PROJECT_DETAIL";
export const PROJECT_DETAIL_SUCCESS = "PROJECT_DETAIL_SUCCESS";
export const PROJECT_DETAIL_FAILURE = "PROJECT_DETAIL_FAILURE";

export const PROJECT_DELETE = "PROJECT_DELETE";
export const PROJECT_DELETE_SUCCESS = "PROJECT_DELETE_SUCCESS";
export const PROJECT_DELETE_FAILURE = "PROJECT_DELETE_FAILURE";

export const PROJECT_CANCEL = "PROJECT_CANCEL";
export const PROJECT_CANCEL_SUCCESS = "PROJECT_CANCEL_SUCCESS";
export const PROJECT_CANCEL_FAILURE = "PROJECT_CANCEL_FAILURE";

// Resources for Tickets module
export const GENERATE_TICKET_ID = "GENERATE_TICKET_ID";
export const GENERATE_TICKET_ID_SUCCESS = "GENERATE_TICKET_ID_SUCCESS";
export const GENERATE_TICKET_ID_FAILURE = "GENERATE_TICKET_ID_FAILURE";

export const TICKET_NEW = "TICKET_NEW";
export const TICKET_NEW_SUCCESS = "TICKET_NEW_SUCCESS";
export const TICKET_NEW_FAILURE = "TICKET_NEW_FAILURE";

export const TICKET_UPDATE = "TICKET_UPDATE";
export const TICKET_UPDATE_SUCCESS = "TICKET_UPDATE_SUCCESS";
export const TICKET_UPDATE_FAILURE = "TICKET_UPDATE_FAILURE";

export const TICKET_LIST = "TICKET_LIST";
export const TICKET_LIST_SUCCESS = "TICKET_LIST_SUCCESS";
export const TICKET_LIST_FAILURE = "TICKET_LIST_FAILURE";

export const TICKET_LIST_BY_ROLE = "TICKET_LIST_BY_ROLE";
export const TICKET_LIST_BY_ROLE_SUCCESS = "TICKET_LIST_BY_ROLE_SUCCESS";
export const TICKET_LIST_BY_ROLE_FAILURE = "TICKET_LIST_BY_ROLE_FAILURE";

export const TICKET_DETAIL = "TICKET_DETAIL";
export const TICKET_DETAIL_SUCCESS = "TICKET_DETAIL_SUCCESS";
export const TICKET_DETAIL_FAILURE = "TICKET_DETAIL_FAILURE";

export const TICKET_DELETE = "TICKET_DELETE";
export const TICKET_DELETE_SUCCESS = "TICKET_DELETE_SUCCESS";
export const TICKET_DELETE_FAILURE = "TICKET_DELETE_FAILURE";

// Resources for Recruit module
export const RECRUIT_NEW = "RECRUIT_NEW";
export const RECRUIT_NEW_SUCCESS = "RECRUIT_NEW_SUCCESS";
export const RECRUIT_NEW_FAILURE = "RECRUIT_NEW_FAILURE";

export const RECRUIT_UPDATE = "RECRUIT_UPDATE";
export const RECRUIT_UPDATE_SUCCESS = "RECRUIT_UPDATE_SUCCESS";
export const RECRUIT_UPDATE_FAILURE = "RECRUIT_UPDATE_FAILURE";

export const RECRUIT_LIST = "RECRUIT_LIST";
export const RECRUIT_LIST_SUCCESS = "RECRUIT_LIST_SUCCESS";
export const RECRUIT_LIST_FAILURE = "RECRUIT_LIST_FAILURE";

export const RECRUIT_LIST_BY_ROLE = "RECRUIT_LIST_BY_ROLE";
export const RECRUIT_LIST_BY_ROLE_SUCCESS = "RECRUIT_LIST_BY_ROLE_SUCCESS";
export const RECRUIT_LIST_BY_ROLE_FAILURE = "RECRUIT_LIST_BY_ROLE_FAILURE";

export const RECRUIT_DETAIL = "RECRUIT_DETAIL";
export const RECRUIT_DETAIL_SUCCESS = "RECRUIT_DETAIL_SUCCESS";
export const RECRUIT_DETAIL_FAILURE = "RECRUIT_DETAIL_FAILURE";

export const RECRUIT_DELETE = "RECRUIT_DELETE";
export const RECRUIT_DELETE_SUCCESS = "RECRUIT_DELETE_SUCCESS";
export const RECRUIT_DELETE_FAILURE = "RECRUIT_DELETE_FAILURE";

/** Customer module related types */
export const CUSTOMER_NEW = "CUSTOMER_NEW";
export const CUSTOMER_NEW_SUCCESS = "CUSTOMER_NEW_SUCCESS";
export const CUSTOMER_NEW_FAILURE = "CUSTOMER_NEW_FAILURE";

export const ADD_NEW_CUSTOMER = "ADD_NEW_CUSTOMER";
export const ADD_NEW_CUSTOMER_SUCCESS = "ADD_NEW_CUSTOMER_SUCCESS";
export const ADD_NEW_CUSTOMER_FAILURE = "ADD_NEW_CUSTOMER_FAILURE";

export const CUSTOMER_UPDATE = "CUSTOMER_UPDATE";
export const CUSTOMER_UPDATE_SUCCESS = "CUSTOMER_UPDATE_SUCCESS";
export const CUSTOMER_UPDATE_FAILURE = "CUSTOMER_UPDATE_FAILURE";

export const CUSTOMER_LIST = "CUSTOMER_LIST";
export const CUSTOMER_LIST_SUCCESS = "CUSTOMER_LIST_SUCCESS";
export const CUSTOMER_LIST_FAILURE = "CUSTOMER_LIST_FAILURE";

export const CUSTOMER_LIST_BY_ROLE = "CUSTOMER_LIST_BY_ROLE";
export const CUSTOMER_LIST_BY_ROLE_SUCCESS = "CUSTOMER_LIST_BY_ROLE_SUCCESS";
export const CUSTOMER_LIST_BY_ROLE_FAILURE = "CUSTOMER_LIST_BY_ROLE_FAILURE";

export const CUSTOMER_DETAIL = "CUSTOMER_DETAIL";
export const CUSTOMER_DETAIL_SUCCESS = "CUSTOMER_DETAIL_SUCCESS";
export const CUSTOMER_DETAIL_FAILURE = "CUSTOMER_DETAIL_FAILURE";

export const CUSTOMER_DELETE = "CUSTOMER_DELETE";
export const CUSTOMER_DELETE_SUCCESS = "CUSTOMER_DELETE_SUCCESS";
export const CUSTOMER_DELETE_FAILURE = "CUSTOMER_DELETE_FAILURE";

/** Supplier module related types */
export const SUPPLIER_NEW = "SUPPLIER_NEW";
export const SUPPLIER_NEW_SUCCESS = "SUPPLIER_NEW_SUCCESS";
export const SUPPLIER_NEW_FAILURE = "SUPPLIER_NEW_FAILURE";

export const ADD_NEW_SUPPLIER = "ADD_NEW_SUPPLIER";
export const ADD_NEW_SUPPLIER_SUCCESS = "ADD_NEW_SUPPLIER_SUCCESS";
export const ADD_NEW_SUPPLIER_FAILURE = "ADD_NEW_SUPPLIER_FAILURE";

export const SUPPLIER_UPDATE = "SUPPLIER_UPDATE";
export const SUPPLIER_UPDATE_SUCCESS = "SUPPLIER_UPDATE_SUCCESS";
export const SUPPLIER_UPDATE_FAILURE = "SUPPLIER_UPDATE_FAILURE";

export const SUPPLIER_LIST = "SUPPLIER_LIST";
export const SUPPLIER_LIST_SUCCESS = "SUPPLIER_LIST_SUCCESS";
export const SUPPLIER_LIST_FAILURE = "SUPPLIER_LIST_FAILURE";

export const SUPPLIER_LIST_BY_ROLE = "SUPPLIER_LIST_BY_ROLE";
export const SUPPLIER_LIST_BY_ROLE_SUCCESS = "SUPPLIER_LIST_BY_ROLE_SUCCESS";
export const SUPPLIER_LIST_BY_ROLE_FAILURE = "SUPPLIER_LIST_BY_ROLE_FAILURE";

export const SUPPLIER_DETAIL = "SUPPLIER_DETAIL";
export const SUPPLIER_DETAIL_SUCCESS = "SUPPLIER_DETAIL_SUCCESS";
export const SUPPLIER_DETAIL_FAILURE = "SUPPLIER_DETAIL_FAILURE";

export const SUPPLIER_DELETE = "SUPPLIER_DELETE";
export const SUPPLIER_DELETE_SUCCESS = "SUPPLIER_DELETE_SUCCESS";
export const SUPPLIER_DELETE_FAILURE = "SUPPLIER_DELETE_FAILURE";

/** Roles and rights module related types */
export const ROLES_NEW = "ROLES_NEW";
export const ROLES_NEW_SUCCESS = "ROLES_NEW_SUCCESS";
export const ROLES_NEW_FAILURE = "ROLES_NEW_FAILURE";

export const ROLES_UPDATE = "ROLES_UPDATE";
export const ROLES_UPDATE_SUCCESS = "ROLES_UPDATE_SUCCESS";
export const ROLES_UPDATE_FAILURE = "ROLES_UPDATE_FAILURE";

export const ROLES_LIST = "ROLES_LIST";
export const ROLES_LIST_SUCCESS = "ROLES_LIST_SUCCESS";
export const ROLES_LIST_FAILURE = "ROLES_LIST_FAILURE";

export const ROLES_LIST_BY_ROLE = "ROLES_LIST_BY_ROLE";
export const ROLES_LIST_BY_ROLE_SUCCESS = "ROLES_LIST_BY_ROLE_SUCCESS";
export const ROLES_LIST_BY_ROLE_FAILURE = "ROLES_LIST_BY_ROLE_FAILURE";

export const ROLES_DETAIL = "ROLES_DETAIL";
export const ROLES_DETAIL_SUCCESS = "ROLES_DETAIL_SUCCESS";
export const ROLES_DETAIL_FAILURE = "ROLES_DETAIL_FAILURE";

export const ROLES_DELETE = "ROLES_DELETE";
export const ROLES_DELETE_SUCCESS = "ROLES_DELETE_SUCCESS";
export const ROLES_DELETE_FAILURE = "ROLES_DELETE_FAILURE";

/** User module related types */
export const USER_LIST = "USER_LIST";
export const USER_LIST_SUCCESS = "USER_LIST_SUCCESS";
export const USER_LIST_FAILURE = "USER_LIST_FAILURE";

export const USER_LIST_BY_ROLE = "USER_LIST_BY_ROLE";
export const USER_LIST_BY_ROLE_SUCCESS = "USER_LIST_BY_ROLE_SUCCESS";
export const USER_LIST_BY_ROLE_FAILURE = "USER_LIST_BY_ROLE_FAILURE";

export const USER_NEW = "USER_NEW";
export const USER_NEW_SUCCESS = "USER_NEW_SUCCESS";
export const USER_NEW_FAILURE = "USER_NEW_FAILURE";

export const USER_UPDATE = "USER_UPDATE";
export const USER_UPDATE_SUCCESS = "USER_UPDATE_SUCCESS";
export const USER_UPDATE_FAILURE = "USER_UPDATE_FAILURE";

export const USER_DETAIL = "USER_DETAIL";
export const USER_DETAIL_SUCCESS = "USER_DETAIL_SUCCESS";
export const USER_DETAIL_FAILURE = "USER_DETAIL_FAILURE";

export const USER_DELETE = "USER_DELETE";
export const USER_DELETE_SUCCESS = "USER_DELETE_SUCCESS";
export const USER_DELETE_FAILURE = "USER_DELETE_FAILURE";

/** GST master module related types */

export const ADD_NEW_GST_MASTER = "ADD_NEW_GST_MASTER";
export const ADD_NEW_GST_MASTER_SUCCESS = "ADD_NEW_GST_MASTER_SUCCESS";
export const ADD_NEW_GST_MASTER_FAILURE = "ADD_NEW_GST_MASTER_FAILURE";

export const GST_MASTER_LIST = "GST_MASTER_LIST";
export const GST_MASTER_LIST_SUCCESS = "GST_MASTER_LIST_SUCCESS";
export const GST_MASTER_LIST_FAILURE = "GST_MASTER_LIST_FAILURE";

export const GST_MASTER_LIST_BY_ROLE = "GST_MASTER_LIST_BY_ROLE";
export const GST_MASTER_LIST_BY_ROLE_SUCCESS =
  "GST_MASTER_LIST_BY_ROLE_SUCCESS";
export const GST_MASTER_LIST_BY_ROLE_FAILURE =
  "GST_MASTER_LIST_BY_ROLE_FAILURE";

export const GST_MASTER_NEW = "GST_MASTER_NEW";
export const GST_MASTER_NEW_SUCCESS = "GST_MASTER_NEW_SUCCESS";
export const GST_MASTER_NEW_FAILURE = "GST_MASTER_NEW_FAILURE";

export const GST_MASTER_UPDATE = "GST_MASTER_UPDATE";
export const GST_MASTER_UPDATE_SUCCESS = "GST_MASTER_UPDATE_SUCCESS";
export const GST_MASTER_UPDATE_FAILURE = "GST_MASTER_UPDATE_FAILURE";

export const GST_MASTER_UPDATE_STATUS = "GST_MASTER_UPDATE_STATUS";
export const GST_MASTER_UPDATE_STATUS_SUCCESS =
  "GST_MASTER_UPDATE_STATUS_SUCCESS";
export const GST_MASTER_UPDATE_STATUS_FAILURE =
  "GST_MASTER_UPDATE_STATUS_FAILURE";

export const GST_MASTER_DETAIL = "GST_MASTER_DETAIL";
export const GST_MASTER_DETAIL_SUCCESS = "GST_MASTER_DETAIL_SUCCESS";
export const GST_MASTER_DETAIL_FAILURE = "GST_MASTER_DETAIL_FAILURE";

export const GST_MASTER_DELETE = "GST_MASTER_DELETE";
export const GST_MASTER_DELETE_SUCCESS = "GST_MASTER_DELETE_SUCCESS";
export const GST_MASTER_DELETE_FAILURE = "GST_MASTER_DELETE_FAILURE";

export const VERIFY_USER = "VERIFY_USER";
export const VERIFY_USER_SUCCESS = "VERIFY_USER_SUCCESS";
export const VERIFY_USER_FAILURE = "VERIFY_USER_FAILURE";

export const REGISTER_USER = "REGISTER_USER";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAILURE = "REGISTER_FAILURE";

export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const LOGOUT = "LOGOUT";
export const LOGOUT_SUCCESS = "LOGOUT_SUCCESS";
export const LOGOUT_FAILURE = "LOGOUT_FAILURE";

// Resources for Code Setup module
export const CODE_SETUP_NEW = "CODE_SETUP_NEW";
export const CODE_SETUP_NEW_SUCCESS = "CODE_SETUP_NEW_SUCCESS";
export const CODE_SETUP_NEW_FAILURE = "CODE_SETUP_NEW_FAILURE";

export const ADD_NEW_CODE_SETUP = "ADD_NEW_CODE_SETUP";
export const ADD_NEW_CODE_SETUP_SUCCESS = "ADD_NEW_CODE_SETUP_SUCCESS";
export const ADD_NEW_CODE_SETUP_FAILURE = "ADD_NEW_CODE_SETUP_FAILURE";

export const CODE_SETUP_UPDATE = "CODE_SETUP_UPDATE";
export const CODE_SETUP_UPDATE_SUCCESS = "CODE_SETUP_UPDATE_SUCCESS";
export const CODE_SETUP_UPDATE_FAILURE = "CODE_SETUP_UPDATE_FAILURE";

export const CODE_SETUP_LIST = "CODE_SETUP_LIST";
export const CODE_SETUP_LIST_SUCCESS = "CODE_SETUP_LIST_SUCCESS";
export const CODE_SETUP_LIST_FAILURE = "CODE_SETUP_LIST_FAILURE";

export const CODE_SETUP_LIST_BY_ROLE = "CODE_SETUP_LIST_BY_ROLE";
export const CODE_SETUP_LIST_BY_ROLE_SUCCESS =
  "CODE_SETUP_LIST_BY_ROLE_SUCCESS";
export const CODE_SETUP_LIST_BY_ROLE_FAILURE =
  "CODE_SETUP_LIST_BY_ROLE_FAILURE";

export const CODE_SETUP_DETAIL = "CODE_SETUP_DETAIL";
export const CODE_SETUP_DETAIL_SUCCESS = "CODE_SETUP_DETAIL_SUCCESS";
export const CODE_SETUP_DETAIL_FAILURE = "CODE_SETUP_DETAIL_FAILURE";

export const CODE_SETUP_DELETE = "CODE_SETUP_DELETE";
export const CODE_SETUP_DELETE_SUCCESS = "CODE_SETUP_DELETE_SUCCESS";
export const CODE_SETUP_DELETE_FAILURE = "CODE_SETUP_DELETE_FAILURE";

// Resources for add masters module
export const MASTER_LIST_NEW = "MASTER_LIST_NEW";
export const MASTER_LIST_NEW_SUCCESS = "MASTER_LIST_NEW_SUCCESS";
export const MASTER_LIST_NEW_FAILURE = "MASTER_LIST_NEW_FAILURE";

export const MASTER_LIST_UPDATE = "MASTER_LIST_UPDATE";
export const MASTER_LIST_UPDATE_SUCCESS = "MASTER_LIST_UPDATE_SUCCESS";
export const MASTER_LIST_UPDATE_FAILURE = "MASTER_LIST_UPDATE_FAILURE";

export const MASTER_LIST_ALL = "MASTER_LIST_ALL";
export const MASTER_LIST_ALL_SUCCESS = "MASTER_LIST_ALL_SUCCESS";
export const MASTER_LIST_ALL_FAILURE = "MASTER_LIST_ALL_FAILURE";

export const MASTER_LIST_DETAIL = "MASTER_LIST_DETAIL";
export const MASTER_LIST_DETAIL_SUCCESS = "MASTER_LIST_DETAIL_SUCCESS";
export const MASTER_LIST_DETAIL_FAILURE = "MASTER_LIST_DETAIL_FAILURE";

export const MASTER_LIST_DELETE = "MASTER_LIST_DELETE";
export const MASTER_LIST_DELETE_SUCCESS = "MASTER_LIST_DELETE_SUCCESS";
export const MASTER_LIST_DELETE_FAILURE = "MASTER_LIST_DELETE_FAILURE";

/** Profile module related types */
export const PROFILE_GET = "PROFILE_GET";
export const PROFILE_GET_SUCCESS = "PROFILE_GET_SUCCESS";
export const PROFILE_GET_FAILURE = "PROFILE_GET_FAILURE";

export const PROFILE_GET_BY_ROLE = "PROFILE_GET_BY_ROLE";
export const PROFILE_GET_BY_ROLE_SUCCESS = "PROFILE_GET_BY_ROLE_SUCCESS";
export const PROFILE_GET_BY_ROLE_FAILURE = "PROFILE_GET_BY_ROLE_FAILURE";

export const PROFILE_DETAIL = "PROFILE_DETAIL";
export const PROFILE_DETAIL_SUCCESS = "PROFILE_DETAIL_SUCCESS";
export const PROFILE_DETAIL_FAILURE = "PROFILE_DETAIL_FAILURE";

export const PROFILE_BANK_DETAIL = "PROFILE_BANK_DETAIL";
export const PROFILE_BANK_DETAIL_SUCCESS = "PROFILE_BANK_DETAIL_SUCCESS";
export const PROFILE_BANK_DETAIL_FAILURE = "PROFILE_BANK_DETAIL_FAILURE";

/** Holiday setup module related types */
export const HOLIDAYS_LIST = "HOLIDAYS_LIST";
export const HOLIDAYS_LIST_SUCCESS = "HOLIDAYS_LIST_SUCCESS";
export const HOLIDAYS_LIST_FAILURE = "HOLIDAYS_LIST_FAILURE";

export const HOLIDAYS_LIST_BY_ROLE = "HOLIDAYS_LIST_BY_ROLE";
export const HOLIDAYS_LIST_BY_ROLE_SUCCESS = "HOLIDAYS_LIST_BY_ROLE_SUCCESS";
export const HOLIDAYS_LIST_BY_ROLE_FAILURE = "HOLIDAYS_LIST_BY_ROLE_FAILURE";

export const HOLIDAYS_NEW = "HOLIDAYS_NEW";
export const HOLIDAYS_NEW_SUCCESS = "HOLIDAYS_NEW_SUCCESS";
export const HOLIDAYS_NEW_FAILURE = "HOLIDAYS_NEW_FAILURE";

export const HOLIDAYS_UPDATE = "HOLIDAYS_UPDATE";
export const HOLIDAYS_UPDATE_SUCCESS = "HOLIDAYS_UPDATE_SUCCESS";
export const HOLIDAYS_UPDATE_FAILURE = "HOLIDAYS_UPDATE_FAILURE";

export const HOLIDAYS_DETAIL = "HOLIDAYS_DETAIL";
export const HOLIDAYS_DETAIL_SUCCESS = "HOLIDAYS_DETAIL_SUCCESS";
export const HOLIDAYS_DETAIL_FAILURE = "HOLIDAYS_DETAIL_FAILURE";

export const HOLIDAYS_DELETE = "HOLIDAYS_DELETE";
export const HOLIDAYS_DELETE_SUCCESS = "HOLIDAYS_DELETE_SUCCESS";
export const HOLIDAYS_DELETE_FAILURE = "HOLIDAYS_DELETE_FAILURE";

// Resources for Sales module
// export const GENERATE_QUOTATION_ID = "GENERATE_QUOTATION_ID";
// export const GENERATE_QUOTATION_ID_SUCCESS = "GENERATE_QUOTATION_ID_SUCCESS";
// export const GENERATE_QUOTATION_ID_FAILURE = "GENERATE_QUOTATION_ID_FAILURE";

export const SALES_NEW = "SALES_NEW";
export const SALES_NEW_SUCCESS = "SALES_NEW_SUCCESS";
export const SALES_NEW_FAILURE = "SALES_NEW_FAILURE";

export const SALES_STATUS_UPDATE = "SALES_STATUS_UPDATE";
export const SALES_STATUS_UPDATE_SUCCESS = "SALES_STATUS_UPDATE_SUCCESS";
export const SALES_STATUS_UPDATE_FAILURE = "SALES_STATUS_UPDATE_FAILURE";

export const SALES_UPDATE = "SALES_UPDATE";
export const SALES_UPDATE_SUCCESS = "SALES_UPDATE_SUCCESS";
export const SALES_UPDATE_FAILURE = "SALES_UPDATE_FAILURE";

export const SALES_PAYMENT_UPDATE = "SALES_PAYMENT_UPDATE";
export const SALES_PAYMENT_UPDATE_SUCCESS = "SALES_PAYMENT_UPDATE_SUCCESS";
export const SALES_PAYMENT_UPDATE_FAILURE = "SALES_PAYMENT_UPDATE_FAILURE";

export const SALES_LIST = "SALES_LIST";
export const SALES_LIST_SUCCESS = "SALES_LIST_SUCCESS";
export const SALES_LIST_FAILURE = "SALES_LIST_FAILURE";

export const SALES_LIST_BY_ROLE = "SALES_LIST_BY_ROLE";
export const SALES_LIST_BY_ROLE_SUCCESS = "SALES_LIST_BY_ROLE_SUCCESS";
export const SALES_LIST_BY_ROLE_FAILURE = "SALES_LIST_BY_ROLE_FAILURE";

export const SALES_DETAIL = "SALES_DETAIL";
export const SALES_DETAIL_SUCCESS = "SALES_DETAIL_SUCCESS";
export const SALES_DETAIL_FAILURE = "SALES_DETAIL_FAILURE";

export const SALES_DELETE = "SALES_DELETE";
export const SALES_DELETE_SUCCESS = "SALES_DELETE_SUCCESS";
export const SALES_DELETE_FAILURE = "SALES_DELETE_FAILURE";

// Resources for Purchase Return module
// export const GENERATE_SALES_RETURN_ID = "GENERATE_SALES_RETURN_ID";
// export const GENERATE_SALES_RETURN_ID_SUCCESS = "GENERATE_SALES_RETURN_ID_SUCCESS";
// export const GENERATE_SALES_RETURN_ID_FAILURE = "GENERATE_SALES_RETURN_ID_FAILURE";

export const SALES_RETURN_NEW = "SALES_RETURN_NEW";
export const SALES_RETURN_NEW_SUCCESS = "SALES_RETURN_NEW_SUCCESS";
export const SALES_RETURN_NEW_FAILURE = "SALES_RETURN_NEW_FAILURE";

export const SALES_RETURN_STATUS_UPDATE = "SALES_RETURN_STATUS_UPDATE";
export const SALES_RETURN_STATUS_UPDATE_SUCCESS =
  "SALES_RETURN_STATUS_UPDATE_SUCCESS";
export const SALES_RETURN_STATUS_UPDATE_FAILURE =
  "SALES_RETURN_STATUS_UPDATE_FAILURE";

export const SALES_RETURN_PAYMENT_UPDATE = "SALES_RETURN_PAYMENT_UPDATE";
export const SALES_RETURN_PAYMENT_UPDATE_SUCCESS =
  "SALES_RETURN_PAYMENT_UPDATE_SUCCESS";
export const SALES_RETURN_PAYMENT_UPDATE_FAILURE =
  "SALES_RETURN_PAYMENT_UPDATE_FAILURE";

export const SALES_RETURN_UPDATE = "SALES_RETURN_UPDATE";
export const SALES_RETURN_UPDATE_SUCCESS = "SALES_RETURN_UPDATE_SUCCESS";
export const SALES_RETURN_UPDATE_FAILURE = "SALES_RETURN_UPDATE_FAILURE";

export const SALES_RETURN_LIST = "SALES_RETURN_LIST";
export const SALES_RETURN_LIST_SUCCESS = "SALES_RETURN_LIST_SUCCESS";
export const SALES_RETURN_LIST_FAILURE = "SALES_RETURN_LIST_FAILURE";

export const SALES_RETURN_LIST_BY_ROLE = "SALES_RETURN_LIST_BY_ROLE";
export const SALES_RETURN_LIST_BY_ROLE_SUCCESS =
  "SALES_RETURN_LIST_BY_ROLE_SUCCESS";
export const SALES_RETURN_LIST_BY_ROLE_FAILURE =
  "SALES_RETURN_LIST_BY_ROLE_FAILURE";

export const SALES_RETURN_DETAIL = "SALES_RETURN_DETAIL";
export const SALES_RETURN_DETAIL_SUCCESS = "SALES_RETURN_DETAIL_SUCCESS";
export const SALES_RETURN_DETAIL_FAILURE = "SALES_RETURN_DETAIL_FAILURE";

export const SALES_RETURN_DELETE = "SALES_RETURN_DELETE";
export const SALES_RETURN_DELETE_SUCCESS = "SALES_RETURN_DELETE_SUCCESS";
export const SALES_RETURN_DELETE_FAILURE = "SALES_RETURN_DELETE_FAILURE";

export const POS_ID_GENERATION_CHECK = "POS_ID_GENERATION_CHECK";
export const POS_ID_GENERATION_CHECK_SUCCESS =
  "POS_ID_GENERATION_CHECK_SUCCESS";
export const POS_ID_GENERATION_CHECK_FAILURE =
  "POS_ID_GENERATION_CHECK_FAILURE";

export const GENERATE_POS_ID = "GENERATE_POS_ID";
export const GENERATE_POS_ID_SUCCESS = "GENERATE_POS_ID_SUCCESS";
export const GENERATE_POS_ID_FAILURE = "GENERATE_POS_ID_FAILURE";

export const POS_NEW = "POS_NEW";
export const POS_NEW_SUCCESS = "POS_NEW_SUCCESS";
export const POS_NEW_FAILURE = "POS_NEW_FAILURE";

export const POS_CATEGORIES_LIST = "POS_CATEGORIES_LIST";
export const POS_CATEGORIES_LIST_SUCCESS = "POS_CATEGORIES_LIST_SUCCESS";
export const POS_CATEGORIES_LIST_FAILURE = "POS_CATEGORIES_LIST_FAILURE";

export const POS_STATUS_UPDATE = "POS_STATUS_UPDATE";
export const POS_STATUS_UPDATE_SUCCESS = "POS_STATUS_UPDATE_SUCCESS";
export const POS_STATUS_UPDATE_FAILURE = "POS_STATUS_UPDATE_FAILURE";

export const POS_UPDATE = "POS_UPDATE";
export const POS_UPDATE_SUCCESS = "POS_UPDATE_SUCCESS";
export const POS_UPDATE_FAILURE = "POS_UPDATE_FAILURE";

export const POS_PAYMENT_UPDATE = "POS_PAYMENT_UPDATE";
export const POS_PAYMENT_UPDATE_SUCCESS = "POS_PAYMENT_UPDATE_SUCCESS";
export const POS_PAYMENT_UPDATE_FAILURE = "POS_PAYMENT_UPDATE_FAILURE";

export const POS_LIST = "POS_LIST";
export const POS_LIST_SUCCESS = "POS_LIST_SUCCESS";
export const POS_LIST_FAILURE = "POS_LIST_FAILURE";

export const POS_LIST_BY_ROLE = "POS_LIST_BY_ROLE";
export const POS_LIST_BY_ROLE_SUCCESS = "POS_LIST_BY_ROLE_SUCCESS";
export const POS_LIST_BY_ROLE_FAILURE = "POS_LIST_BY_ROLE_FAILURE";

export const POS_DETAIL = "POS_DETAIL";
export const POS_DETAIL_SUCCESS = "POS_DETAIL_SUCCESS";
export const POS_DETAIL_FAILURE = "POS_DETAIL_FAILURE";

export const POS_DELETE = "POS_DELETE";
export const POS_DELETE_SUCCESS = "POS_DELETE_SUCCESS";
export const POS_DELETE_FAILURE = "POS_DELETE_FAILURE";

// Resources for Purchase module
// export const GENERATE_QUOTATION_ID = "GENERATE_QUOTATION_ID";
// export const GENERATE_QUOTATION_ID_SUCCESS = "GENERATE_QUOTATION_ID_SUCCESS";
// export const GENERATE_QUOTATION_ID_FAILURE = "GENERATE_QUOTATION_ID_FAILURE";

export const STOCK_REPORT_LIST = "STOCK_REPORT_LIST";
export const STOCK_REPORT_LIST_SUCCESS = "STOCK_REPORT_LIST_SUCCESS";
export const STOCK_REPORT_LIST_FAILURE = "STOCK_REPORT_LIST_FAILURE";

export const SALES_STOCK_REPORT_LIST = "SALES_STOCK_REPORT_LIST";
export const SALES_STOCK_REPORT_LIST_SUCCESS =
  "SALES_STOCK_REPORT_LIST_SUCCESS";
export const SALES_STOCK_REPORT_LIST_FAILURE =
  "SALES_STOCK_REPORT_LIST_FAILURE";

export const PURCHASE_NEW = "PURCHASE_NEW";
export const PURCHASE_NEW_SUCCESS = "PURCHASE_NEW_SUCCESS";
export const PURCHASE_NEW_FAILURE = "PURCHASE_NEW_FAILURE";

export const PURCHASE_STATUS_UPDATE = "PURCHASE_STATUS_UPDATE";
export const PURCHASE_STATUS_UPDATE_SUCCESS = "PURCHASE_STATUS_UPDATE_SUCCESS";
export const PURCHASE_STATUS_UPDATE_FAILURE = "PURCHASE_STATUS_UPDATE_FAILURE";

export const PURCHASE_UPDATE = "PURCHASE_UPDATE";
export const PURCHASE_UPDATE_SUCCESS = "PURCHASE_UPDATE_SUCCESS";
export const PURCHASE_UPDATE_FAILURE = "PURCHASE_UPDATE_FAILURE";

export const PURCHASE_PAYMENT_UPDATE = "PURCHASE_PAYMENT_UPDATE";
export const PURCHASE_PAYMENT_UPDATE_SUCCESS =
  "PURCHASE_PAYMENT_UPDATE_SUCCESS";
export const PURCHASE_PAYMENT_UPDATE_FAILURE =
  "PURCHASE_PAYMENT_UPDATE_FAILURE";

export const PURCHASE_LIST = "PURCHASE_LIST";
export const PURCHASE_LIST_SUCCESS = "PURCHASE_LIST_SUCCESS";
export const PURCHASE_LIST_FAILURE = "PURCHASE_LIST_FAILURE";

export const PURCHASE_LIST_BY_ROLE = "PURCHASE_LIST_BY_ROLE";
export const PURCHASE_LIST_BY_ROLE_SUCCESS = "PURCHASE_LIST_BY_ROLE_SUCCESS";
export const PURCHASE_LIST_BY_ROLE_FAILURE = "PURCHASE_LIST_BY_ROLE_FAILURE";

export const PURCHASE_DETAIL = "PURCHASE_DETAIL";
export const PURCHASE_DETAIL_SUCCESS = "PURCHASE_DETAIL_SUCCESS";
export const PURCHASE_DETAIL_FAILURE = "PURCHASE_DETAIL_FAILURE";

export const PURCHASE_DELETE = "PURCHASE_DELETE";
export const PURCHASE_DELETE_SUCCESS = "PURCHASE_DELETE_SUCCESS";
export const PURCHASE_DELETE_FAILURE = "PURCHASE_DELETE_FAILURE";

// Resources for Purchase Return module
// export const GENERATE_PURCHASE_RETURN_ID = "GENERATE_PURCHASE_RETURN_ID";
// export const GENERATE_PURCHASE_RETURN_ID_SUCCESS = "GENERATE_PURCHASE_RETURN_ID_SUCCESS";
// export const GENERATE_PURCHASE_RETURN_ID_FAILURE = "GENERATE_PURCHASE_RETURN_ID_FAILURE";

export const PURCHASE_RETURN_NEW = "PURCHASE_RETURN_NEW";
export const PURCHASE_RETURN_NEW_SUCCESS = "PURCHASE_RETURN_NEW_SUCCESS";
export const PURCHASE_RETURN_NEW_FAILURE = "PURCHASE_RETURN_NEW_FAILURE";

export const PURCHASE_RETURN_STATUS_UPDATE = "PURCHASE_RETURN_STATUS_UPDATE";
export const PURCHASE_RETURN_STATUS_UPDATE_SUCCESS =
  "PURCHASE_RETURN_STATUS_UPDATE_SUCCESS";
export const PURCHASE_RETURN_STATUS_UPDATE_FAILURE =
  "PURCHASE_RETURN_STATUS_UPDATE_FAILURE";

export const PURCHASE_RETURN_PAYMENT_UPDATE = "PURCHASE_RETURN_PAYMENT_UPDATE";
export const PURCHASE_RETURN_PAYMENT_UPDATE_SUCCESS =
  "PURCHASE_RETURN_PAYMENT_UPDATE_SUCCESS";
export const PURCHASE_RETURN_PAYMENT_UPDATE_FAILURE =
  "PURCHASE_RETURN_PAYMENT_UPDATE_FAILURE";

export const PURCHASE_RETURN_UPDATE = "PURCHASE_RETURN_UPDATE";
export const PURCHASE_RETURN_UPDATE_SUCCESS = "PURCHASE_RETURN_UPDATE_SUCCESS";
export const PURCHASE_RETURN_UPDATE_FAILURE = "PURCHASE_RETURN_UPDATE_FAILURE";

export const PURCHASE_RETURN_LIST = "PURCHASE_RETURN_LIST";
export const PURCHASE_RETURN_LIST_SUCCESS = "PURCHASE_RETURN_LIST_SUCCESS";
export const PURCHASE_RETURN_LIST_FAILURE = "PURCHASE_RETURN_LIST_FAILURE";

export const PURCHASE_RETURN_LIST_BY_ROLE = "PURCHASE_RETURN_LIST_BY_ROLE";
export const PURCHASE_RETURN_LIST_BY_ROLE_SUCCESS =
  "PURCHASE_RETURN_LIST_BY_ROLE_SUCCESS";
export const PURCHASE_RETURN_LIST_BY_ROLE_FAILURE =
  "PURCHASE_RETURN_LIST_BY_ROLE_FAILURE";

export const PURCHASE_RETURN_DETAIL = "PURCHASE_RETURN_DETAIL";
export const PURCHASE_RETURN_DETAIL_SUCCESS = "PURCHASE_RETURN_DETAIL_SUCCESS";
export const PURCHASE_RETURN_DETAIL_FAILURE = "PURCHASE_RETURN_DETAIL_FAILURE";

export const PURCHASE_RETURN_DELETE = "PURCHASE_RETURN_DELETE";
export const PURCHASE_RETURN_DELETE_SUCCESS = "PURCHASE_RETURN_DELETE_SUCCESS";
export const PURCHASE_RETURN_DELETE_FAILURE = "PURCHASE_RETURN_DELETE_FAILURE";

// RESOURCES FOR PO NUMBER

export const PO_ID_GENERATION_CHECK = "PO_ID_GENERATION_CHECK";
export const PO_ID_GENERATION_CHECK_SUCCESS =
  "PO_ID_GENERATION_CHECK_SUCCESS";
export const PO_ID_GENERATION_CHECK_FAILURE =
  "PO_ID_GENERATION_CHECK_FAILURE";

export const GENERATE_PO_ID = "GENERATE_PO_ID";
export const GENERATE_PO_ID_SUCCESS = "GENERATE_PO_ID_SUCCESS";
export const GENERATE_PO_ID_FAILURE = "GENERATE_PO_ID_FAILURE";

export const PO_NEW = "PO_NEW";
export const PO_NEW_SUCCESS = "PO_NEW_SUCCESS";
export const PO_NEW_FAILURE = "PO_NEW_FAILURE";

export const PO_CATEGORIES_LIST = "PO_CATEGORIES_LIST";
export const PO_CATEGORIES_LIST_SUCCESS = "PO_CATEGORIES_LIST_SUCCESS";
export const PO_CATEGORIES_LIST_FAILURE = "PO_CATEGORIES_LIST_FAILURE";

export const PO_STATUS_UPDATE = "PO_STATUS_UPDATE";
export const PO_STATUS_UPDATE_SUCCESS = "PO_STATUS_UPDATE_SUCCESS";
export const PO_STATUS_UPDATE_FAILURE = "PO_STATUS_UPDATE_FAILURE";

export const PO_UPDATE = "PO_UPDATE";
export const PO_UPDATE_SUCCESS = "PO_UPDATE_SUCCESS";
export const PO_UPDATE_FAILURE = "PO_UPDATE_FAILURE";

export const PO_PAYMENT_UPDATE = "PO_PAYMENT_UPDATE";
export const PO_PAYMENT_UPDATE_SUCCESS = "PO_PAYMENT_UPDATE_SUCCESS";
export const PO_PAYMENT_UPDATE_FAILURE = "PO_PAYMENT_UPDATE_FAILURE";

export const PO_LIST = "PO_LIST";
export const PO_LIST_SUCCESS = "PO_LIST_SUCCESS";
export const PO_LIST_FAILURE = "PO_LIST_FAILURE";

export const PO_LIST_BY_ROLE = "PO_LIST_BY_ROLE";
export const PO_LIST_BY_ROLE_SUCCESS = "PO_LIST_BY_ROLE_SUCCESS";
export const PO_LIST_BY_ROLE_FAILURE = "PO_LIST_BY_ROLE_FAILURE";

export const PO_DETAIL = "PO_DETAIL";
export const PO_DETAIL_SUCCESS = "PO_DETAIL_SUCCESS";
export const PO_DETAIL_FAILURE = "PO_DETAIL_FAILURE";

export const PO_DELETE = "PO_DELETE";
export const PO_DELETE_SUCCESS = "PO_DELETE_SUCCESS";
export const PO_DELETE_FAILURE = "PO_DELETE_FAILURE";

// Resources for Brand module

export const BRAND_NEW = "BRAND_NEW";
export const BRAND_NEW_SUCCESS = "BRAND_NEW_SUCCESS";
export const BRAND_NEW_FAILURE = "BRAND_NEW_FAILURE";

export const ADD_NEW_BRAND = "ADD_NEW_BRAND";
export const ADD_NEW_BRAND_SUCCESS = "ADD_NEW_BRAND_SUCCESS";
export const ADD_NEW_BRAND_FAILURE = "ADD_NEW_BRAND_FAILURE";

export const BRAND_UPDATE = "BRAND_UPDATE";
export const BRAND_UPDATE_SUCCESS = "BRAND_UPDATE_SUCCESS";
export const BRAND_UPDATE_FAILURE = "BRAND_UPDATE_FAILURE";

export const BRAND_LIST = "BRAND_LIST";
export const BRAND_LIST_SUCCESS = "BRAND_LIST_SUCCESS";
export const BRAND_LIST_FAILURE = "BRAND_LIST_FAILURE";

export const BRAND_LIST_BY_ROLE = "BRAND_LIST_BY_ROLE";
export const BRAND_LIST_BY_ROLE_SUCCESS = "BRAND_LIST_BY_ROLE_SUCCESS";
export const BRAND_LIST_BY_ROLE_FAILURE = "BRAND_LIST_BY_ROLE_FAILURE";

export const BRAND_DETAIL = "BRAND_DETAIL";
export const BRAND_DETAIL_SUCCESS = "BRAND_DETAIL_SUCCESS";
export const BRAND_DETAIL_FAILURE = "BRAND_DETAIL_FAILURE";

export const BRAND_DELETE = "BRAND_DELETE";
export const BRAND_DELETE_SUCCESS = "BRAND_DELETE_SUCCESS";
export const BRAND_DELETE_FAILURE = "BRAND_DELETE_FAILURE";

// Resources for Category module

export const CATEGORY_NEW = "CATEGORY_NEW";
export const CATEGORY_NEW_SUCCESS = "CATEGORY_NEW_SUCCESS";
export const CATEGORY_NEW_FAILURE = "CATEGORY_NEW_FAILURE";

export const ADD_NEW_CATEGORY = "ADD_NEW_CATEGORY";
export const ADD_NEW_CATEGORY_SUCCESS = "ADD_NEW_CATEGORY_SUCCESS";
export const ADD_NEW_CATEGORY_FAILURE = "ADD_NEW_CATEGORY_FAILURE";

export const CATEGORY_UPDATE = "CATEGORY_UPDATE";
export const CATEGORY_UPDATE_SUCCESS = "CATEGORY_UPDATE_SUCCESS";
export const CATEGORY_UPDATE_FAILURE = "CATEGORY_UPDATE_FAILURE";

export const CATEGORY_LIST = "CATEGORY_LIST";
export const CATEGORY_LIST_SUCCESS = "CATEGORY_LIST_SUCCESS";
export const CATEGORY_LIST_FAILURE = "CATEGORY_LIST_FAILURE";

export const CATEGORY_LIST_BY_ROLE = "CATEGORY_LIST_BY_ROLE";
export const CATEGORY_LIST_BY_ROLE_SUCCESS = "CATEGORY_LIST_BY_ROLE_SUCCESS";
export const CATEGORY_LIST_BY_ROLE_FAILURE = "CATEGORY_LIST_BY_ROLE_FAILURE";

export const CATEGORY_DETAIL = "CATEGORY_DETAIL";
export const CATEGORY_DETAIL_SUCCESS = "CATEGORY_DETAIL_SUCCESS";
export const CATEGORY_DETAIL_FAILURE = "CATEGORY_DETAIL_FAILURE";

export const CATEGORY_DELETE = "CATEGORY_DELETE";
export const CATEGORY_DELETE_SUCCESS = "CATEGORY_DELETE_SUCCESS";
export const CATEGORY_DELETE_FAILURE = "CATEGORY_DELETE_FAILURE";

// Resources for Sub Category module

export const SUB_CATEGORY_NEW = "SUB_CATEGORY_NEW";
export const SUB_CATEGORY_NEW_SUCCESS = "SUB_CATEGORY_NEW_SUCCESS";
export const SUB_CATEGORY_NEW_FAILURE = "SUB_CATEGORY_NEW_FAILURE";

export const ADD_NEW_SUB_CATEGORY = "ADD_NEW_SUB_CATEGORY";
export const ADD_NEW_SUB_CATEGORY_SUCCESS = "ADD_NEW_SUB_CATEGORY_SUCCESS";
export const ADD_NEW_SUB_CATEGORY_FAILURE = "ADD_NEW_SUB_CATEGORY_FAILURE";

export const SUB_CATEGORY_UPDATE = "SUB_CATEGORY_UPDATE";
export const SUB_CATEGORY_UPDATE_SUCCESS = "SUB_CATEGORY_UPDATE_SUCCESS";
export const SUB_CATEGORY_UPDATE_FAILURE = "SUB_CATEGORY_UPDATE_FAILURE";

export const SUB_CATEGORY_LIST = "SUB_CATEGORY_LIST";
export const SUB_CATEGORY_LIST_SUCCESS = "SUB_CATEGORY_LIST_SUCCESS";
export const SUB_CATEGORY_LIST_FAILURE = "SUB_CATEGORY_LIST_FAILURE";

export const SUB_CATEGORY_LIST_BY_ROLE = "SUB_CATEGORY_LIST_BY_ROLE";
export const SUB_CATEGORY_LIST_BY_ROLE_SUCCESS =
  "SUB_CATEGORY_LIST_BY_ROLE_SUCCESS";
export const SUB_CATEGORY_LIST_BY_ROLE_FAILURE =
  "SUB_CATEGORY_LIST_BY_ROLE_FAILURE";

export const SUB_CATEGORY_DETAIL = "SUB_CATEGORY_DETAIL";
export const SUB_CATEGORY_DETAIL_SUCCESS = "SUB_CATEGORY_DETAIL_SUCCESS";
export const SUB_CATEGORY_DETAIL_FAILURE = "SUB_CATEGORY_DETAIL_FAILURE";

export const SUB_CATEGORY_DELETE = "SUB_CATEGORY_DELETE";
export const SUB_CATEGORY_DELETE_SUCCESS = "SUB_CATEGORY_DELETE_SUCCESS";
export const SUB_CATEGORY_DELETE_FAILURE = "SUB_CATEGORY_DELETE_FAILURE";

/** Product module related types */
export const PRODUCT_NEW = "PRODUCT_NEW";
export const PRODUCT_NEW_SUCCESS = "PRODUCT_NEW_SUCCESS";
export const PRODUCT_NEW_FAILURE = "PRODUCT_NEW_FAILURE";

export const ADD_NEW_PRODUCT = "ADD_NEW_PRODUCT";
export const ADD_NEW_PRODUCT_SUCCESS = "ADD_NEW_PRODUCT_SUCCESS";
export const ADD_NEW_PRODUCT_FAILURE = "ADD_NEW_PRODUCT_FAILURE";

export const PRODUCT_UPDATE = "PRODUCT_UPDATE";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAILURE = "PRODUCT_UPDATE_FAILURE";

export const PRODUCT_STATUS_UPDATE = "PRODUCT_STATUS_UPDATE";
export const PRODUCT_STATUS_UPDATE_SUCCESS = "PRODUCT_STATUS_UPDATE_SUCCESS";
export const PRODUCT_STATUS_UPDATE_FAILURE = "PRODUCT_STATUS_UPDATE_FAILURE";

export const PRODUCT_LIST = "PRODUCT_LIST";
export const PRODUCT_LIST_SUCCESS = "PRODUCT_LIST_SUCCESS";
export const PRODUCT_LIST_FAILURE = "PRODUCT_LIST_FAILURE";

export const PRODUCT_LIST_BY_ROLE = "PRODUCT_LIST_BY_ROLE";
export const PRODUCT_LIST_BY_ROLE_SUCCESS = "PRODUCT_LIST_BY_ROLE_SUCCESS";
export const PRODUCT_LIST_BY_ROLE_FAILURE = "PRODUCT_LIST_BY_ROLE_FAILURE";

export const PRODUCT_DETAIL = "PRODUCT_DETAIL";
export const PRODUCT_DETAIL_SUCCESS = "PRODUCT_DETAIL_SUCCESS";
export const PRODUCT_DETAIL_FAILURE = "PRODUCT_DETAIL_FAILURE";

export const PRODUCT_DELETE = "PRODUCT_DELETE";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAILURE = "PRODUCT_DELETE_FAILURE";
