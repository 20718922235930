import {
  HOLIDAYS_DELETE_SUCCESS,
  HOLIDAYS_DETAIL,
  HOLIDAYS_DETAIL_SUCCESS,
  HOLIDAYS_LIST,
  HOLIDAYS_LIST_BY_ROLE,
  HOLIDAYS_LIST_BY_ROLE_FAILURE,
  HOLIDAYS_LIST_BY_ROLE_SUCCESS,
  HOLIDAYS_LIST_FAILURE,
  HOLIDAYS_LIST_SUCCESS,
  HOLIDAYS_NEW,
  HOLIDAYS_NEW_SUCCESS,
  HOLIDAYS_UPDATE,
  HOLIDAYS_UPDATE_SUCCESS,
} from "../types.js";

const initialState = {
  holidays: [],
  holidayDetails: null,
  createOrUpdated: false,

  // holidays by role
  holidaysByRole: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case HOLIDAYS_LIST:
    case HOLIDAYS_NEW:
    case HOLIDAYS_UPDATE:
    case HOLIDAYS_DETAIL:
      return {
        ...state,
        createOrUpdated: false,
        holidayDetails: null,
      };

    case HOLIDAYS_NEW_SUCCESS:
    case HOLIDAYS_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case HOLIDAYS_DETAIL_SUCCESS: {
      return { ...state, holidayDetails: payload.holidayDetails };
    }

    case HOLIDAYS_LIST_SUCCESS:
      return { ...state, holidays: payload.holidays };
    case HOLIDAYS_LIST_FAILURE:
      return { ...state };

    case HOLIDAYS_LIST_BY_ROLE:
      return { ...state, holidaysByRole: [] };
    case HOLIDAYS_LIST_BY_ROLE_SUCCESS:
      return { ...state, holidaysByRole: payload.holidaysByRole };
    case HOLIDAYS_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case HOLIDAYS_DELETE_SUCCESS:
      return {
        ...state,
        holidays: state.holidays.filter((item) => item._id !== payload.id),
      };

    default:
      return { ...state };
  }
}
