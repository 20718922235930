import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../../config/firebase-config";
import { generateMessage } from "../firebaseMessage";
import api from "../api";
import { CryptoJSAesJson } from "../../utils/utils";

let response = {
  status: null,
  data: null,
  message: "",
};

// export const fetchCustomerAsync = async (payload) => {
//   try {
//     // const querySnapshot = await api.get(`/customers?createdBy=${payload.createdBy}&parentId=${payload.parentId}`)
//     const querySnapshot = await api.get(`/customers`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//     } else {
//       response = {
//         status: 400,
//         message: t("message_customers_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//     // try {
//     //   const customerRef = collection(database, "customers");

//     //   const q = query(customerRef, where("createdBy", "==", String(uid)));

//     //   const querySnapshot = await getDocs(q);

//     //   if (!querySnapshot.empty) {
//     //     const dataArr = [];
//     //     querySnapshot.forEach((doc) => {
//     //       dataArr.push(doc.data());
//     //     });
//     //     response = {
//     //       status: 200,
//     //       data: dataArr,
//     //       message: t("message_customers_get_success"),
//     //     };
//     //   } else {
//     //     response = {
//     //       status: 400,
//     //       message: t("message_customers_not_found"),
//     //     };
//     //   }

//     //   return response;
//     // } catch (error) {
//     //   let message = generateMessage(error.code);
//     //   response = {
//     //     status: 401,
//     //     data: null,
//     //     message: message || error.message,
//     //   };
//     //   return response;
//     // }
// };

// export const createCustomerAsync = async (payload) => {

//   try {
//     const querySnapshot = await api.post(`/customers`, payload)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_customer_new_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: querySnapshot?.data?.message,
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await setDoc(doc(database, "customers", Number(payload._id).toString()), {
//   //     ...payload,
//   //     createdAt: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_customer_new_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const updateCustomerAsync = async (payload, id) => {

//   try {
//     const querySnapshot = await api.put(`/customers/${id}`, payload)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_customer_update_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_customers_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await updateDoc(doc(database, "customers", String(id)), {
//   //     ...payload,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_customer_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const fetchCustomerDetailAsync = async (id) => {

//   try {
//     const querySnapshot = await api.get(`/customers/${id}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };console.log("payload is", querySnapshot.data)
//     } else {
//       response = {
//         status: 400,
//         message: t("message_customers_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const docRef = doc(database, "customers", id);
//   //   const docSnap = await getDoc(docRef);

//   //   if (docSnap.exists()) {
//   //     response = {
//   //       status: 200,
//   //       data: docSnap.data(),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 401,
//   //       data: null,
//   //       message: "customer details not found",
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const deleteCustomerAsync = async (id) => {

//   try {
//     const querySnapshot = await api.delete(`/customers/${id}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("customers_deleted_succeful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_customers_not_found"),
//       };
//     }
//     return response;
//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 400,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const docRef = doc(database, "customers", String(id));
//   //   await deleteDoc(docRef);

//   //   response = {
//   //     status: 200,
//   //     message: "customer deleted succesfully...",
//   //   };

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 400,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

export const fetchCustomerByRoleAsync = async (payload) =>
  await api
    .get(`/customers/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchCustomerAsync = async (payload) =>
  await api
    .get(`/customers`)
    .then((res) => res.data)
    .catch((err) => err);

export const createCustomerAsync = async (payload) =>
  await api
    .post(`/customers`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateCustomerAsync = async (payload, id) =>
  await api
    .put(`/customers/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchCustomerDetailAsync = async (id) =>
  await api
    .get(`/customers/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteCustomerAsync = async (id) =>
  await api
    .delete(`/customers/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
