import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Loader from "./components/Loader";
import AppNavigator from "./views/AppNavigator";
import AuthNavigator from "./views/AuthNavigator";
import "./App.scss";
import cogoToast from "cogo-toast";
import { useLocation } from "react-router-dom";
import {
  fetchUserProfile,
  logoutUser,
  resetPasswordRequest,
} from "./redux/auth/actions";
import { useTranslation } from "react-i18next";
import { useIdleTimer } from "react-idle-timer";
import Sidebar from "./components/Sidebar";
import Navbar from "./components/Navbar";
import { fetchProfileInfo } from "./redux/profile/actions";
import { store } from "./redux/index";
import setup from "./services/setupInterceptor";
import Swal from "sweetalert2";
import { showLoader } from "./redux/lem/action";

export const verifyPermission = (key) => {
  // const permissions = store.getState().Auth.permissions;
  // const permissions = store.getState().Auth?.profile?.data?.role?.permissions;
  const permissions = store.getState().Auth?.permissions;
  if (permissions && permissions.length !== 0) {
    // console.log("PERMISSION", permissions);
    return permissions.includes(key);
  }
  return true;
};

function App() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const authState = useSelector((state) => state.Auth);
  const profileState = useSelector((state) => state.Profile);

  const [templateState, setTemplateState] = useState({
    isFullPageLayout: false
  })

  const closeOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas")?.classList?.remove("active");
  };

  const handleOnIdle = (event) => {
    // console.log("user is idle", event);
    // console.log("last active", getLastActiveTime());
    if (getRemainingTime() === 0 && authState.isLoggedIn) {
      cogoToast.info(t("your_session_has_been_expired"));
      dispatch(logoutUser());
    }
  };

  const { getRemainingTime } = useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 250,
  });

  useEffect(() => {
    setup(store);
    // if (user.isLoggedIn) {
    //   dispatch(resetPermissions());
    // }
  }, []);

  useEffect(() => {
    if (authState.isLoggedIn) {
      dispatch(fetchUserProfile());
    }
  }, [authState.isLoggedIn]);

  // useEffect(() => {
  //   // setup for showing innerpages in full width
  //   const fullPageLayoutRoutes = ["/sales/pos"];
  //   for (let i = 0; i < fullPageLayoutRoutes.length; i++) {
  //     if (location.pathname === fullPageLayoutRoutes[i]) {
  //       setTemplateState( prev => ({...prev, isFullPageLayout: true}));
  //       document
  //         .querySelector(".page-body-wrapper")
  //         ?.classList.add("full-page-wrapper");
  //       break;
  //     } else {
  //       setTemplateState( prev => ({...prev, isFullPageLayout: true}));
  //       document
  //         .querySelector(".page-body-wrapper")
  //         ?.classList.remove("full-page-wrapper");
  //     }
  //   }
  // },[location.pathname]);

  useEffect(() => {
    if (authState.isLoggedIn) {
      // dispatch(
      //   fetchProfileInfo(
      //     authState.profile?.createdBy != ""
      //       ? authState.profile?.createdBy
      //       : authState.profile?.id
      //   )
      // );
      dispatch(fetchProfileInfo());
    }
  }, [profileState.createOrUpdate, authState.isLoggedIn, authState.profile]);

  useEffect(() => {
    if (authState.isLoggedIn && authState.isFirstLogin) {
      Swal.fire({
        title: "Please change the password first!",
        input: "password",
        inputLabel: "new password",
        showCancelButton: false,
        allowOutsideClick: false,
        inputValidator: (value) => {
          if (!value) {
            return "Please enter password";
          }
          if (
            value &&
            !value.match(
              /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/
            )
          ) {
            return "Please enter valid password";
          }
        },
      }).then((result) => {
        if (result.value) {
          dispatch(showLoader());
          dispatch(
            resetPasswordRequest({
              email: authState?.user?.email,
              password: result.value,
            })
          );
        }
      });
    }
  }, [authState.isFirstLogin, authState.isLoggedIn]);

  if (authState.isLoggedIn && !authState.isFirstLogin) {
    return (
      <div className="container-scroller">
        <Sidebar />
        <div className="container-fluid page-body-wrapper">
          <Navbar />
          <div className="main-panel" onClick={() => closeOffcanvas()}>
            <div className="content-wrapper">
              <Loader />
              <AppNavigator />
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <Loader />
        <AuthNavigator />
      </>
    );
  }
}

export default App;
