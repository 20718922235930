import { generateMessage } from "./firebaseMessage";
import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const checkIDGeneratedAsync = async (moduleSlug) =>
  await api
    .get(`/code-setup/slug/assign?moduleSlug=${moduleSlug}`)
    .then((res) => res.data)
    .catch((err) => err);

export const generateID = async (uid, moduleSlug) => {
  try {
    // const response = await api.get(
    //   `/code-setup/slug?createdBy=${uid}&moduleSlug=${moduleSlug}`
    // );
    const res = await api.get(
      `/code-setup/slug/assign?moduleSlug=${moduleSlug}`
    );
    console.log("response", res);
    // const quotationRef = collection(database, "codeSetups");

    // const q = query(
    //   quotationRef,
    //   where("createdBy", "==", uid),
    //   where("moduleSlug", "==", moduleSlug)
    // );

    // const response = await getDocs(q);
    if (res?.data?.status == 200 || res?.data?.status == 201) {
      if (res?.data?.data != null) {
        let obj = { ...res?.data?.data };

        let previewArr = obj.preview.split(`${obj.separator}`);
        let increment = obj.lastGeneratedId
          ? String(Number(obj.lastGeneratedId) + 1).padStart(
              obj.digitLength,
              "0"
            )
          : String(
              Number(previewArr[1]) === 1 ? Number(previewArr[1]) : +1
            ).padStart(obj.digitLength, "0");

        let generatedId =
          obj.prefix +
          obj.separator +
          increment +
          `${obj.suffix ? obj.separator : ""}` +
          obj.suffix;
        let lastGeneratedId = increment;

        if (generatedId !== "") {
          try {
            // await updateDoc(doc(database, "codeSetups", String(obj._id)), {
            //   lastGeneratedId: lastGeneratedId,
            //   lastModified: new Date(),
            // });
            await api.put(`/code-setup/${obj.id}`, {
              lastGeneratedId,
            });

            response = {
              status: 200,
              data: generatedId,
              message: res?.data?.message,
            };
            return response;
          } catch (error) {
            let message = generateMessage(error.code);
            response = {
              status: 401,
              data: null,
              message: res?.data?.message,
            };
            return response;
          }
        }
        return response
      } else {
        response = {
          status: 404,
          // message: `Code setup not available for ${moduleSlug} module.`,
          message: response?.data?.message,
        };
        return response;
      }
    } else {
      response = {
        status: 404,
        message: response?.data?.message,
      };
      return response;
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }
};
