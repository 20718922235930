import api from "../api";

export const fetchPurchaseAsync = async (uid) => await api.get(`/purchase`).then((res) => res.data).catch((err) => err);

export const fetchPurchaseOrderAsync = async (uid) => await api.get(`/reports/stock`).then((res) => res.data).catch((err) => err);

export const fetchPurchaseByRoleAsync = async (uid) => await api.get(`/purchase/assign`).then((res) => res.data).catch((err) => err);

export const createPurchaseAsync = async (payload) => await api.post(`/purchase`, payload).then((res) => res.data).catch((err) => err);

export const updatePurchaseAsync = async (payload, id) => await api.put(`/purchase/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updatePurchaseStatusAsync = async (payload, id) => await api.put(`/purchase/status/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updatePurchasePaymentInfoAsync = async (payload, id) =>
  await api
    .put(`/purchase/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchPurchaseDetailAsync = async (id) => await api.get(`/purchase/${id}`).then((res) => res.data).catch((err) => err);

export const deletePurchaseAsync = async (id) => await api.delete(`/purchase/${id}`).then((res) => res.data).catch((err) => err);