import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";
import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

// export const fetchTicketAsync = async (uid, haveBeenAssigned) => {

//   // let query = `createdBy=${uid}`
//   // if (haveBeenAssigned) {
//   //   query = `assignedTo=${uid}`
//   // }
//   let query = ``
//   if (haveBeenAssigned) {
//     query = `assignedTo=${uid}`
//   }
  
//   try {
//     // const querySnapshot = await api.get(`/tickets?createdBy=${uid}`)
//     const querySnapshot = await api.get(`/tickets?${query}`)
    
    
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//     } else {
//       response = {
//         status: 400,
//         message: t("message_tickets_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const ticketRef = collection(database, "tickets");

//   //   let q = query(ticketRef);
//   //   if(haveBeenAssigned){
//   //     q = query(ticketRef,where("assignedTo", "==", String(uid)))
//   //   }else{
//   //     q = query(ticketRef,where("createdBy", "==", String(uid)))
//   //   }

//   //   const querySnapshot = await getDocs(q);

//   //   if (!querySnapshot.empty) {
//   //     const dataArr = [];
//   //     querySnapshot.forEach((doc) => {
//   //       dataArr.push(doc.data());
//   //     });

//   //     response = {
//   //       status: 200,
//   //       data: dataArr,
//   //       message: t("message_tickets_get_success"),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 400,
//   //       message: t("message_tickets_not_found"),
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const generateTicketIDAsync = async (uid) => {
//   const response = await generateID(uid, "Ticket management");
//   return response;
// };

// export const createTicketAsync = async (payload) => {

//   console.log("payload is", payload)
//   try {
//     const querySnapshot = await api.post(`/tickets`, payload)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_ticket_new_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: querySnapshot?.data?.message,
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await setDoc(doc(database, "tickets", Number(payload._id).toString()), {
//   //     ...payload,
//   //     createdAt: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_ticket_new_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const updateTicketAsync = async (payload, id) => {

//   delete payload["createdAt"]
//   console.log("payload is =>", payload);

//   try {
//     const querySnapshot = await api.put(`/tickets/${id}`, payload)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_ticket_update_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_tickets_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await updateDoc(doc(database, "tickets", String(id)), {
//   //     ...payload,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_ticket_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const fetchTicketDetailAsync = async (id) => {

//   try {
//     const querySnapshot = await api.get(`/tickets/${id}`)
//     console.log("querySnapshot",querySnapshot)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };console.log("payload is", querySnapshot.data)
//     } else {
//       response = {
//         status: 400,
//         message: t("message_tickets_not_found"),  
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const ticketRef = collection(database, "tickets");

//   //   const docRef = doc(database, "tickets", id);
//   //   const docSnap = await getDoc(docRef);

//   //   if (docSnap.exists()) {
//   //     response = {
//   //       status: 200,
//   //       data: docSnap.data(),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 401,
//   //       data: null,
//   //       message: "ticket details not found",
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const deleteTicketAsync = async (id) => {

//   try {
//     const querySnapshot = await api.delete(`/tickets/${id}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("tickets_deleted_succeful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_tickets_not_found"),
//       };
//     }
//     return response;
//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 400,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const docRef = doc(database, "tickets", String(id));
//   //   await deleteDoc(docRef);

//   //   response = {
//   //     status: 200,
//   //     message: "ticket deleted",
//   //   };

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 400,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };


export const fetchTicketAsync = async (uid, haveBeenAssigned) => {

  // let query = `createdBy=${uid}`
  // if (haveBeenAssigned) {
  //   query = `assignedTo=${uid}`
  // }
  let query = ``
  if (haveBeenAssigned) {
    query = `assignedTo=${uid}`
  }
  
  return await api.get(`/tickets?${query}`).then((res) => res.data).catch((err) => err);
};

export const fetchTicketByRoleAsync = async (uid, haveBeenAssigned) => {

  // let query = `createdBy=${uid}`
  // if (haveBeenAssigned) {
  //   query = `assignedTo=${uid}`
  // }
  let query = ``
  if (haveBeenAssigned) {
    query = `assignedTo=${uid}`
  }
  
  return await api.get(`/tickets?${query}`).then((res) => res.data).catch((err) => err);
};

// export const generateTicketIDAsync = async (uid) => {
//   const response = await generateID(uid, "Ticket management");
//   return response;
// };

export const generateTicketIDAsync = async (uid) => await api.get(`/tickets/ticketNo/generateTicketNo`).then((res) => res.data).catch((err) => err);

export const createTicketAsync = async (payload) => await api.post(`/tickets`, payload).then((res) => res.data).catch((err) => err);

export const updateTicketAsync = async (payload, id) => await api.put(`/tickets/${id}`, payload).then((res) => res.data).catch((err) => err);

export const fetchTicketDetailAsync = async (id) => await api.get(`/tickets/${id}`).then((res) => res.data).catch((err) => err);

export const deleteTicketAsync = async (id) => await api.delete(`/tickets/${id}`).then((res) => res.data).catch((err) => err);
