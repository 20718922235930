import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  ADD_NEW_SUPPLIER,
  ADD_NEW_SUPPLIER_FAILURE,
  ADD_NEW_SUPPLIER_SUCCESS,
  SUPPLIER_DELETE,
  SUPPLIER_DELETE_FAILURE,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DETAIL,
  SUPPLIER_DETAIL_FAILURE,
  SUPPLIER_DETAIL_SUCCESS,
  SUPPLIER_LIST,
  SUPPLIER_LIST_BY_ROLE,
  SUPPLIER_LIST_BY_ROLE_FAILURE,
  SUPPLIER_LIST_BY_ROLE_SUCCESS,
  SUPPLIER_LIST_FAILURE,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_NEW,
  SUPPLIER_NEW_FAILURE,
  SUPPLIER_NEW_SUCCESS,
  SUPPLIER_UPDATE,
  SUPPLIER_UPDATE_FAILURE,
  SUPPLIER_UPDATE_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createSupplierAsync,
  deleteSupplierAsync,
  fetchSupplierAsync,
  fetchSupplierByRoleAsync,
  fetchSupplierDetailAsync,
  updateSupplierAsync,
} from "../../../services/supplier.service.js";
import { uploadFile } from "../../common/saga.js";

export function* watchFetchSupplier() {
  yield takeLatest(SUPPLIER_LIST, fetchSupplierList);
}

export function* watchFetchSupplierByRole() {
  yield takeLatest(SUPPLIER_LIST_BY_ROLE, fetchSupplierListByRole);
}

export function* watchCreateSupplier() {
  yield takeLatest(SUPPLIER_NEW, createNewSupplier);
}

export function* watchAddSupplier() {
  yield takeLatest(ADD_NEW_SUPPLIER, addNewSupplier);
}

export function* watchUpdateSupplier() {
  yield takeLatest(SUPPLIER_UPDATE, updateSupplier);
}

export function* watchGetDetailSupplier() {
  yield takeLatest(SUPPLIER_DETAIL, fetchSupplierDetail);
}

export function* watchDeleteSupplier() {
  yield takeLatest(SUPPLIER_DELETE, deleteSupplier);
}

function* fetchSupplierList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchSupplierAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SUPPLIER_LIST_SUCCESS,
        payload: { suppliers: response.data },
      });
    } else {
      yield put({ type: SUPPLIER_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUPPLIER_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchSupplierListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchSupplierByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SUPPLIER_LIST_BY_ROLE_SUCCESS,
        payload: { suppliersByRole: response.data },
      });
    } else {
      yield put({ type: SUPPLIER_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUPPLIER_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewSupplier({ payload }) {
  const { data, imageFile } = payload;

  let supplierName = data.supplierName.trim().replace(" ", "_");
  let responseImageFileUpload = null;

  if (imageFile.file) {
    const logoFormData = new FormData();
    logoFormData.append("files", imageFile.file);
    responseImageFileUpload = yield call(
      uploadFile,
      supplierName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (responseImageFileUpload !== null) {
    // data.logo = responseImageFileUpload.data.id;
    data.image = responseImageFileUpload.id;
  }

  try {
    const response = yield call(createSupplierAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SUPPLIER_NEW_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SUPPLIER_NEW_FAILURE });
      yield put(showMessage("error", typeof response.message != "string" ? response.message?.toString() : response.message));
    }
  } catch (error) {
    yield put({ type: SUPPLIER_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* addNewSupplier({ payload }) {
  const { data, imageFile } = payload;

  let supplierName = data.supplierName.trim().replace(" ", "_");
  let responseImageFileUpload = null;

  if (imageFile.file) {
    const logoFormData = new FormData();
    logoFormData.append("files", imageFile.file);
    responseImageFileUpload = yield call(
      uploadFile,
      supplierName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (responseImageFileUpload !== null) {
    // data.logo = responseImageFileUpload.data.id;
    data.image = responseImageFileUpload.id;
  }

  try {
    const response = yield call(createSupplierAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: ADD_NEW_SUPPLIER_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ADD_NEW_SUPPLIER_FAILURE });
      yield put(showMessage("error", typeof response.message != "string" ? response.message?.toString() : response.message));
    }
  } catch (error) {
    yield put({ type: ADD_NEW_SUPPLIER_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateSupplier({ payload }) {
  const { data, id, imageFile } = payload;

  let supplierName = data.supplierName.trim().replace(" ", "_");
  let responseImageFileUpload = null;

  if (imageFile.file) {
    const logoFormData = new FormData();
    logoFormData.append("files", imageFile.file);
    responseImageFileUpload = yield call(
      uploadFile,
      supplierName,
      "logo",
      "",
      "",
      logoFormData
    );
  }

  if (responseImageFileUpload !== null) {
    // data.logo = responseImageFileUpload.data.id;
    data.image = responseImageFileUpload.id;
  }
  
  try {
    const response = yield call(updateSupplierAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SUPPLIER_UPDATE_SUCCESS,
        payload: { supplier: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SUPPLIER_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUPPLIER_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchSupplierDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchSupplierDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SUPPLIER_DETAIL_SUCCESS,
        payload: { supplier: response.data },
      });
    } else {
      yield put({ type: SUPPLIER_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUPPLIER_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteSupplier({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteSupplierAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SUPPLIER_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SUPPLIER_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUPPLIER_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchSupplier),
    fork(watchFetchSupplierByRole),
    fork(watchCreateSupplier),
    fork(watchAddSupplier),
    fork(watchUpdateSupplier),
    fork(watchGetDetailSupplier),
    fork(watchDeleteSupplier),
  ]);
}
