import {
  collection,
  doc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

// export const fetchMasterListAsync = async (slug) => {

//   try {
//     // const querySnapshot = await api.get(`/${slug}?createdBy=${id}`)
//     const querySnapshot = await api.get(`/${slug}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//     } else {
//       response = {
//         status: 400,
//         message: t("message_master_option_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

// };

// export const fetchMasterListByRoleAsync = async (slug) => {

//   try {
//     // const querySnapshot = await api.get(`/${slug}?createdBy=${id}`)
//     const querySnapshot = await api.get(`/${slug}/assign`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//     } else {
//       response = {
//         status: 400,
//         message: t("message_master_option_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

// };

// export const createMasterOptionAsync = async (masterId, masterName, data) => {

//   try {
//     const querySnapshot = await api.post(`/${masterName}`, data)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_master_option_create_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: querySnapshot?.data?.message,
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // console.log(masterName);
//   // const masterOptionRef = await api.post(`/${masterName}`, data)
//   // console.log(masterOptionRef);
//   // response = {
//   //   status: 200,
//   //   message: t("message_master_option_create_successful"),
//   // };
//   // return

//   //   let name = data.name.trim().toLowerCase();
//   //   const q = query(
//   //     masterOptionRef,
//   //     where("createdBy", "==", data?.createdBy),
//   //     where("name", "==", name)
//   //   );

//   //   const querySnapshot = await getDocs(q);
//   //   if (querySnapshot.empty) {
//   //     await setDoc(doc(database, `${masterName}`, data._id), {
//   //       ...data,
//   //       createdAt: new Date(),
//   //     });
//   //     response = {
//   //       status: 200,
//   //       message: t("message_master_option_create_successful"),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 401,
//   //       message: t("message_master_option_exists"),
//   //     };
//   //   }
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const updateMasterOptionAsync = async (slug, payload, id) => {

//   try {
//     const querySnapshot = await api.patch(`/${slug}/${id}`, payload)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_master_option_update_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_master_option_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await updateDoc(doc(database, String(slug), id), {
//   //     ...payload,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_master_option_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

export const fetchMasterListAsync = async (slug) =>
  await api
    .get(`/${slug}`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchMasterListByRoleAsync = async (slug) =>
  await api
    .get(`/${slug}/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const createMasterOptionAsync = async (masterId, masterName, data) =>
  await api
    .post(`/${masterName}`, data)
    .then((res) => res.data)
    .catch((err) => err);

export const updateMasterOptionAsync = async (slug, payload, id) =>
  await api
    .put(`/${slug}/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);
