import {
  ADD_NEW_PRODUCT,
  ADD_NEW_PRODUCT_SUCCESS,
  PRODUCT_DELETE_SUCCESS,
  PRODUCT_DETAIL,
  PRODUCT_DETAIL_SUCCESS,
  PRODUCT_LIST,
  PRODUCT_LIST_BY_ROLE,
  PRODUCT_LIST_BY_ROLE_FAILURE,
  PRODUCT_LIST_BY_ROLE_SUCCESS,
  PRODUCT_LIST_FAILURE,
  PRODUCT_LIST_SUCCESS,
  PRODUCT_NEW,
  PRODUCT_NEW_SUCCESS,
  PRODUCT_STATUS_UPDATE,
  PRODUCT_STATUS_UPDATE_SUCCESS,
  PRODUCT_UPDATE,
  PRODUCT_UPDATE_SUCCESS,
} from "../../types.js";

const initialState = {
  products: [],
  productDetail: null,
  createOrUpdated: false,

  // Product by role
  productsByRole: [],

  productAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PRODUCT_LIST:
    case PRODUCT_NEW:
    case PRODUCT_UPDATE:
    case PRODUCT_STATUS_UPDATE:
    case PRODUCT_DETAIL:
      return {
        ...state,
        createOrUpdated: false,
        productDetail: null,
      };

      case ADD_NEW_PRODUCT:
        return {
          ...state,
          productAdded: false,
          newProductData: null,
        };
  
      case ADD_NEW_PRODUCT_SUCCESS: {
        const { data } = action.payload;
        return {
          ...state,
          createOrUpdated: true,
          productAdded: true,
          newProductData: data,
          productsByRole: [...state.productsByRole, data],
        };
      }
      
    case PRODUCT_NEW_SUCCESS:
    case PRODUCT_STATUS_UPDATE_SUCCESS:
    case PRODUCT_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case PRODUCT_DETAIL_SUCCESS: {
      return { ...state, productDetail: payload.product };
    }

    case PRODUCT_LIST_SUCCESS:
      return { ...state, products: payload.products };
    case PRODUCT_LIST_FAILURE:
      return { ...state };

    case PRODUCT_LIST_BY_ROLE:
      return { ...state, productsByRole: [] };
    case PRODUCT_LIST_BY_ROLE_SUCCESS:
      return { ...state, productsByRole: payload.productsByRole };
    case PRODUCT_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case PRODUCT_DELETE_SUCCESS:
      return {
        ...state,
        products: state.products.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
