import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  createRolesAsync,
  deleteRolesAsync,
  fetchRolesDetailAsync,
  fetchRolesListAsync,
  fetchRolesListByRoleAsync,
  updateRolesAsync,
} from "../../services/roles.service";
import { hideLoader, showMessage } from "../lem/action";
import {
  ROLES_DELETE,
  ROLES_DELETE_FAILURE,
  ROLES_DELETE_SUCCESS,
  ROLES_DETAIL,
  ROLES_DETAIL_FAILURE,
  ROLES_DETAIL_SUCCESS,
  ROLES_LIST,
  ROLES_LIST_BY_ROLE,
  ROLES_LIST_BY_ROLE_FAILURE,
  ROLES_LIST_BY_ROLE_SUCCESS,
  ROLES_LIST_FAILURE,
  ROLES_LIST_SUCCESS,
  ROLES_NEW,
  ROLES_NEW_FAILURE,
  ROLES_NEW_SUCCESS,
  ROLES_UPDATE,
  ROLES_UPDATE_FAILURE,
  ROLES_UPDATE_SUCCESS,
} from "../types";

export function* watchFetchROLESList() {
  yield takeLatest(ROLES_LIST, fetchROLESList);
}

export function* watchFetchROLESListByRole() {
  yield takeLatest(ROLES_LIST_BY_ROLE, fetchROLESListByRole);
}

export function* watchCreateROLES() {
  yield takeLatest(ROLES_NEW, createNewROLES);
}

export function* watchUpdateROLES() {
  yield takeLatest(ROLES_UPDATE, updateROLES);
}

export function* watchGetDetailROLES() {
  yield takeLatest(ROLES_DETAIL, fetchROLESDetail);
}

export function* watchDeleteROLES() {
  yield takeLatest(ROLES_DELETE, deleteROLES);
}

function* fetchROLESList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchRolesListAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: ROLES_LIST_SUCCESS,
        payload: { roles: response.data },
      });
    } else {
      yield put({ type: ROLES_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ROLES_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchROLESListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchRolesListByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: ROLES_LIST_BY_ROLE_SUCCESS,
        payload: { rolesByRole: response.data },
      });
      // yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ROLES_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ROLES_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewROLES({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createRolesAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: ROLES_NEW_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ROLES_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ROLES_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateROLES({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateRolesAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: ROLES_UPDATE_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ROLES_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ROLES_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchROLESDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchRolesDetailAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: ROLES_DETAIL_SUCCESS,
        payload: { roles: response.data },
      });
    } else {
      yield put({ type: ROLES_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ROLES_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteROLES({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteRolesAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: ROLES_DELETE_SUCCESS,
        payload: { id: id },
      });
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ROLES_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ROLES_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchROLESList),
    fork(watchFetchROLESListByRole),
    fork(watchCreateROLES),
    fork(watchUpdateROLES),
    fork(watchGetDetailROLES),
    fork(watchDeleteROLES),
  ]);
}
