import {
  collection,
  deleteDoc,
  doc,
  endAt,
  getDoc,
  getDocs,
  orderBy,
  query,
  setDoc,
  startAt,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../../config/firebase-config";
import { generateMessage } from "../firebaseMessage";
import { generateID } from "../idGenerator.service";
import api from "../api";

let response = {
  status: null,
  data: null,
  message: "",
};

// export const fetchInvoiceAsync = async (id) => {

//   try {
//     // const querySnapshot = await api.get(`/invoices?createdBy=${id}`)
//     const querySnapshot = await api.get(`/invoices`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//     } else {
//       response = {
//         status: 400,
//         message: t("message_invoices_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const invoiceRef = collection(database, "invoices");

//   //   const q = query(invoiceRef, where("createdBy", "==", String(id)));

//   //   const querySnapshot = await getDocs(q);

//   //   if (!querySnapshot.empty) {
//   //     const dataArr = [];
//   //     querySnapshot.forEach((doc) => {
//   //       dataArr.push(doc.data());
//   //     });
//   //     response = {
//   //       status: 200,
//   //       data: dataArr,
//   //       message: t("message_invoices_get_success"),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 400,
//   //       message: t("message_invoices_not_found"),
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const fetchInvoicesForReportAsync = async (id, startDate, endDate) => {

//   console.log("startDate *", startDate)

//   try {
//   // const querySnapshot = await api.get(`/invoices/report?createdBy=${id}&startDate=${startDate}&endDate=${endDate}`)
//   const querySnapshot = await api.get(`/invoices/report?startDate=${startDate}&endDate=${endDate}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//     } else {
//       response = {
//         status: 400,
//         message: t("message_invoices_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const invoiceRef = collection(database, "invoices");

//   //   const q = query(invoiceRef, where("createdBy", "==", String(id)), orderBy("createdAt", "desc"), where("createdAt", ">=", startDate), where("createdAt", "<=", endDate));

//   //   const querySnapshot = await getDocs(q);

//   //   if (!querySnapshot.empty) {
//   //     const dataArr = [];
//   //     querySnapshot.forEach((doc) => {
//   //       dataArr.push(doc.data());
//   //     });
//   //     response = {
//   //       status: 200,
//   //       data: dataArr,
//   //       message: t("message_invoices_get_success"),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 400,
//   //       message: t("message_invoices_not_found"),
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const generateInvoiceNoAsync = async (uid) => {
//   const response = await generateID(uid, "Invoice");
//   return response;
// };

// export const createInvoiceAsync = async (payload) => {

//   const newData = payload
//   newData.customerInfo = newData?.customerInfo?.id
//   newData.gstInfo = newData?.gstInfo.id;
//   newData.paymentInfo = newData?.paymentInfo.id;

//   try {
//     const querySnapshot = await api.post(`/invoices`, newData)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_invoice_new_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: querySnapshot?.data?.message,
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await setDoc(doc(database, "invoices", Number(payload._id).toString()), {
//   //     ...payload,
//   //     createdAt: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_invoice_new_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const updateInvoiceAsync = async (payload, id) => {

//   const newData = payload
//   newData.customerInfo = newData?.customerInfo?.id
//   newData.gstInfo = newData?.gstInfo.id;
//   newData.paymentInfo = newData?.paymentInfo.id;
//   delete newData["_id"]

//   console.log("newData is", newData);

//   try {
//     const querySnapshot = await api.put(`/invoices/${id}`, newData)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_invoice_update_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_invoices_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await setDoc(doc(database, "invoices", String(id)), {
//   //     ...payload,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_invoice_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const updatePaymentInfoAsync = async (payload, id) => {

//   delete payload["paymentInfo"]

//   try {
//     const querySnapshot = await api.put(`/invoices/${id}`, payload)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_invoices_updated_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_invoices_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await updateDoc(doc(database, "invoices", String(id)), {
//   //     ...payload,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_invoice_payment_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const cancelInvoiceAsync = async (payload, id) => {

//   try {
//     const querySnapshot = await api.put(`/invoices/${id}`, payload)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_invoice_cancel_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_invoices_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await updateDoc(doc(database, "invoices", String(id)), {
//   //     isCancelled: true,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_invoice_cancel_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const restoreInvoiceAsync = async (payload, id) => {

//   try {
//     const querySnapshot = await api.put(`/invoices/${id}`, payload)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_invoice_restore_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_invoices_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await updateDoc(doc(database, "invoices", String(id)), {
//   //     isCancelled: false,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_invoice_restore_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const fetchInvoiceDetailAsync = async (id) => {

//   try {
//     const querySnapshot = await api.get(`/invoices/${id}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       }; console.log("payload is", querySnapshot.data)
//     } else {
//       response = {
//         status: 400,
//         message: t("message_invoices_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const invoiceRef = collection(database, "invoices");

//   //   const docRef = doc(database, "invoices", id);
//   //   const docSnap = await getDoc(docRef);

//   //   if (docSnap.exists()) {
//   //     response = {
//   //       status: 200,
//   //       data: docSnap.data(),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 401,
//   //       data: null,
//   //       message: "invoice details not found",
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const deleteInvoiceAsync = async (id) => {

//   try {
//     const querySnapshot = await api.delete(`/invoices/${id}`)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("invoices_deleted_succeful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_invoices_not_found"),
//       };
//     }
//     return response;
//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 400,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const docRef = doc(database, "invoices", String(id));
//   //   await deleteDoc(docRef);

//   //   response = {
//   //     status: 200,
//   //     message: "invoice deleted succesfully...",
//   //   };

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 400,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

export const fetchInvoiceAsync = async (id) =>
  await api
    .get(`/invoices`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchInvoiceByRoleAsync = async (id) =>
  await api
    .get(`/invoices/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchInvoicesForReportAsync = async (id, startDate, endDate) =>
  await api
    .get(`/invoices/report?startDate=${startDate}&endDate=${endDate}`)
    .then((res) => res.data)
    .catch((err) => err);

// export const generateInvoiceNoAsync = async (uid) => {
//   const response = await generateID(uid, "Invoice");
//   return response;
// };
export const generateInvoiceNoAsync = async (uid) => await api.get(`/invoices/invoiceNo/generateInvoiceNo`).then((res) => res.data).catch((err) => err);

export const createInvoiceAsync = async (payload) =>
  await api
    .post(`/invoices`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateInvoiceAsync = async (payload, id) =>
  await api
    .put(`/invoices/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updatePaymentInfoAsync = async (payload, id) =>
  await api
    .put(`/invoices/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const cancelInvoiceAsync = async (payload, id) =>
  await api
    .put(`/invoices/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const restoreInvoiceAsync = async (payload, id) =>
  await api
    .put(`/invoices/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchInvoiceDetailAsync = async (id) =>
  await api
    .get(`/invoices/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteInvoiceAsync = async (id) =>
  await api
    .delete(`/invoices/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
