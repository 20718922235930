import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchSupplierAsync = async (uid) =>
  await api
    .get(`/suppliers`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchSupplierByRoleAsync = async (uid) =>
  await api
    .get(`/suppliers/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const createSupplierAsync = async (payload) =>
  await api
    .post(`/suppliers`, payload)
    .then((res) => res.data)
    .catch((err) => err.data);

export const updateSupplierAsync = async (payload, id) =>
  await api
    .put(`/suppliers/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchSupplierDetailAsync = async (id) =>
  await api
    .get(`/suppliers/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteSupplierAsync = async (id) =>
  await api
    .delete(`/suppliers/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
