import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  HOLIDAYS_DELETE,
  HOLIDAYS_DELETE_FAILURE,
  HOLIDAYS_DELETE_SUCCESS,
  HOLIDAYS_DETAIL,
  HOLIDAYS_DETAIL_FAILURE,
  HOLIDAYS_DETAIL_SUCCESS,
  HOLIDAYS_LIST,
  HOLIDAYS_LIST_BY_ROLE,
  HOLIDAYS_LIST_BY_ROLE_FAILURE,
  HOLIDAYS_LIST_BY_ROLE_SUCCESS,
  HOLIDAYS_LIST_FAILURE,
  HOLIDAYS_LIST_SUCCESS,
  HOLIDAYS_NEW,
  HOLIDAYS_NEW_FAILURE,
  HOLIDAYS_NEW_SUCCESS,
  HOLIDAYS_UPDATE,
  HOLIDAYS_UPDATE_FAILURE,
  HOLIDAYS_UPDATE_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action.js";
import { createHolidayAsync, deleteHolidayAsync, fetchHolidayAsync, fetchHolidayByRoleAsync, fetchHolidayDetailAsync, updateHolidayAsync } from "../../services/holidays.service.js";


export function* watchFetchHoliday() {
  yield takeLatest(HOLIDAYS_LIST, fetchHoliday);
}

export function* watchFetchHolidayByRole() {
  yield takeLatest(HOLIDAYS_LIST_BY_ROLE, fetchHolidayByRole);
}

export function* watchCreateHoliday() {
  yield takeLatest(HOLIDAYS_NEW, createNewHoliday);
}

export function* watchUpdateHoliday() {
  yield takeLatest(HOLIDAYS_UPDATE, updateHoliday);
}

export function* watchGetDetailHoliday() {
  yield takeLatest(HOLIDAYS_DETAIL, fetchHolidayDetail);
}

export function* watchDeleteHoliday() {
  yield takeLatest(HOLIDAYS_DELETE, deleteHoliday);
}

function* fetchHoliday({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchHolidayAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: HOLIDAYS_LIST_SUCCESS,
        payload: { holidays: response.data },
      });
    } else {
      yield put({ type: HOLIDAYS_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: HOLIDAYS_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchHolidayByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchHolidayByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: HOLIDAYS_LIST_BY_ROLE_SUCCESS,
        payload: { holidaysByRole: response.data },
      });
    } else {
      yield put({ type: HOLIDAYS_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: HOLIDAYS_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewHoliday({ payload }) {
  console.log(payload)
  const { data } = payload;
  try {
    const response = yield call(createHolidayAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: HOLIDAYS_NEW_SUCCESS,
      });
    } else {
      yield put({ type: HOLIDAYS_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: HOLIDAYS_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateHoliday({ payload }) {
  const { data, id } = payload;
  console.log("data", data, "id", id)
  try {
    const response = yield call(updateHolidayAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: HOLIDAYS_UPDATE_SUCCESS,
        payload: { holiday: response.data },
      });
    } else {
      yield put({ type: HOLIDAYS_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: HOLIDAYS_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchHolidayDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchHolidayDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: HOLIDAYS_DETAIL_SUCCESS,
        payload: { holidayDetails: response.data },
      });
    } else {
      yield put({ type: HOLIDAYS_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: HOLIDAYS_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteHoliday({ payload }) {
  const { id,recruitId } = payload;
  try {
    const response = yield call(deleteHolidayAsync, id,recruitId);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: HOLIDAYS_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: HOLIDAYS_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: HOLIDAYS_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchHoliday),
    fork(watchFetchHolidayByRole),
    fork(watchCreateHoliday),
    fork(watchUpdateHoliday),
    fork(watchGetDetailHoliday),
    fork(watchDeleteHoliday),
  ]);
}
