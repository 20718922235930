import {
  all,
  call,
  put,
  fork,
  takeLatest,
  takeEvery,
} from "redux-saga/effects";
import {
  ADD_NEW_MASTER,
  ADD_NEW_MASTER_FAILURE,
  ADD_NEW_MASTER_SUCCESS,
  CREATE_MASTER,
  CREATE_MASTER_FAILURE,
  CREATE_MASTER_SUCCESS,
  MASTER_LIST,
  MASTER_LIST_BY_ROLE,
  MASTER_LIST_BY_ROLE_FAILURE,
  MASTER_LIST_BY_ROLE_SUCCESS,
  MASTER_LIST_FAILURE,
  MASTER_LIST_SUCCESS,
  UPDATE_MASTER,
  UPDATE_MASTER_FAILURE,
  UPDATE_MASTER_STATUS,
  UPDATE_MASTER_STATUS_FAILURE,
  UPDATE_MASTER_STATUS_SUCCESS,
  UPDATE_MASTER_SUCCESS,
} from "../types";

import { hideLoader, showMessage } from "../lem/action";
import {
  createMasterOptionAsync,
  fetchMasterListAsync,
  fetchMasterListByRoleAsync,
  updateMasterOptionAsync,
} from "../../services/masters.service";

export function* watchFetchMasterList() {
  yield takeEvery(MASTER_LIST, fetchMasterList);
}

export function* watchFetchMasterListByRole() {
  yield takeEvery(MASTER_LIST_BY_ROLE, fetchMasterListByRole);
}

export function* watchCreateMasterOption() {
  yield takeLatest(CREATE_MASTER, createMasterOptions);
}

export function* watchAddNewMasterOption() {
  yield takeLatest(ADD_NEW_MASTER, AddNewMasterOptions);
}

export function* watchUpdateMasterOption() {
  yield takeLatest(UPDATE_MASTER, updateMasterOptions);
}

export function* watchUpdateMasterStatus() {
  yield takeLatest(UPDATE_MASTER_STATUS, updateMasterStatus);
}

function* fetchMasterList({ payload }) {
  // const { slug, createdBy } = payload;
  const { slug } = payload;
  try {
    const response = yield call(fetchMasterListAsync, slug);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: MASTER_LIST_SUCCESS,
        payload: { slug: slug, data: response.data },
      });
    } else {
      yield put({ type: MASTER_LIST_FAILURE });
      // yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: MASTER_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchMasterListByRole({ payload }) {
  // const { slug, createdBy } = payload;
  const { slug } = payload;
  try {
    const response = yield call(fetchMasterListByRoleAsync, slug);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: MASTER_LIST_BY_ROLE_SUCCESS,
        payload: { slug: slug, data: response.data },
      });
    } else {
      yield put({ type: MASTER_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: MASTER_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createMasterOptions({ payload }) {
  const { masterId, slug, data } = payload;
  try {
    const response = yield call(
      createMasterOptionAsync,
      masterId,
      slug,
      data
    );
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CREATE_MASTER_SUCCESS,
        payload: { masterId: masterId, data: response.data, slug: slug },
      });
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: CREATE_MASTER_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CREATE_MASTER_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* AddNewMasterOptions({ payload }) {
  const { masterId, slug, data } = payload;
  try {
    const response = yield call(
      createMasterOptionAsync,
      masterId,
      slug,
      data
    );
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: ADD_NEW_MASTER_SUCCESS,
        payload: { masterId: masterId, data: response.data, slug: slug },
      });
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ADD_NEW_MASTER_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADD_NEW_MASTER_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateMasterOptions({ payload }) {
  const { slug, data, id } = payload;
  try {
    const response = yield call(updateMasterOptionAsync, slug, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: UPDATE_MASTER_SUCCESS,
        payload: { data: response.data, id: id },
      });
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: UPDATE_MASTER_FAILURE });
      yield put(showMessage("error", response.data.message));
    }
  } catch (error) {
    yield put({ type: UPDATE_MASTER_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateMasterStatus({ payload }) {
  const { slug, data, id } = payload;
  try {
    const response = yield call(updateMasterOptionAsync,slug, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: UPDATE_MASTER_STATUS_SUCCESS,
        payload: { slug: slug, data: response.data, id: id },
      });
      yield put(showMessage("success", response.message));
    } else {
      yield put({ type: UPDATE_MASTER_STATUS_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: UPDATE_MASTER_STATUS_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchMasterList),
    fork(watchFetchMasterListByRole),
    fork(watchCreateMasterOption),
    fork(watchAddNewMasterOption),
    fork(watchUpdateMasterOption),
    fork(watchUpdateMasterStatus),
  ]);
}
