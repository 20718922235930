import {
  ADD_NEW_CODE_SETUP,
  ADD_NEW_CODE_SETUP_SUCCESS,
  CODE_SETUP_DELETE,
  CODE_SETUP_DELETE_SUCCESS,
  CODE_SETUP_DETAIL,
  CODE_SETUP_DETAIL_SUCCESS,
  CODE_SETUP_LIST,
  CODE_SETUP_LIST_BY_ROLE,
  CODE_SETUP_LIST_BY_ROLE_FAILURE,
  CODE_SETUP_LIST_BY_ROLE_SUCCESS,
  CODE_SETUP_LIST_SUCCESS,
  CODE_SETUP_NEW,
  CODE_SETUP_NEW_SUCCESS,
  CODE_SETUP_UPDATE,
  CODE_SETUP_UPDATE_SUCCESS,
} from "../types";

const initialState = {
  codeSetups: [],
  codeSetupDetail: null,
  createOrUpdated: false,

  // codeSetups by role
  codeSetupsByRole: [],

  codeSetupAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CODE_SETUP_LIST:
    case CODE_SETUP_NEW:
    case CODE_SETUP_DETAIL:
    case CODE_SETUP_UPDATE:
    case CODE_SETUP_DELETE:
      return { ...state, createOrUpdated: false, codeSetupDetail: null };

    case CODE_SETUP_LIST_SUCCESS:
      return { ...state, codeSetups: payload.codeSetups };

    case CODE_SETUP_LIST_BY_ROLE:
      return { ...state, codeSetupsByRole: [] };
    case CODE_SETUP_LIST_BY_ROLE_SUCCESS:
      return { ...state, codeSetupsByRole: payload.codeSetupsByRole };
    case CODE_SETUP_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case CODE_SETUP_DETAIL_SUCCESS:
      return { ...state, codeSetupDetail: payload.codeSetup };

    case CODE_SETUP_DELETE_SUCCESS: {
      const items = state.codeSetups.filter((item) => item.id !== payload.id);
      return { ...state, codeSetups: items };
    }

    case CODE_SETUP_NEW_SUCCESS:
    case CODE_SETUP_UPDATE_SUCCESS:
      return { ...state, createOrUpdated: true };

      case ADD_NEW_CODE_SETUP:
        return {
          ...state,
          codeSetupAdded: false,
          newCodeSetupData: null,
        };
  
      case ADD_NEW_CODE_SETUP_SUCCESS: {
        const { data } = action.payload;
        return {
          ...state,
          createOrUpdated: true,
          codeSetupAdded: true,
          newCodeSetupData: data,
          codeSetupsByRole: [...state.codeSetupsByRole, data],
        };
      }

    default:
      return { ...state };
  }
}
