import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";
import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchProjectAsync = async (uid) => await api.get(`projects`).then((res) => res.data).catch((err) => err);

export const fetchProjectByRoleAsync = async (uid) => await api.get(`projects/assign`).then((res) => res.data).catch((err) => err);

// export const generateProjectNoAsync = async (uid) => {
//   const response = await generateID(uid, "Project");
//   return response;
// };

export const generateProjectNoAsync = async (uid) => await api.get(`/projects/projectNo/generateProjectNo`).then((res) => res.data).catch((err) => err);

export const createProjectAsync = async (payload) => await api.post(`/projects`, payload).then((res) => res.data).catch((err) => err);

export const updateProjectAsync = async (payload, id) => await api.put(`/projects/${id}`, payload).then((res) => res.data).catch((err) => err);

export const fetchProjectDetailAsync = async (id) => await api.get(`/projects/${id}`).then((res) => res.data).catch((err) => err);

export const deleteProjectAsync = async (id) => await api.delete(`/projects/${id}`).then((res) => res.data).catch((err) => err);
