import api from "../api";

export const fetchSalesAsync = async (uid) => await api.get(`/sales`).then((res) => res.data).catch((err) => err);

export const fetchSalesOrderAsync = async (uid) => await api.get(`/reports/stock`).then((res) => res.data).catch((err) => err);

export const fetchSalesByRoleAsync = async (uid) => await api.get(`/sales/assign`).then((res) => res.data).catch((err) => err);

export const createSalesAsync = async (payload) => await api.post(`/sales`, payload).then((res) => res.data).catch((err) => err);

export const updateSalesAsync = async (payload, id) => await api.put(`/sales/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updateSalesStatusAsync = async (payload, id) => await api.put(`/sales/status/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updateSalesPaymentInfoAsync = async (payload, id) =>
  await api
    .put(`/sales/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchSalesDetailAsync = async (id) => await api.get(`/sales/${id}`).then((res) => res.data).catch((err) => err);

export const deleteSalesAsync = async (id) => await api.delete(`/sales/${id}`).then((res) => res.data).catch((err) => err);