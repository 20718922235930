import {
  MASTER_LIST_DELETE,
  MASTER_LIST_DELETE_SUCCESS,
  MASTER_LIST_DETAIL,
  MASTER_LIST_DETAIL_SUCCESS,
  MASTER_LIST_ALL,
  MASTER_LIST_ALL_SUCCESS,
  MASTER_LIST_NEW,
  MASTER_LIST_NEW_SUCCESS,
  MASTER_LIST_UPDATE,
  MASTER_LIST_UPDATE_SUCCESS,
} from "../types";

const initialState = {
  masterLists: [],
  masterListDetail: null,
  createOrUpdated: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case MASTER_LIST_ALL:
    case MASTER_LIST_NEW:
    case MASTER_LIST_DETAIL:
    case MASTER_LIST_UPDATE:
    case MASTER_LIST_DELETE:
      return { ...state, createOrUpdated: false, masterListDetail: null };

    case MASTER_LIST_ALL_SUCCESS:
      return { ...state, masterLists: payload.masterLists };

    case MASTER_LIST_DETAIL_SUCCESS:
      return { ...state, masterListDetail: payload.MasterList };

    case MASTER_LIST_DELETE_SUCCESS: {
      const items = state.masterLists.filter((item) => item.id !== payload.id);
      return { ...state, masterLists: items };
    }

    case MASTER_LIST_NEW_SUCCESS:
    case MASTER_LIST_UPDATE_SUCCESS:
      return { ...state, createOrUpdated: true };

    default:
      return { ...state };
  }
}
