import {
  EMPLOY_ONBOARDING_DELETE_SUCCESS,
  EMPLOY_ONBOARDING_DETAIL,
  EMPLOY_ONBOARDING_DETAIL_SUCCESS,
  EMPLOY_ONBOARDING_LIST,
  EMPLOY_ONBOARDING_LIST_BY_ROLE,
  EMPLOY_ONBOARDING_LIST_BY_ROLE_FAILURE,
  EMPLOY_ONBOARDING_LIST_BY_ROLE_SUCCESS,
  EMPLOY_ONBOARDING_LIST_FAILURE,
  EMPLOY_ONBOARDING_LIST_SUCCESS,
  EMPLOY_ONBOARDING_NEW,
  EMPLOY_ONBOARDING_NEW_SUCCESS,
  EMPLOY_ONBOARDING_UPDATE,
  EMPLOY_ONBOARDING_UPDATE_SUCCESS,
  GENERATE_EMPLOY_ONBOARDING_ID,
  GENERATE_EMPLOY_ONBOARDING_ID_SUCCESS,
} from "../types.js";

const initialState = {
  employOnboardings: [],
  employeeId: "",
  employOnboardingDetail: null,
  createOrUpdated: false,

  // Employonboardings for role
  employOnboardingsByRole: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case EMPLOY_ONBOARDING_LIST:
    case EMPLOY_ONBOARDING_NEW:
    case EMPLOY_ONBOARDING_UPDATE:
    case EMPLOY_ONBOARDING_DETAIL:
    case GENERATE_EMPLOY_ONBOARDING_ID:
      return {
        ...state,
        createOrUpdated: false,
        employOnboardingDetail: null,
        idIDGenarationChecked: false,
        employeeId: "",
      };

    case EMPLOY_ONBOARDING_NEW_SUCCESS:
    case EMPLOY_ONBOARDING_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case EMPLOY_ONBOARDING_DETAIL_SUCCESS: {
      return { ...state, employOnboardingDetail: payload.employOnboarding };
    }
    case GENERATE_EMPLOY_ONBOARDING_ID_SUCCESS: {
      return { ...state, employeeId: payload.employeeId, idIDGenarationChecked: true };
    }

    case EMPLOY_ONBOARDING_LIST_SUCCESS:
      return { ...state, employOnboardings: payload.employOnboardings };
    case EMPLOY_ONBOARDING_LIST_FAILURE:
      return { ...state };

    case EMPLOY_ONBOARDING_LIST_BY_ROLE:
      return { ...state, employOnboardingsByRole: [] };
    case EMPLOY_ONBOARDING_LIST_BY_ROLE_SUCCESS:
      return { ...state, employOnboardingsByRole: payload.employOnboardingsByRole };
    case EMPLOY_ONBOARDING_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case EMPLOY_ONBOARDING_DELETE_SUCCESS:
      return {
        ...state,
        employOnboardings: state.employOnboardings.filter(
          (item) => item.id == payload.id && !item.isUserCreated ? item.id !== payload.id : true
        ),
      };

    default:
      return { ...state };
  }
}
