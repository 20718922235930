import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  PURCHASE_DELETE,
  PURCHASE_DELETE_FAILURE,
  PURCHASE_DELETE_SUCCESS,
  PURCHASE_DETAIL,
  PURCHASE_DETAIL_FAILURE,
  PURCHASE_DETAIL_SUCCESS,
  PURCHASE_LIST,
  PURCHASE_LIST_BY_ROLE,
  PURCHASE_LIST_BY_ROLE_FAILURE,
  PURCHASE_LIST_BY_ROLE_SUCCESS,
  PURCHASE_LIST_FAILURE,
  PURCHASE_LIST_SUCCESS,
  PURCHASE_NEW,
  PURCHASE_NEW_FAILURE,
  PURCHASE_NEW_SUCCESS,
  PURCHASE_PAYMENT_UPDATE,
  PURCHASE_PAYMENT_UPDATE_FAILURE,
  PURCHASE_PAYMENT_UPDATE_SUCCESS,
  PURCHASE_STATUS_UPDATE,
  PURCHASE_UPDATE,
  PURCHASE_UPDATE_FAILURE,
  PURCHASE_UPDATE_SUCCESS,
  STOCK_REPORT_LIST,
  STOCK_REPORT_LIST_FAILURE,
  STOCK_REPORT_LIST_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createPurchaseAsync,
  deletePurchaseAsync,
  fetchPurchaseAsync,
  fetchPurchaseByRoleAsync,
  fetchPurchaseDetailAsync,
  fetchPurchaseOrderAsync,
  updatePurchaseAsync,
  updatePurchasePaymentInfoAsync,
  updatePurchaseStatusAsync,
} from "../../../services/purchase/purchase.service.js";

export function* watchFetchPurchase() {
  yield takeLatest(PURCHASE_LIST, fetchPurchaseList);
}

export function* watchFetchPurchaseOrder() {
  yield takeLatest(STOCK_REPORT_LIST, fetchPurchaseOrderList);
}

export function* watchFetchPurchaseByRole() {
  yield takeLatest(PURCHASE_LIST_BY_ROLE, fetchPurchaseListByRole);
}

export function* watchCreatePurchase() {
  yield takeLatest(PURCHASE_NEW, createNewPurchase);
}

export function* watchUpdatePurchase() {
  yield takeLatest(PURCHASE_UPDATE, updatePurchase);
}

export function* watchUpdatePurchaseStatus() {
  yield takeLatest(PURCHASE_STATUS_UPDATE, updatePurchaseStatus);
}

export function* watchUpdatePurchasePaymentInfo() {
  yield takeLatest(PURCHASE_PAYMENT_UPDATE, updatePurchasePaymentInfo);
}

export function* watchGetDetailPurchase() {
  yield takeLatest(PURCHASE_DETAIL, fetchPurchaseDetail);
}

export function* watchDeletePurchase() {
  yield takeLatest(PURCHASE_DELETE, deletePurchase);
}

function* fetchPurchaseOrderList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPurchaseOrderAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: STOCK_REPORT_LIST_SUCCESS,
        payload: { purchaseOrders: response.data },
      });
    } else {
      yield put({ type: STOCK_REPORT_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: STOCK_REPORT_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPurchaseList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPurchaseAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PURCHASE_LIST_SUCCESS,
        payload: { purchases: response.data },
      });
    } else {
      yield put({ type: PURCHASE_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPurchaseListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPurchaseByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PURCHASE_LIST_BY_ROLE_SUCCESS,
        payload: { purchasesByRole: response.data },
      });
    } else {
      yield put({ type: PURCHASE_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewPurchase({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createPurchaseAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_NEW_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePurchase({ payload }) {
  const { data, id } = payload;
  
  try {
    const response = yield call(updatePurchaseAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_UPDATE_SUCCESS,
        payload: { purchase: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePurchaseStatus({ payload }) {
  const { data, id} = payload;
  
  try {
    const response = yield call(updatePurchaseStatusAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_UPDATE_SUCCESS,
        payload: { purchase: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePurchasePaymentInfo({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updatePurchasePaymentInfoAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_PAYMENT_UPDATE_SUCCESS,
        payload: { purchase: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_PAYMENT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_PAYMENT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPurchaseDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchPurchaseDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: PURCHASE_DETAIL_SUCCESS,
        payload: { purchase: response.data },
      });
    } else {
      yield put({ type: PURCHASE_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deletePurchase({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deletePurchaseAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: PURCHASE_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: PURCHASE_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: PURCHASE_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchPurchase),
    fork(watchFetchPurchaseOrder),
    fork(watchFetchPurchaseByRole),
    fork(watchCreatePurchase),
    fork(watchUpdatePurchase),
    fork(watchUpdatePurchaseStatus),
    fork(watchUpdatePurchasePaymentInfo),
    fork(watchGetDetailPurchase),
    fork(watchDeletePurchase),
  ]);
}
