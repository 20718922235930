import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  SALES_DELETE,
  SALES_DELETE_FAILURE,
  SALES_DELETE_SUCCESS,
  SALES_DETAIL,
  SALES_DETAIL_FAILURE,
  SALES_DETAIL_SUCCESS,
  SALES_LIST,
  SALES_LIST_BY_ROLE,
  SALES_LIST_BY_ROLE_FAILURE,
  SALES_LIST_BY_ROLE_SUCCESS,
  SALES_LIST_FAILURE,
  SALES_LIST_SUCCESS,
  SALES_NEW,
  SALES_NEW_FAILURE,
  SALES_NEW_SUCCESS,
  SALES_PAYMENT_UPDATE,
  SALES_PAYMENT_UPDATE_FAILURE,
  SALES_PAYMENT_UPDATE_SUCCESS,
  SALES_STATUS_UPDATE,
  SALES_STOCK_REPORT_LIST_FAILURE,
  SALES_STOCK_REPORT_LIST_SUCCESS,
  SALES_UPDATE,
  SALES_UPDATE_FAILURE,
  SALES_UPDATE_SUCCESS,
  STOCK_REPORT_LIST,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createSalesAsync,
  deleteSalesAsync,
  fetchSalesAsync,
  fetchSalesByRoleAsync,
  fetchSalesDetailAsync,
  fetchSalesOrderAsync,
  updateSalesAsync,
  updateSalesPaymentInfoAsync,
  updateSalesStatusAsync,
} from "../../../services/sales/sales.service.js";

export function* watchFetchSales() {
  yield takeLatest(SALES_LIST, fetchSalesList);
}

export function* watchFetchSalesOrder() {
  yield takeLatest(STOCK_REPORT_LIST, fetchSalesOrderList);
}

export function* watchFetchSalesByRole() {
  yield takeLatest(SALES_LIST_BY_ROLE, fetchSalesListByRole);
}

export function* watchCreateSales() {
  yield takeLatest(SALES_NEW, createNewSales);
}

export function* watchUpdateSales() {
  yield takeLatest(SALES_UPDATE, updateSales);
}

export function* watchUpdateSalesStatus() {
  yield takeLatest(SALES_STATUS_UPDATE, updateSalesStatus);
}

export function* watchUpdateSalesPaymentInfo() {
  yield takeLatest(SALES_PAYMENT_UPDATE, updateSalesPaymentInfo);
}

export function* watchGetDetailSales() {
  yield takeLatest(SALES_DETAIL, fetchSalesDetail);
}

export function* watchDeleteSales() {
  yield takeLatest(SALES_DELETE, deleteSales);
}

function* fetchSalesOrderList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchSalesOrderAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SALES_STOCK_REPORT_LIST_SUCCESS,
        payload: { saleOrders: response.data },
      });
    } else {
      yield put({ type: SALES_STOCK_REPORT_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_STOCK_REPORT_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchSalesList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchSalesAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SALES_LIST_SUCCESS,
        payload: { sales: response.data },
      });
    } else {
      yield put({ type: SALES_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchSalesListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchSalesByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SALES_LIST_BY_ROLE_SUCCESS,
        payload: { salesByRole: response.data },
      });
    } else {
      yield put({ type: SALES_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewSales({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createSalesAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_NEW_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateSales({ payload }) {
  const { data, id } = payload;
  
  try {
    const response = yield call(updateSalesAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_UPDATE_SUCCESS,
        payload: { sale: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateSalesStatus({ payload }) {
  const { data, id} = payload;
  
  try {
    const response = yield call(updateSalesStatusAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_UPDATE_SUCCESS,
        payload: { sale: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateSalesPaymentInfo({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateSalesPaymentInfoAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_PAYMENT_UPDATE_SUCCESS,
        payload: { sale: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_PAYMENT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_PAYMENT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchSalesDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchSalesDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SALES_DETAIL_SUCCESS,
        payload: { sale: response.data },
      });
    } else {
      yield put({ type: SALES_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteSales({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteSalesAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchSales),
    fork(watchFetchSalesOrder),
    fork(watchFetchSalesByRole),
    fork(watchCreateSales),
    fork(watchUpdateSales),
    fork(watchUpdateSalesStatus),
    fork(watchUpdateSalesPaymentInfo),
    fork(watchGetDetailSales),
    fork(watchDeleteSales),
  ]);
}
