import {
  GST_MASTER_DELETE,
  GST_MASTER_DELETE_SUCCESS,
  GST_MASTER_DETAIL,
  GST_MASTER_DETAIL_SUCCESS,
  GST_MASTER_LIST,
  GST_MASTER_LIST_BY_ROLE,
  GST_MASTER_LIST_BY_ROLE_FAILURE,
  GST_MASTER_LIST_BY_ROLE_SUCCESS,
  GST_MASTER_LIST_SUCCESS,
  GST_MASTER_NEW,
  GST_MASTER_NEW_SUCCESS,
  GST_MASTER_UPDATE,
  GST_MASTER_UPDATE_SUCCESS,
} from "../types";

const initialState = {
  GSTMasters: [],
  GSTMasterDetail: null,
  createOrUpdated: false,

  // GSTMasters by role
  GSTMastersByRole: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case GST_MASTER_LIST:
    case GST_MASTER_NEW:
    case GST_MASTER_DETAIL:
    case GST_MASTER_UPDATE:
    case GST_MASTER_DELETE:
      return { ...state, createOrUpdated: false, GSTMasterDetail: null };

    case GST_MASTER_LIST_SUCCESS:
      return { ...state, GSTMasters: payload.GSTMasters };

    case GST_MASTER_LIST_BY_ROLE:
      return { ...state, GSTMastersByRole: [] };
    case GST_MASTER_LIST_BY_ROLE_SUCCESS:
      return { ...state, GSTMastersByRole: payload.GSTMastersByRole };
    case GST_MASTER_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case GST_MASTER_DETAIL_SUCCESS:
      return { ...state, GSTMasterDetail: payload.GSTMaster, GSTMasters: [] };

    case GST_MASTER_DELETE_SUCCESS: {
      const items = state.GSTMasters.filter((item) => item.id !== payload.id);
      return { ...state, GSTMasters: items };
    }

    case GST_MASTER_NEW_SUCCESS:
    case GST_MASTER_UPDATE_SUCCESS:
      return { ...state, createOrUpdated: true };

    default:
      return { ...state };
  }
}
