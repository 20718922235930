import {
  ROLES_DELETE,
  ROLES_DELETE_SUCCESS,
  ROLES_DETAIL,
  ROLES_DETAIL_SUCCESS,
  ROLES_LIST,
  ROLES_LIST_BY_ROLE,
  ROLES_LIST_BY_ROLE_FAILURE,
  ROLES_LIST_BY_ROLE_SUCCESS,
  ROLES_LIST_SUCCESS,
  ROLES_NEW,
  ROLES_NEW_SUCCESS,
  ROLES_UPDATE,
  ROLES_UPDATE_SUCCESS,
} from "../types";

const initialState = {
  roles: [],
  rolesDetail: null,
  createOrUpdated: false,

  // roles by role
  rolesByRole: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ROLES_LIST:
    case ROLES_NEW:
    case ROLES_DETAIL:
    case ROLES_UPDATE:
    case ROLES_DELETE:
      return { ...state, createOrUpdated: false, rolesDetail: null };

    case ROLES_LIST_SUCCESS:
      return { ...state, roles: payload.roles };

    case ROLES_LIST_BY_ROLE:
      return { ...state, rolesByRole: [] };
    case ROLES_LIST_BY_ROLE_SUCCESS:
      return { ...state, rolesByRole: payload.rolesByRole };
    case ROLES_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case ROLES_DETAIL_SUCCESS:
      return { ...state, rolesDetail: payload.roles };

    case ROLES_DELETE_SUCCESS: {
      const items = state.roles.filter((item) => item.id !== payload.id);
      return { ...state, roles: items };
    }

    case ROLES_NEW_SUCCESS:
    case ROLES_UPDATE_SUCCESS:
      return { ...state, createOrUpdated: true };

    default:
      return { ...state };
  }
}
