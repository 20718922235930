import jwtDecode from "jwt-decode";
import {
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  REFRESH_TOKEN,
  FORGOT_PASSWORD_SUCCESS,
  FORGOT_PASSWORD_FAILURE,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAILURE,
  VERIFY_USER_SUCCESS,
  VERIFY_USER_FAILURE,
  LOGOUT_SUCCESS,
  LOGIN_COUNT_RESET,
  RESET_PERMISSION,
  FETCH_USER_PROFILE_FAILURE,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE,
} from "../types";

const user = JSON.parse(sessionStorage.getItem("user"));

// console.log("user is =>", sessionStorage.getItem("user"));

const initialState = user
  ? {
      // isLoggedIn: true,
      // user: user,
      user: {
        ...jwtDecode(user?.accessToken),
        accessToken: user?.accessToken,
        refreshToken: user?.refreshToken,
      },
      // isFirstLogin: jwtDecode(user?.accessToken)?.isFirstLogin,
      isLoggedIn: jwtDecode(user?.accessToken)?.isLoggedIn,
      isFirstLogin: jwtDecode(user?.accessToken)?.isFirstLogin,
      failCount: 0,
      isTokenVerified: null,
      permissions: [],
    }
  : {
      isLoggedIn: false,
      user: null,
      profile: null,
      failCount: 0,
      isTokenVerified: null,
    };

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;
  // console.log("payload is =>>", payload);
  switch (type) {
    case FETCH_USER_PROFILE:
      return { ...state, permissions: [] };
    case REGISTER_SUCCESS: {
      return { isLoggedIn: false, register: true };
    }
    case REGISTER_FAILURE: {
      return { ...state, isLoggedIn: false };
    }
    case LOGIN_SUCCESS: {
      return {
        ...state,
        // isLoggedIn: true,
        // user: payload.user,
        // user: {...jwtDecode(payload.user), token: payload.user},
        user: {
          ...jwtDecode(payload.user?.accessToken),
          accessToken: payload.user?.accessToken,
          refreshToken: payload.user?.refreshToken,
        },
        isLoggedIn: jwtDecode(payload.user?.accessToken)?.isLoggedIn,
        isFirstLogin: jwtDecode(payload.user?.accessToken)?.isFirstLogin,
        failCount: 0,
      };
    }
    case LOGIN_FAILURE: {
      return {
        ...state,
        isLoggedIn: false,
        user: null,
        failCount: state.failCount + 1,
      };
    }
    case LOGIN_COUNT_RESET: {
      return { ...state, failCount: 0 };
    }
    case FETCH_USER_PROFILE_SUCCESS: {
      return {
        ...state,
        profile: payload.user,
        permissions:
          payload.user.role != null
            ? [...payload.user.role?.permissions]
            : ["READ_CUSTOMER"],
      };
    }
    case FETCH_USER_PROFILE_FAILURE: {
      return { ...state };
    }
    case FORGOT_PASSWORD_SUCCESS: {
      return { ...state, isLoggedIn: false, user: null, navigate: true };
    }
    case FORGOT_PASSWORD_FAILURE: {
      return { ...state, isLoggedIn: false, user: null, navigate: false };
    }
    case RESET_PASSWORD_SUCCESS: {
      // return { ...state, isLoggedIn: false, user: null, navigate: true, isFirstLogin: false };
      return { ...state, isFirstLogin: false };
    }
    case RESET_PASSWORD_FAILURE: {
      // return { ...state, isLoggedIn: false, user: null };
      return { ...state };
    }

    case VERIFY_USER_SUCCESS: {
      return {
        ...state,
        isTokenVerified: true,
        isLoggedIn: false,
        user: null,
        navigate: false,
      };
    }
    
    case VERIFY_USER_FAILURE: {
      return {
        ...state,
        isTokenVerified: false,
        isLoggedIn: false,
        user: null,
        navigate: false,
      };
    }

    case LOGOUT_SUCCESS: {
      return { ...state, isLoggedIn: false, user: null };
    }

    case RESET_PERMISSION: {
      return {
        ...state,
        permissions: jwtDecode(state.user.accessToken).role.permissions,
      };
    }

    case REFRESH_TOKEN: {
      return {
        ...state,
        permissions: payload.decoded,
        user: {
          // ...user,
          accessToken: payload.accessToken,
        },
      };
    }
    default: {
      return state;
    }
  }
}
