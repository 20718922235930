import { all, call, put, fork, takeLatest } from "redux-saga/effects";

import {
  MASTER_GET_ALL,
  MASTER_GET_ALL_FAILURE,
  MASTER_GET_ALL_SUCCESS,
} from "../types";
import { getAllMasters, uploadFilesAsync } from "../../services/common.service";
import { hideLoader, showMessage } from "../lem/action";

export function* watchGetAllMasters() {
  yield takeLatest(MASTER_GET_ALL, masterGetAll);
}

function* masterGetAll({ payload }) {
  try {
    const response = yield call(getAllMasters);
    hideLoader();
    if (response.status === 200) {
      yield put({
        type: MASTER_GET_ALL_SUCCESS,
        payload: { masters: response.data },
      });
    } else {
      yield put({ type: MASTER_GET_ALL_FAILURE });
      // yield put(showMessage("error", response.data.error[0]));
    }
  } catch (error) {
    yield put({ type: MASTER_GET_ALL_FAILURE });
    yield put(showMessage("error", error));
  }
}

export function* uploadFile(companyName,documentType, isPublic, prefix, file) {
  let fileUploadResponse = yield call(
    uploadFilesAsync,
    companyName?companyName:`company${Math.random()}`,
    documentType,
    isPublic,
    // `${prefix}.${file.name.split(".").pop()}`
   file
  );
console.log("fileUploadResponse", fileUploadResponse)
  return fileUploadResponse;
}

export default function* rootSaga() {
  yield all([fork(watchGetAllMasters)]);
}
