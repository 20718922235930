import { combineReducers } from "redux";
import authReducer from "./auth/reducer";
import adminReducer from "./admin/reducer";
import lemReducer from "./lem/reducer";
import commonReducer from "./common/reducer";
import masterReducer from "./masters/reducer";
import profileReducer from "./profile/reducer";
import employOnboardingReducer from "./employ_onboarding/reducer";
import employAttendanceReducer from "./employeeAttendance/reducer";
import projectReducer from "./project/reducer";
import ticketReducer from "./ticket/reducer";
import recruitReducer from "./recruit/reducer";
import userManagementReducer from "./user/reducer";
import rolesReducer from "./roles_and_rights/reducer";
import gstMasterReducer from "./gst_master/reducer";
import codeSetupReducer from "./codesetup/reducer";
import holidaysReducer from "./holidays/reducer";
import addMastersReducer from "./addMasters/reducer";

import brandReducer from "./inventory/brand/reducer";
import categoryReducer from "./inventory/category/reducer";
import subCategoryReducer from "./inventory/subcategory/reducer";
import productReducer from "./inventory/product/reducer";

import supplierReducer from "./account/supplier/reducer";
import purchaseReducer from "./purchase/purchases/reducer";
import purchaseReturnReducer from "./purchase/purchaseReturns/reducer";

import salesReducer from "./sales/sales/reducer";
import posReducer from "./sales/pos/reducer";
import poReducer from "./purchase/po/reducer";
import salesReturnReducer from "./sales/salesReturns/reducer";
import customerReducer from "./customer/reducer";
import quotationReducer from "./quotation/reducer";
import invoiceReducer from "./invoice/reducer";

const reducers = combineReducers({
  Auth: authReducer,
  Admin: adminReducer,
  Lem: lemReducer,
  Common: commonReducer,
  Masters: masterReducer,
  Profile: profileReducer,
  Customer: customerReducer,
  Supplier: supplierReducer,
  Quotation: quotationReducer,
  Invoice: invoiceReducer,
  EmployOnboarding:employOnboardingReducer,
  EmployAttendance:employAttendanceReducer,
  Project:projectReducer,
  Ticket:ticketReducer,
  Recruit: recruitReducer,
  User: userManagementReducer,
  Roles: rolesReducer,
  GSTMaster: gstMasterReducer,
  CodeSetup: codeSetupReducer,  
  Holidays: holidaysReducer,
  MasterList: addMastersReducer,
  Brand: brandReducer,
  Category: categoryReducer,
  SubCategory: subCategoryReducer,
  Product: productReducer,
  Purchase: purchaseReducer,
  PurchaseReturn: purchaseReturnReducer,
  Sales: salesReducer,
  POS: posReducer,
  PO: poReducer,
  SalesReturn: salesReturnReducer,
});

export default reducers;