import {
  ADD_NEW_BRAND,
  ADD_NEW_BRAND_SUCCESS,
  BRAND_DELETE_SUCCESS,
  BRAND_DETAIL,
  BRAND_DETAIL_SUCCESS,
  BRAND_LIST,
  BRAND_LIST_BY_ROLE,
  BRAND_LIST_BY_ROLE_FAILURE,
  BRAND_LIST_BY_ROLE_SUCCESS,
  BRAND_LIST_FAILURE,
  BRAND_LIST_SUCCESS,
  BRAND_NEW,
  BRAND_NEW_SUCCESS,
  BRAND_UPDATE,
  BRAND_UPDATE_SUCCESS,
} from "../../types.js";

const initialState = {
  brands: [],
  brandDetail: null,
  createOrUpdated: false,

  // Brands by role
  brandsByRole: [],
  brandAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case BRAND_LIST:
    case BRAND_NEW:
    case BRAND_UPDATE:
    case BRAND_DETAIL:
      return {
        ...state,
        createOrUpdated: false,
        brandDetail: null,
      };

    case BRAND_NEW_SUCCESS:
    case BRAND_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case ADD_NEW_BRAND:
      return {
        ...state,
        brandAdded: false,
        newBrandData: null,
      };

    case ADD_NEW_BRAND_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        createOrUpdated: true,
        brandAdded: true,
        newBrandData: data,
        brandsByRole: [...state.brandsByRole, data],
      };
    }

    case BRAND_DETAIL_SUCCESS: {
      return { ...state, brandDetail: payload.brand };
    }

    case BRAND_LIST_SUCCESS:
      return { ...state, brands: payload.brands };
    case BRAND_LIST_FAILURE:
      return { ...state };

    case BRAND_LIST_BY_ROLE:
      return { ...state, brandsByRole: [], brandAdded: false,
        newBrandData: null, };
    case BRAND_LIST_BY_ROLE_SUCCESS:
      return { ...state, brandsByRole: payload.brandsByRole };
    case BRAND_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case BRAND_DELETE_SUCCESS:
      return {
        ...state,
        brands: state.brands.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
