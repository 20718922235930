import { lazy, Suspense } from "react";
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { verifyPermission } from "../App";

import Loader from "../components/Loader";

const ProfileForm = lazy(() => import("../screens/Profile/ProfileForm"));
const Dashboard = lazy(() => import("../screens/Home/Dashboard"));

const AdminList = lazy(() => import("../screens/Admin/AdminList.js"));
const AdminForm = lazy(() =>
  import("../screens/Admin/AdminRegister/AdminRegisterMain.js")
);

const CustomerList = lazy(() => import("../screens/Sales/Customer/customerList"));
const CustomerForm = lazy(() => import("../screens/Sales/Customer/customerForm"));

const SupplierList = lazy(() => import("../screens/Purchase/Supplier/supplierList.js"));
const SupplierForm = lazy(() => import("../screens/Purchase/Supplier/supplierForm.js"));

const QuotationList = lazy(() => import("../screens/Sales/Quotation/quotationList.js"));
const QuotationForm = lazy(() => import("../screens/Sales/Quotation/quotationForm.js"));
const PDF = lazy(() => import("../screens/Sales/Quotation/PDF.js"));

const InvoiceList = lazy(() => import("../screens/Sales/Invoice/invoiceList"));
const InvoiceForm = lazy(() => import("../screens/Sales/Invoice/invoiceForm"));
const InvoicePDF = lazy(() => import("../screens/Sales/Invoice/PDF"));

const EmployeeList = lazy(() =>
  import("../screens/Employ_onboarding/employOnboardingList")
);
const EmployeeForm = lazy(() =>
  import("../screens/Employ_onboarding/employOnboardingForm")
);

const HolidaysList = lazy(() => import("../screens/HolidaySetup/HolidaysList"));
const HolidaySetupForm = lazy(() =>
  import("../screens/HolidaySetup/HolidaySetupForm")
);

const EmployeeAttendance = lazy(() =>
  import("../screens/EmployeeAtendance/employeeAtendence")
);
const AttendanceReport = lazy(() =>
  import("../screens/EmployeeAtendance/attendanceReport")
);
const AttendanceReportPDF = lazy(() =>
  import("../screens/EmployeeAtendance/PDF")
);

const ProjectList = lazy(() => import("../screens/Project/projectList"));
const ProjectForm = lazy(() => import("../screens/Project/projectForm"));

const TicketsList = lazy(() => import("../screens/Ticket/ticketsList"));
const TicketsForm = lazy(() => import("../screens/Ticket/ticketsForm"));

const RecruitList = lazy(() => import("../screens/Recruit/recruitList"));
const RecruitForm = lazy(() => import("../screens/Recruit/recruitForm"));

const UserList = lazy(() => import("../screens/user/userList"));
const UserForm = lazy(() => import("../screens/user/userForm"));

const RolesAndRightsList = lazy(() =>
  import("../screens/roles_and_rights/rolesAndRightsList")
);
const RolesAndRightsForm = lazy(() =>
  import("../screens/roles_and_rights/rolesAndRightsForm")
);

const JobTypeList = lazy(() =>
  import("../screens/Masters/job_type/jobTypeList")
);
const RecruitmentStatusList = lazy(() =>
  import("../screens/Masters/recruit_status/recruitmentStatusList")
);
const EmploymentStatusList = lazy(() =>
  import("../screens/Masters/employment_status/employmentStatusList")
);
const DesignationList = lazy(() =>
  import("../screens/Masters/designation/designationList")
);
const CurrencyList = lazy(() =>
  import("../screens/Masters/currency/currencyList")
);

const Reports = lazy(() => import("../screens/Reports/reportsForm"));

//GST master master page
const GSTMasterList = lazy(() =>
  import("../screens/Masters/gst_master/GSTMasterList.js")
);
const GSTMasterForm = lazy(() =>
  import("../screens/Masters/gst_master/GSTMasterForm")
);

const CodeSetupList = lazy(() => import("../screens/Codesetup/codeSetupList"));
const CodeSetupForm = lazy(() => import("../screens/Codesetup/codeSetupForm"));

// const AddMastersList = lazy(() => import("../screens/AddMasters/addMastersList.js"));
// const AddMastersForm = lazy(() => import("../screens/AddMasters/addMastersForm.js"));

const StockReport = lazy(() => import("../screens/Stock/stockReport.js"));

const PurchaseList = lazy(() => import("../screens/Purchase/Purchases/purchaseList.js"));
const PurchaseForm = lazy(() => import("../screens/Purchase/Purchases/purchaseForm.js"));

// const PurchaseOrderList = lazy(() => import("../screens/Purchase/PurchaseOrder/purchaseOrderList.js"));


const PurchaseReturnList = lazy(() => import("../screens/Purchase/PurchaseReturn/purchaseReturnList.js"));
const PurchaseReturnForm = lazy(() => import("../screens/Purchase/PurchaseReturn/purchaseReturnForm.js"));
const POForm = lazy(() => import("../screens/Purchase/PO/poForm.js"));

const SalesList = lazy(() => import("../screens/Sales/Sales/salesList.js"));
const SalesForm = lazy(() => import("../screens/Sales/Sales/salesForm.js"));

// const SalesOrderList = lazy(() => import("../screens/Sales/SalesOrder/purchaseOrderList.js"));

const SalesReturnList = lazy(() => import("../screens/Sales/SalesReturn/salesReturnList.js"));
const SalesReturnForm = lazy(() => import("../screens/Sales/SalesReturn/salesReturnForm.js"));

// const posList = lazy(() => import("../screens/Sales/POS/salesReturnList.js"));
const POSForm = lazy(() => import("../screens/Sales/POS/posForm.js"));

// Inventory starts
const BrandList = lazy(() => import("../screens/Inventory/Brand/brandList.js"));
const BrandForm = lazy(() => import("../screens/Inventory/Brand/brandForm.js"));

const CategoryList = lazy(() =>
  import("../screens/Inventory/Category/categoryList.js")
);
const CategoryForm = lazy(() =>
  import("../screens/Inventory/Category/categoryForm.js")
);

const SubCategoryList = lazy(() =>
  import("../screens/Inventory/SubCategory/subCategoryList.js")
);
const SubCategoryForm = lazy(() =>
  import("../screens/Inventory/SubCategory/subCategoryForm.js")
);

const ProductList = lazy(() => import("../screens/Inventory/Product/productList"));
const ProductForm = lazy(() => import("../screens/Inventory/Product/productForm"));

const AppNavigator = () => {
  const navigate = useNavigate();
  const location = useLocation();
  // console.log(location.pathname.startsWith("/masters/currency"))

  if (
    (location.pathname.startsWith("/masters") &&
      !verifyPermission("READ_MASTERS")) ||
    (location.pathname === "/company-profile" &&
      !verifyPermission("UPDATE_COMPANY_PROFILE")) ||
    (location.pathname === "/admin" && !verifyPermission("READ_ADMIN")) ||
    (location.pathname.startsWith("/admin-form") &&
      (!verifyPermission("CREATE_ADMIN") ||
        !verifyPermission("UPDATE_ADMIN"))) ||
    (location.pathname === "/sales/customer" &&
    !verifyPermission("READ_INVENTORY_MODULE") && !verifyPermission("READ_CUSTOMER")) ||
    (location.pathname.startsWith("/sales/customer-form") &&
    !verifyPermission("READ_INVENTORY_MODULE") &&
      (!verifyPermission("CREATE_CUSTOMER") ||
        !verifyPermission("UPDATE_CUSTOMER"))) || (location.pathname === "/purchase/supplier" && !verifyPermission("READ_SUPPLIER")) ||
        (location.pathname.startsWith("/purchase/supplier-form") &&
        !verifyPermission("READ_INVENTORY_MODULE") &&
          (!verifyPermission("CREATE_SUPPLIER") ||
            !verifyPermission("UPDATE_SUPPLIER"))) ||
    (location.pathname === "/sales/quotation" &&
    !verifyPermission("READ_INVENTORY_MODULE") &&
      !verifyPermission("READ_QUOTATION")) ||
    (location.pathname.startsWith("/sales/quotation-form") &&
      (!verifyPermission("CREATE_QUOTATION") ||
        !verifyPermission("UPDATE_QUOTATION"))) ||
    (location.pathname === "/sales/invoice" && !verifyPermission("READ_INVOICE")) ||
    (location.pathname.startsWith("/sales/invoice-form") &&
    !verifyPermission("READ_INVENTORY_MODULE") &&
      (!verifyPermission("CREATE_INVOICE") ||
        !verifyPermission("UPDATE_INVOICE"))) ||
    (location.pathname === "/roles_and_rights" &&
      !verifyPermission("READ_ROLE")) ||
    (location.pathname.startsWith("/roles_and_rights-form") &&
      (!verifyPermission("CREATE_ROLE") || !verifyPermission("UPDATE_ROLE"))) ||
    (location.pathname === "/user" &&
      !verifyPermission("READ_USER_MANAGEMENT")) ||
    (location.pathname.startsWith("/user-form") &&
      (!verifyPermission("CREATE_USER_MANAGEMENT") ||
        !verifyPermission("UPDATE_USER_MANAGEMENT"))) ||
    (location.pathname === "/code-setup" &&
      !verifyPermission("READ_CODE_SETUP")) ||
    (location.pathname.startsWith("/code-setup-form") &&
      (!verifyPermission("CREATE_CODE_SETUP") ||
        !verifyPermission("UPDATE_CODE_SETUP"))) ||
    // (location.pathname === "/add-masters" &&
    //   !verifyPermission("READ_MASTER_LIST")) ||
    // (location.pathname.startsWith("/add-masters-form") &&
    //   (!verifyPermission("CREATE_MASTER_LIST") ||
    //     !verifyPermission("UPDATE_MASTER_LIST"))) ||
    (location.pathname.startsWith("/reports") &&
      !verifyPermission("READ_REPORT")) ||
    (location.pathname === "/recruit" && !verifyPermission("READ_RECRUIT")) ||
    (location.pathname.startsWith("/recruit-form") &&
      (!verifyPermission("CREATE_RECRUIT") ||
        !verifyPermission("UPDATE_RECRUIT"))) ||
    (location.pathname === "/employ_onboarding" &&
      !verifyPermission("READ_EMPLOYEE")) ||
    (location.pathname.startsWith("/employ_onboarding-form") &&
      (!verifyPermission("CREATE_EMPLOYEE") ||
        !verifyPermission("UPDATE_EMPLOYEE"))) ||
    (location.pathname === "/project" && !verifyPermission("READ_PROJECT")) ||
    (location.pathname.startsWith("/project-form") &&
      (!verifyPermission("CREATE_PROJECT") ||
        !verifyPermission("UPDATE_PROJECT"))) ||
    (location.pathname === "/tickets" && !verifyPermission("READ_TICKET")) ||
    (location.pathname.startsWith("/tickets-form") &&
      (!verifyPermission("CREATE_TICKET") ||
        !verifyPermission("UPDATE_TICKET"))) ||
    (location.pathname === "purchase/purchases" &&
    !verifyPermission("READ_INVENTORY_MODULE") && !verifyPermission("READ_PURCHASE")) ||
    (location.pathname.startsWith("purchase/purchase-form") &&
    !verifyPermission("READ_INVENTORY_MODULE") &&
      (!verifyPermission("CREATE_PURCHASE") ||
        !verifyPermission("UPDATE_PURCHASE"))) ||
        (location.pathname === "purchase/purchase-return" &&
        !verifyPermission("READ_INVENTORY_MODULE") && !verifyPermission("READ_PURCHASE_RETURN")) ||
        (location.pathname.startsWith("purchase/purchase-return-form") &&
        !verifyPermission("READ_INVENTORY_MODULE") &&
          (!verifyPermission("CREATE_PURCHASE_RETURN") ||
            !verifyPermission("UPDATE_PURCHASE_RETURN"))) || 
        (location.pathname.startsWith("purchase/po") &&
        !verifyPermission("READ_INVENTORY_MODULE") &&
          (!verifyPermission("READ_PO") ||
            !verifyPermission("UPDATE_PO"))) || (location.pathname === "sales/sales" &&
            !verifyPermission("READ_INVENTORY_MODULE") && !verifyPermission("READ_SALES")) ||
    (location.pathname.startsWith("sales/sales-form") &&
    !verifyPermission("READ_INVENTORY_MODULE") &&
      (!verifyPermission("CREATE_SALES") ||
        !verifyPermission("UPDATE_SALES"))) ||
        (location.pathname === "sales/sales-return" && !verifyPermission("READ_SALES_RETURN")) ||
        (location.pathname.startsWith("sales/sales-return-form") &&
        !verifyPermission("READ_INVENTORY_MODULE") &&
          (!verifyPermission("CREATE_SALES_RETURN") ||
            !verifyPermission("UPDATE_SALES_RETURN"))) || 
        (location.pathname.startsWith("sales/pos") &&
        !verifyPermission("READ_INVENTORY_MODULE") &&
          (!verifyPermission("CREATE_POS") ||
            !verifyPermission("UPDATE_POS"))) ||
    (location.pathname === "/brand" &&
    !verifyPermission("READ_INVENTORY_MODULE") && !verifyPermission("READ_BRAND")) ||
    (location.pathname.startsWith("/brand-form") &&
    !verifyPermission("READ_INVENTORY_MODULE") &&
      (!verifyPermission("CREATE_BRAND") ||
        !verifyPermission("UPDATE_BRAND"))) ||
    (location.pathname === "/category" &&
    !verifyPermission("READ_INVENTORY_MODULE") && !verifyPermission("READ_CATEGORY")) ||
    (location.pathname.startsWith("/category-form") &&
    !verifyPermission("READ_INVENTORY_MODULE") &&
      (!verifyPermission("CREATE_CATEGORY") || !verifyPermission("UPDATE_CATEGORY"))) ||
        (location.pathname === "/products" &&
        !verifyPermission("READ_INVENTORY_MODULE") && !verifyPermission("READ_PRODUCT")) ||
        (location.pathname.startsWith("/product-form") &&
        !verifyPermission("READ_INVENTORY_MODULE") &&
          (!verifyPermission("CREATE_PRODUCT") || !verifyPermission("UPDATE_PRODUCT")))
  ) {
    navigate("/");
  }

  return (
    <Suspense fallback={<Loader loading={true} />}>
      <Routes>
        <Route path="/admin" element={<AdminList />} />
        <Route path="/admin-form" element={<AdminForm />} />
        <Route path="/admin-form/:id/:email" element={<AdminForm />} />

        <Route path="/company-profile" element={<ProfileForm />} />

        <Route path="/sales/customer" element={<CustomerList />} />
        <Route path="/sales/customer-form" element={<CustomerForm />} />
        <Route path="/sales/customer-form/:id" element={<CustomerForm />} />

        <Route path="/recruit" element={<RecruitList />} />
        <Route path="/recruit-form" element={<RecruitForm />} />
        <Route path="/recruit-form/:id" element={<RecruitForm />} />

        <Route path="/employ_onboarding" element={<EmployeeList />} />
        <Route path="/employ_onboarding-form" element={<EmployeeForm />} />
        <Route path="/employ_onboarding-form/:id" element={<EmployeeForm />} />

        <Route path="/holiday-setup" element={<HolidaysList />} />
        <Route path="/holiday-setup-form" element={<HolidaySetupForm />} />
        <Route path="/holiday-setup-form/:id" element={<HolidaySetupForm />} />

        <Route path="/attendances" element={<EmployeeAttendance />} />
        <Route path="/attendance_report" element={<AttendanceReport />} />
        <Route
          path="/attendance_report/PDF/:id"
          element={<AttendanceReportPDF />}
        />

        <Route path="/project" element={<ProjectList />} />
        <Route path="/project-form" element={<ProjectForm />} />
        <Route path="/project-form/:id" element={<ProjectForm />} />

        <Route path="/tickets" element={<TicketsList />} />
        <Route path="/tickets-form" element={<TicketsForm />} />
        <Route path="/tickets-form/:id" element={<TicketsForm />} />

        <Route path="/user" element={<UserList />} />
        <Route path="/user-form" element={<UserForm />} />
        <Route path="/user-form/:id" element={<UserForm />} />

        <Route path="/roles_and_rights" element={<RolesAndRightsList />} />
        <Route path="/roles_and_rights-form" element={<RolesAndRightsForm />} />
        <Route
          path="/roles_and_rights-form/:id"
          element={<RolesAndRightsForm />}
        />

        <Route exact path={"/masters/job_type"} element={<JobTypeList />} />
        <Route
          exact
          path={"/masters/recruitment_status"}
          element={<RecruitmentStatusList />}
        />
        <Route
          exact
          path={"/masters/employment_status"}
          element={<EmploymentStatusList />}
        />
        <Route exact path={"/masters/gst_master"} element={<GSTMasterList />} />
        <Route
          exact
          path={"/masters/designation"}
          element={<DesignationList />}
        />
        <Route exact path={"/masters/currency"} element={<CurrencyList />} />
        <Route
          exact
          path={"/masters/gst_master-form"}
          element={<GSTMasterForm />}
        />
        <Route
          exact
          path={"/masters/gst_master-form/:id"}
          element={<GSTMasterForm />}
        />

        <Route
          exact
          path={"/reports" || "reports-form"}
          element={<Reports />}
        />

        <Route path="/code-setup" element={<CodeSetupList />} />
        <Route path="/code-setup-form" element={<CodeSetupForm />} />
        <Route path="/code-setup-form/:id" element={<CodeSetupForm />} />

        {/* <Route path="/add-masters" element={<AddMastersList />} />
        <Route path="/add-masters-form" element={<AddMastersForm />} />
        <Route path="/add-masters-form/:id" element={<AddMastersForm />} /> */}

        {/* Above code is for adding new masters */}
        <Route path="/stock-report" element={<StockReport />} />

        {verifyPermission("READ_PURCHASE") && <Route path="/purchase/purchases" element={<PurchaseList />} />}
        {verifyPermission("CREATE_PURCHASE") && <Route path="/purchase/purchase-form" element={<PurchaseForm />} />}
        {verifyPermission("UPDATE_PURCHASE") && <Route path="/purchase/purchase-form/:id" element={<PurchaseForm />} />}

        {verifyPermission("READ_PURCHASE_RETURN") && <Route path="/purchase/purchase-return" element={<PurchaseReturnList />} />}
        {verifyPermission("CREATE_PURCHASE_RETURN") && <Route path="/purchase/purchase-return-form" element={<PurchaseReturnForm />} />}
        {verifyPermission("UPDATE_PURCHASE_RETURN") && <Route path="/purchase/purchase-return-form/:id" element={<PurchaseReturnForm />} />}
        {verifyPermission("READ_PO") && <Route path="/purchase/po" element={<POForm />} />}
        {verifyPermission("UPDATE_PO") && <Route path="/purchase/po/:id" element={<POForm />} />}

        {/* Sales */}
        {verifyPermission("READ_SALES") && <Route path="/sales/sales" element={<SalesList />} />}
        {verifyPermission("CREATE_SALES") && <Route path="/sales/sales-form" element={<SalesForm />} />}
        {verifyPermission("UPDATE_SALES") && <Route path="/sales/sales-form/:id" element={<SalesForm />} />}

        {/* <Route path="/sales/sales-order" element={<SalesOrderList />} /> */}
        {verifyPermission("READ_SUPPLIER") && <Route path="/purchase/supplier" element={<SupplierList />} />}
        {verifyPermission("CREATE_SUPPLIER") && <Route path="/purchase/supplier-form" element={<SupplierForm />} />}
        {verifyPermission("UPDATE_SUPPLIER") && <Route path="/purchase/supplier-form/:id" element={<SupplierForm />} />}

        {verifyPermission("READ_SALES_RETURN") && <Route path="/sales/sales-return" element={<SalesReturnList />} />}
        {verifyPermission("CREATE_SALES_RETURN") && <Route path="/sales/sales-return-form" element={<SalesReturnForm />} />}
        {verifyPermission("UPDATE_SALES_RETURN") && <Route path="/sales/sales-return-form/:id" element={<SalesReturnForm />} />}

        {/* <Route path="/sales/pos" element={<SalesReturnList />} /> */}
        {verifyPermission("READ_POS") && <Route path="/sales/pos" element={<POSForm />} />}
        {verifyPermission("UPDATE_POS") &&  <Route path="/sales/pos/:id" element={<POSForm />} />}

        <Route path="/sales/quotation" element={<QuotationList />} />
        <Route path="/sales/quotation-form" element={<QuotationForm />} />
        <Route path="/sales/quotation-form/:id" element={<QuotationForm />} />
        <Route path="/sales/quotation/PDF/:id" element={<PDF />} />

        <Route path="/sales/invoice" element={<InvoiceList />} />
        <Route path="/sales/invoice-form" element={<InvoiceForm />} />
        <Route path="/sales/invoice-form/:id" element={<InvoiceForm />} />
        <Route path="/sales/invoice/PDF/:id" element={<InvoicePDF />} />
        

        <Route path="/brand" element={<BrandList />} />
        <Route path="/brand-form" element={<BrandForm />} />
        <Route path="/brand-form/:id" element={<BrandForm />} />

        {verifyPermission("READ_CATEGORY") && <Route path="/category" element={<CategoryList />} />}
        {verifyPermission("CREATE_CATEGORY") && <Route path="/category-form" element={<CategoryForm />} />}
        {verifyPermission("UPDATE_CATEGORY") && <Route path="/category-form/:id" element={<CategoryForm />} />}

        {/* {verifyPermission("READ_SUB_CATEGORY") && <Route path="/sub-category" element={<SubCategoryList />} />}
        {verifyPermission("CREATE_SUB_CATEGORY") && <Route path="/sub-category-form" element={<SubCategoryForm />} />}
        {verifyPermission("UPDATE_SUB_CATEGORY") && <Route path="/sub-category-form/:id" element={<SubCategoryForm />} />} */}

        {verifyPermission("READ_PRODUCT") && <Route path="/products" element={<ProductList />} />}
        {verifyPermission("CREATE_PRODUCT") && <Route path="/product-form" element={<ProductForm />} />}
        {verifyPermission("UPDATE_PRODUCT") && <Route path="/product-form/:id" element={<ProductForm />} />}

        <Route path="/*" element={<Navigate to="/dashboard" />} />
        <Route path="/dashboard" element={<Dashboard />} />
      </Routes>
    </Suspense>
  );
};
export default AppNavigator;
