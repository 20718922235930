import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  GENERATE_QUOTATION_ID,
  GENERATE_QUOTATION_ID_FAILURE,
  GENERATE_QUOTATION_ID_SUCCESS,
  QUOTATION_DELETE,
  QUOTATION_DELETE_FAILURE,
  QUOTATION_DELETE_SUCCESS,
  QUOTATION_DETAIL,
  QUOTATION_DETAIL_FAILURE,
  QUOTATION_DETAIL_SUCCESS,
  QUOTATION_LIST,
  QUOTATION_LIST_BY_ROLE,
  QUOTATION_LIST_BY_ROLE_FAILURE,
  QUOTATION_LIST_BY_ROLE_SUCCESS,
  QUOTATION_LIST_FAILURE,
  QUOTATION_LIST_SUCCESS,
  QUOTATION_NEW,
  QUOTATION_NEW_FAILURE,
  QUOTATION_NEW_SUCCESS,
  QUOTATION_PDF,
  QUOTATION_PDF_FAILURE,
  QUOTATION_PDF_SUCCESS,
  QUOTATION_UPDATE,
  QUOTATION_UPDATE_FAILURE,
  QUOTATION_UPDATE_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action";
import {
  createQuotationAsync,
  deleteQuotationAsync,
  fetchQuotationAsync,
  fetchQuotationDetailAsync,
  fetchQuotationsByRoleAsync,
  generateQuotationNoAsync,
  updateQuotationAsync,
} from "../../services/account/quotation.service.js";
import { quotationPDFAsync } from "../../services/pdf.service.js";

export function* watchFetchQuotation() {
  yield takeLatest(QUOTATION_LIST, fetchQuotation);
}

export function* watchFetchQuotationByRole() {
  yield takeLatest(QUOTATION_LIST_BY_ROLE, fetchQuotationByRole);
}

export function* watchGenerateQuotationNo() {
  yield takeLatest(GENERATE_QUOTATION_ID, generateQuotationNo);
}

export function* watchCreateQuotation() {
  yield takeLatest(QUOTATION_NEW, createNewQuotation);
}

export function* watchUpdateQuotation() {
  yield takeLatest(QUOTATION_UPDATE, updateQuotation);
}

export function* watchGetDetailQuotation() {
  yield takeLatest(QUOTATION_DETAIL, fetchQuotationDetail);
}

export function* watchDeleteQuotation() {
  yield takeLatest(QUOTATION_DELETE, deleteQuotation);
}

export function* watchQuotationPDF() {
  yield takeLatest(QUOTATION_PDF, quotationPDF);
}

function* fetchQuotation({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchQuotationAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: QUOTATION_LIST_SUCCESS,
        payload: { quotations: response.data },
      });
    } else {
      yield put({ type: QUOTATION_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: QUOTATION_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchQuotationByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchQuotationsByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: QUOTATION_LIST_BY_ROLE_SUCCESS,
        payload: { quotationsByRole: response.data },
      });
    } else {
      yield put({ type: QUOTATION_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: QUOTATION_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* generateQuotationNo({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(generateQuotationNoAsync, uid);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: GENERATE_QUOTATION_ID_SUCCESS,
        payload: { quotationNo: response.data },
      });
    } else {
      yield put({ type: GENERATE_QUOTATION_ID_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GENERATE_QUOTATION_ID_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewQuotation({ payload }) {

  const { data } = payload;

  try {
    const response = yield call(createQuotationAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: QUOTATION_NEW_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: QUOTATION_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: QUOTATION_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateQuotation({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateQuotationAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: QUOTATION_UPDATE_SUCCESS,
        payload: { quotation: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: QUOTATION_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: QUOTATION_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchQuotationDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchQuotationDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: QUOTATION_DETAIL_SUCCESS,
        payload: { quotation: response.data },
      });
    } else {
      yield put({ type: QUOTATION_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: QUOTATION_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteQuotation({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteQuotationAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: QUOTATION_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: QUOTATION_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: QUOTATION_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* quotationPDF({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(quotationPDFAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      const blob = new Blob([response.data], { type: "application/pdf" });
      // saveAs(blob, "Ontario.pdf");
      const fileURL = URL.createObjectURL(blob);
      //Open the URL on new Window
      // window.open(fileURL);

      yield put({
        type: QUOTATION_PDF_SUCCESS,
        payload: { quotationPDFInfo: fileURL },
      });
    } else {
      yield put({ type: QUOTATION_PDF_FAILURE });
      // yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: QUOTATION_PDF_FAILURE });
    // yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchQuotation),
    fork(watchFetchQuotationByRole),
    fork(watchGenerateQuotationNo),
    fork(watchCreateQuotation),
    fork(watchUpdateQuotation),
    fork(watchGetDetailQuotation),
    fork(watchDeleteQuotation),
    fork(watchQuotationPDF),
  ]);
}
