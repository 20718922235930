import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  ADD_NEW_CATEGORY,
  ADD_NEW_CATEGORY_FAILURE,
  ADD_NEW_CATEGORY_SUCCESS,
  CATEGORY_DELETE,
  CATEGORY_DELETE_FAILURE,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DETAIL,
  CATEGORY_DETAIL_FAILURE,
  CATEGORY_DETAIL_SUCCESS,
  CATEGORY_LIST,
  CATEGORY_LIST_BY_ROLE,
  CATEGORY_LIST_BY_ROLE_FAILURE,
  CATEGORY_LIST_BY_ROLE_SUCCESS,
  CATEGORY_LIST_FAILURE,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_NEW,
  CATEGORY_NEW_FAILURE,
  CATEGORY_NEW_SUCCESS,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_FAILURE,
  CATEGORY_UPDATE_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createCategoryAsync,
  deleteCategoryAsync,
  fetchCategoryAsync,
  fetchCategoryByRoleAsync,
  fetchCategoryDetailAsync,
  updateCategoryAsync,
} from "../../../services/inventory/category.service.js";

export function* watchFetchCategory() {
  yield takeLatest(CATEGORY_LIST, fetchCategoryList);
}

export function* watchFetchCategoryByRole() {
  yield takeLatest(CATEGORY_LIST_BY_ROLE, fetchCategoryListByRole);
}

export function* watchCreateCategory() {
  yield takeLatest(CATEGORY_NEW, createNewCategory);
}

export function* watchAddNewCategory() {
  yield takeLatest(ADD_NEW_CATEGORY, addNewCategory);
}

export function* watchUpdateCategory() {
  yield takeLatest(CATEGORY_UPDATE, updateCategory);
}

export function* watchGetDetailCategory() {
  yield takeLatest(CATEGORY_DETAIL, fetchCategoryDetail);
}

export function* watchDeleteCategory() {
  yield takeLatest(CATEGORY_DELETE, deleteCategory);
}

function* fetchCategoryList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchCategoryAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CATEGORY_LIST_SUCCESS,
        payload: { categories: response.data },
      });
    } else {
      yield put({ type: CATEGORY_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CATEGORY_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchCategoryListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchCategoryByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CATEGORY_LIST_BY_ROLE_SUCCESS,
        payload: { categoriesByRole: response.data },
      });
    } else {
      yield put({ type: CATEGORY_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CATEGORY_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewCategory({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createCategoryAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: CATEGORY_NEW_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: CATEGORY_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CATEGORY_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* addNewCategory({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createCategoryAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));      
      yield put({
        type: ADD_NEW_CATEGORY_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ADD_NEW_CATEGORY_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADD_NEW_CATEGORY_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateCategory({ payload }) {
  const { data, id } = payload;
  
  try {
    const response = yield call(updateCategoryAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));      
      yield put({
        type: CATEGORY_UPDATE_SUCCESS,
        payload: { category: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: CATEGORY_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CATEGORY_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchCategoryDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchCategoryDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CATEGORY_DETAIL_SUCCESS,
        payload: { category: response.data },
      });
    } else {
      yield put({ type: CATEGORY_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CATEGORY_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteCategory({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteCategoryAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: CATEGORY_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: CATEGORY_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CATEGORY_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchCategory),
    fork(watchFetchCategoryByRole),
    fork(watchCreateCategory),
    fork(watchAddNewCategory),
    fork(watchUpdateCategory),
    fork(watchGetDetailCategory),
    fork(watchDeleteCategory),
  ]);
}
