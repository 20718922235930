import api from "../api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchSubCategoryAsync = async (uid) =>
  await api
    .get(`/sub-category`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchSubCategoryByRoleAsync = async (uid) =>
  await api
    .get(`/sub-category/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const createSubCategoryAsync = async (payload) =>
  await api
    .post(`/sub-category`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateSubCategoryAsync = async (payload, id) =>
  await api
    .put(`/sub-category/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchSubCategoryDetailAsync = async (id) =>
  await api
    .get(`/sub-category/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteSubCategoryAsync = async (id) =>
  await api
    .delete(`/sub-category/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
