import {
  PURCHASE_RETURN_DELETE_SUCCESS,
  PURCHASE_RETURN_DETAIL,
  PURCHASE_RETURN_DETAIL_SUCCESS,
  PURCHASE_RETURN_LIST,
  PURCHASE_RETURN_LIST_BY_ROLE,
  PURCHASE_RETURN_LIST_BY_ROLE_FAILURE,
  PURCHASE_RETURN_LIST_BY_ROLE_SUCCESS,
  PURCHASE_RETURN_LIST_FAILURE,
  PURCHASE_RETURN_LIST_SUCCESS,
  PURCHASE_RETURN_NEW,
  PURCHASE_RETURN_NEW_SUCCESS,
  PURCHASE_RETURN_PAYMENT_UPDATE,
  PURCHASE_RETURN_PAYMENT_UPDATE_SUCCESS,
  PURCHASE_RETURN_STATUS_UPDATE,
  PURCHASE_RETURN_STATUS_UPDATE_SUCCESS,
  PURCHASE_RETURN_UPDATE,
  PURCHASE_RETURN_UPDATE_SUCCESS,
} from "../../types.js";

const initialState = {
  purchaseReturns: [],
  purchaseReturnDetail: null,
  createOrUpdated: false,

  // Brands by role
  purchaseReturnsByRole: [],

  purchaseReturnAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PURCHASE_RETURN_LIST:
    case PURCHASE_RETURN_UPDATE:
    case PURCHASE_RETURN_STATUS_UPDATE:
    case PURCHASE_RETURN_DETAIL:
    case PURCHASE_RETURN_PAYMENT_UPDATE:
      return {
        ...state,
        createOrUpdated: false,
        paymentUpdated: false,
        purchaseReturnDetail: null,
      };

    case PURCHASE_RETURN_NEW:
      return {
        ...state,
        purchaseReturnAdded: false,
        newProductData: null,
      };

    case PURCHASE_RETURN_NEW_SUCCESS: {
      const { data } = action.payload;
      return { ...state, createOrUpdated: true, purchaseReturnAdded: true, newProductData: data, purchaseReturnsByRole: [...state.purchaseReturnsByRole, data] };
    }
    
    case PURCHASE_RETURN_STATUS_UPDATE_SUCCESS:
    case PURCHASE_RETURN_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case PURCHASE_RETURN_PAYMENT_UPDATE_SUCCESS: {
      return { ...state, paymentUpdated: true };
    }

    case PURCHASE_RETURN_DETAIL_SUCCESS: {
      return { ...state, purchaseReturnDetail: payload.purchaseReturn };
    }

    case PURCHASE_RETURN_LIST_SUCCESS:
      return { ...state, purchaseReturns: payload.purchaseReturns };
    case PURCHASE_RETURN_LIST_FAILURE:
      return { ...state, purchaseReturns: [] };

    case PURCHASE_RETURN_LIST_BY_ROLE:
      return { ...state, purchaseReturnsByRole: [] };
    case PURCHASE_RETURN_LIST_BY_ROLE_SUCCESS:
      return { ...state, purchaseReturnsByRole: payload.purchaseReturnsByRole };
    case PURCHASE_RETURN_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case PURCHASE_RETURN_DELETE_SUCCESS:
      return {
        ...state,
        purchaseReturns: state.purchaseReturns.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
