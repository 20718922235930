import {
  ADMIN_DELETE_SUCCESS,
  ADMIN_DETAIL,
  ADMIN_DETAIL_SUCCESS,
  ADMIN_LIST,
  ADMIN_LIST_BY_ROLE,
  ADMIN_LIST_BY_ROLE_FAILURE,
  ADMIN_LIST_BY_ROLE_SUCCESS,
  ADMIN_LIST_FAILURE,
  ADMIN_LIST_SUCCESS,
  ADMIN_NEW,
  ADMIN_NEW_SUCCESS,
  ADMIN_STATUS_UPDATE,
  ADMIN_STATUS_UPDATE_SUCCESS,
  ADMIN_UPDATE,
  ADMIN_UPDATE_SUCCESS,
} from "../types.js";

const initialState = {
  admins: [],
  adminDetail: null,
  createOrUpdated: false,

  // admins by role
  adminsByRole: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case ADMIN_LIST:
    case ADMIN_NEW:
    case ADMIN_UPDATE:
    case ADMIN_DETAIL:
    case ADMIN_STATUS_UPDATE:
      return {
        ...state,
        createOrUpdated: false,
        adminDetail: null,
        adminNo: "",
      };

    case ADMIN_STATUS_UPDATE_SUCCESS:
    case ADMIN_NEW_SUCCESS:
    case ADMIN_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case ADMIN_DETAIL_SUCCESS: {
      return { ...state, adminDetail: payload.admin };
    }
    // case GENERATE_ADMIN_ID_SUCCESS: {
    //   return { ...state, adminNo: payload.adminNo };
    // }

    case ADMIN_LIST_SUCCESS:
      return { ...state, admins: payload.admins };
    case ADMIN_LIST_FAILURE:
      return { ...state };

    case ADMIN_LIST_BY_ROLE:
      return { ...state, adminsByRole: [] };
    case ADMIN_LIST_BY_ROLE_SUCCESS:
      return { ...state, adminsByRole: payload.adminsByRole };
    case ADMIN_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case ADMIN_DELETE_SUCCESS:
      return {
        ...state,
        admins: state.admins.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
