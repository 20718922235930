import { async } from "@firebase/util";
import { collection, getDocs, query } from "firebase/firestore";
import {
  getDownloadURL,
  ref,
  uploadBytes,
  uploadBytesResumable,
} from "firebase/storage";
import { t } from "i18next";
import { database, storage } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

// export const getAllMasters = async () => {
//   const master = collection(database, "master");

//   const q = query(master);
//   await getDocs(q).then((querySnapshot) => {
//       if (!querySnapshot.empty) {
//         const dataArr = [];
//         querySnapshot.forEach((doc) => {
//           dataArr.push(doc.data());
//         });
//         response = {
//           status: 200,
//           data: dataArr,
//           message: t("message_quotations_get_success"),
//         };
//         return response;
//       } else {
//         response = {
//           status: 400,
//           message: t("message_quotations_not_found"),
//         };
//       }
//     })
//     .catch((error) => {
//       let message = generateMessage(error.code);
//       response = {
//         status: 400,
//         data: null,
//         message: message || error.message,
//       };
//       return response;
//     });

//   return response;
// };

// export const uploadFilesAsync = async(companyName, documentType, isPublic, file) => {

//   try {
//     const querySnapshot = await api.post(`files/cloudinary-upload`, file)
//   //  console.log("querySnapshot", querySnapshot);

//     if (querySnapshot?.status == 200 || querySnapshot?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot.data,
//         // message: t("message_recruits_create_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_recruits_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

// //  try {
// //   const storageRef = ref(storage, `/${companyName}/${documentType}/${file.name}`);
// //   let uploadResponse = await uploadBytes(storageRef, file)
// //   if(uploadResponse){
// //     let responseUrl = await getDownloadURL(storageRef)
// //     response = {
// //       status:200,
// //       data : responseUrl,
// //       message:"File uploaded successfully"
// //       }
// //     }
// //   return response;
// // } catch (error) {
// //   let message = generateMessage(error.code);
// //   response = {
// //     status: 401,
// //     data: null,
// //     message: message || error.message,
// //   };
// //   return response;
// // }
// };

export const getAllMasters = async () => {
  const dataArr = [
    {
      slug: "job_type",
      isActive: true,
      id: "01",
      isDeleted: false,
      // createdAt: { seconds: 1651499100, nanoseconds: 0 },
    },
    {
      slug: "employment_status",
      id: "02",
      isActive: true,
      isDeleted: false,
      // createdAt: { seconds: 1651499160, nanoseconds: 0 },
    },
    {
      isActive: true,
      id: "03",
      slug: "recruitment_status",
      // createdAt: { seconds: 1651757580, nanoseconds: 0 },
      isDeleted: false,
    },
    {
      slug: "designation",
      isActive: true,
      id: "04",
      isDeleted: false,
      // createdAt: { seconds: 1653556140, nanoseconds: 0 },
    },
    {
      isActive: true,
      slug: "currency",
      isDeleted: false,
      id: "05",
      // createdAt: { seconds: 1658399849, nanoseconds: 498000000 },
    },
  ];

  response = {
    status: 200,
    data: dataArr,
    message: t("masters_fetch_successful"),
  };
  return response;
};

export const uploadFilesAsync = async (
  companyName,
  documentType,
  isPublic,
  file
) =>
  await api
    .post(`files/cloudinary-upload`, file)
    .then((res) => res.data)
    .catch((err) => err);
