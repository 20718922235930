import api from "../api";
import { generateID } from "../idGenerator.service";

export const generatePOSNoAsync = async (uid) => await api.get(`/salesPos/posNo/generatePosNo`).then((res) => res.data).catch((err) => err);

export const fetchPOSAsync = async (uid) => await api.get(`/salesPos`).then((res) => res.data).catch((err) => err);

export const fetchPOSByRoleAsync = async (uid) => await api.get(`/salesPos/assign`).then((res) => res.data).catch((err) => err);

export const fetchPOSCategoriesAsync = async (uid) => await api.get(`/pos/categories`).then((res) => res.data).catch((err) => err);

export const createPOSAsync = async (payload) => await api.post(`/salesPos`, payload).then((res) => res.data).catch((err) => err);

export const updatePOSAsync = async (payload, id) => await api.put(`/salesPos/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updatePOStatusAsync = async (payload, id) => await api.put(`/salesPos/status/${id}`, payload).then((res) => res.data).catch((err) => err);

export const updatePOSPaymentInfoAsync = async (payload, id) =>
  await api
    .put(`/salesPos/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchPOSDetailAsync = async (id) => await api.get(`/salesPos/${id}`).then((res) => res.data).catch((err) => err);

export const deletePOSAsync = async (id) => await api.delete(`/salesPos/${id}`).then((res) => res.data).catch((err) => err);