import {
  ADD_NEW_CATEGORY,
  ADD_NEW_CATEGORY_SUCCESS,
  CATEGORY_DELETE_SUCCESS,
  CATEGORY_DETAIL,
  CATEGORY_DETAIL_SUCCESS,
  CATEGORY_LIST,
  CATEGORY_LIST_BY_ROLE,
  CATEGORY_LIST_BY_ROLE_FAILURE,
  CATEGORY_LIST_BY_ROLE_SUCCESS,
  CATEGORY_LIST_FAILURE,
  CATEGORY_LIST_SUCCESS,
  CATEGORY_NEW,
  CATEGORY_NEW_SUCCESS,
  CATEGORY_UPDATE,
  CATEGORY_UPDATE_SUCCESS,
} from "../../types.js";

const initialState = {
  categories: [],
  categoryDetail: null,
  createOrUpdated: false,

  // Brands by role
  categoriesByRole: [],

  categoryAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CATEGORY_LIST:
    case CATEGORY_NEW:
    case CATEGORY_UPDATE:
    case CATEGORY_DETAIL:
      return {
        ...state,
        createOrUpdated: false,
        categoryDetail: null,
      };

    case ADD_NEW_CATEGORY:
      return {
        ...state,
        categoryAdded: false,
        newCategoryData: null,
      };

    case ADD_NEW_CATEGORY_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        categoryAdded: true,
        newCategoryData: data,
        categoriesByRole: [...state.categoriesByRole, data],
      };
    }

    case CATEGORY_NEW_SUCCESS:
    case CATEGORY_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case CATEGORY_DETAIL_SUCCESS: {
      return { ...state, categoryDetail: payload.category };
    }

    case CATEGORY_LIST_SUCCESS:
      return { ...state, categories: payload.categories };
    case CATEGORY_LIST_FAILURE:
      return { ...state };

    case CATEGORY_LIST_BY_ROLE:
      return { ...state, categoriesByRole: [], categoryAdded: false,
        newCategoryData: null, };
    case CATEGORY_LIST_BY_ROLE_SUCCESS:
      return { ...state, categoriesByRole: payload.categoriesByRole };
    case CATEGORY_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case CATEGORY_DELETE_SUCCESS:
      return {
        ...state,
        categories: state.categories.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
