import {
  GENERATE_PROJECT_ID,
  GENERATE_PROJECT_ID_SUCCESS,
  PROJECT_DELETE_SUCCESS,
  PROJECT_DETAIL,
  PROJECT_DETAIL_SUCCESS,
  PROJECT_LIST,
  PROJECT_LIST_BY_ROLE,
  PROJECT_LIST_BY_ROLE_FAILURE,
  PROJECT_LIST_BY_ROLE_SUCCESS,
  PROJECT_LIST_FAILURE,
  PROJECT_LIST_SUCCESS,
  PROJECT_NEW,
  PROJECT_NEW_SUCCESS,
  PROJECT_UPDATE,
  PROJECT_UPDATE_SUCCESS,
} from "../types.js";

const initialState = {
  projects: [],
  projectNo: "",
  projectDetail: null,
  createOrUpdated: false,

  // projects by role
  projectsByRole: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case PROJECT_LIST:
    case PROJECT_NEW:
    case PROJECT_UPDATE:
    case PROJECT_DETAIL:
      return {
        ...state,
        createOrUpdated: false,
        projectDetail: null,
        projectNo: "",
      };

    case PROJECT_NEW_SUCCESS:
    case PROJECT_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case PROJECT_DETAIL_SUCCESS: {
      return { ...state, projectDetail: payload.project };
    }

    case GENERATE_PROJECT_ID: {
      return { ...state, projectNo: "", idIDGenarationChecked: false };
    }

    case GENERATE_PROJECT_ID_SUCCESS: {
      return { ...state, projectNo: payload.projectNo, idIDGenarationChecked: true };
    }

    case PROJECT_LIST_SUCCESS:
      return { ...state, projects: payload.projects };
    case PROJECT_LIST_FAILURE:
      return { ...state };

    case PROJECT_LIST_BY_ROLE:
      return { ...state, projectsByRole: [] };
    case PROJECT_LIST_BY_ROLE_SUCCESS:
      return { ...state, projectsByRole: payload.projectsByRole };
    case PROJECT_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case PROJECT_DELETE_SUCCESS:
      return {
        ...state,
        projects: state.projects.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
