import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  GENERATE_POS_ID,
  GENERATE_POS_ID_FAILURE,
  GENERATE_POS_ID_SUCCESS,
  POS_CATEGORIES_LIST,
  POS_CATEGORIES_LIST_FAILURE,
  POS_CATEGORIES_LIST_SUCCESS,
  POS_DELETE,
  POS_DELETE_FAILURE,
  POS_DELETE_SUCCESS,
  POS_DETAIL,
  POS_DETAIL_FAILURE,
  POS_DETAIL_SUCCESS,
  POS_ID_GENERATION_CHECK,
  POS_ID_GENERATION_CHECK_FAILURE,
  POS_ID_GENERATION_CHECK_SUCCESS,
  POS_LIST,
  POS_LIST_BY_ROLE,
  POS_LIST_BY_ROLE_FAILURE,
  POS_LIST_BY_ROLE_SUCCESS,
  POS_LIST_FAILURE,
  POS_LIST_SUCCESS,
  POS_NEW,
  POS_NEW_FAILURE,
  POS_NEW_SUCCESS,
  POS_PAYMENT_UPDATE,
  POS_PAYMENT_UPDATE_FAILURE,
  POS_PAYMENT_UPDATE_SUCCESS,
  POS_STATUS_UPDATE,
  POS_UPDATE,
  POS_UPDATE_FAILURE,
  POS_UPDATE_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createPOSAsync,
  deletePOSAsync,
  fetchPOSAsync,
  fetchPOSByRoleAsync,
  fetchPOSCategoriesAsync,
  fetchPOSDetailAsync,
  generatePOSNoAsync,
  updatePOSAsync,
  updatePOSPaymentInfoAsync,
  updatePOStatusAsync,
} from "../../../services/sales/pos.service.js";
import { checkIDGeneratedAsync } from "../../../services/idGenerator.service.js";

export function* watchCheckIdGeneration() {
  yield takeLatest(POS_ID_GENERATION_CHECK, checkIDGenerated);
}

export function* watchGeneratePOSNo() {
  yield takeLatest(GENERATE_POS_ID, generatePOSNo);
}

export function* watchFetchPOS() {
  yield takeLatest(POS_LIST, fetchPOSList);
}

export function* watchFetchPOSByRole() {
  yield takeLatest(POS_LIST_BY_ROLE, fetchPOSListByRole);
}

export function* watchFetchPOSCategories() {
  yield takeLatest(POS_CATEGORIES_LIST, fetchPOSCategories);
}

export function* watchCreatePOS() {
  yield takeLatest(POS_NEW, createNewPOS);
}

export function* watchUpdatePOS() {
  yield takeLatest(POS_UPDATE, updatePOS);
}

export function* watchUpdatePOStatus() {
  yield takeLatest(POS_STATUS_UPDATE, updatePOStatus);
}

export function* watchUpdatePOSPaymentInfo() {
  yield takeLatest(POS_PAYMENT_UPDATE, updatePOSPaymentInfo);
}

export function* watchGetDetailPOS() {
  yield takeLatest(POS_DETAIL, fetchPOSDetail);
}

export function* watchDeletePOS() {
  yield takeLatest(POS_DELETE, deletePOS);
}

function* checkIDGenerated({ payload }) {
  const { moduleSlug } = payload;

  try {
    const response = yield call(checkIDGeneratedAsync, moduleSlug);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: POS_ID_GENERATION_CHECK_SUCCESS,
        payload: { idInfo: response.data },
      });
    } else {
      yield put({ type: POS_ID_GENERATION_CHECK_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_ID_GENERATION_CHECK_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* generatePOSNo({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(generatePOSNoAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: GENERATE_POS_ID_SUCCESS,
        payload: { posNo: response.data },
      });
    } else {
      yield put({ type: GENERATE_POS_ID_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GENERATE_POS_ID_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPOSList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPOSAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: POS_LIST_SUCCESS,
        payload: { pos: response.data },
      });
    } else {
      yield put({ type: POS_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPOSListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPOSByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: POS_LIST_BY_ROLE_SUCCESS,
        payload: { posByRole: response.data },
      });
    } else {
      yield put({ type: POS_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPOSCategories({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchPOSCategoriesAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: POS_CATEGORIES_LIST_SUCCESS,
        payload: { posCategory: response.data },
      });
    } else {
      yield put({ type: POS_CATEGORIES_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_CATEGORIES_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewPOS({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createPOSAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: POS_NEW_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: POS_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePOS({ payload }) {
  const { data, id } = payload;
  
  try {
    const response = yield call(updatePOSAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: POS_UPDATE_SUCCESS,
        payload: { pos: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: POS_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePOStatus({ payload }) {
  const { data, id} = payload;
  
  try {
    const response = yield call(updatePOStatusAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: POS_UPDATE_SUCCESS,
        payload: { pos: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: POS_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updatePOSPaymentInfo({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updatePOSPaymentInfoAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: POS_PAYMENT_UPDATE_SUCCESS,
        payload: { pos: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: POS_PAYMENT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_PAYMENT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchPOSDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchPOSDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: POS_DETAIL_SUCCESS,
        payload: { pos: response.data },
      });
    } else {
      yield put({ type: POS_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deletePOS({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deletePOSAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: POS_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: POS_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: POS_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchCheckIdGeneration),
    fork(watchGeneratePOSNo),
    fork(watchFetchPOS),
    fork(watchFetchPOSByRole),
    fork(watchFetchPOSCategories),
    fork(watchCreatePOS),
    fork(watchUpdatePOS),
    fork(watchUpdatePOStatus),
    fork(watchUpdatePOSPaymentInfo),
    fork(watchGetDetailPOS),
    fork(watchDeletePOS),
  ]);
}
