import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import api from "./api"

let response = {
  status: null,
  data: null,
  message: "",
};

// export const fetchRecruitAsync = async (uid) => {

//   try {
//     // const querySnapshot = await api.get(`/recruits?createdBy=${uid}`)
//     const querySnapshot = await api.get(`/recruits`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//     } else {
//       response = {
//         status: 400,
//         message: t("message_recruits_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//     // try {
//     //   const recruitRef = collection(database, "recruits");

//     //   const q = query(recruitRef, where("createdBy", "==", String(uid)));

//     //   const querySnapshot = await getDocs(q);

//     //   if (!querySnapshot.empty) {
//     //     const dataArr = [];
//     //     querySnapshot.forEach((doc) => {
//     //       dataArr.push(doc.data());
//     //     });
//     //     response = {
//     //       status: 200,
//     //       data: dataArr,
//     //       message: t("message_recruits_get_success"),
//     //     };
//     //   } else {
//     //     response = {
//     //       status: 400,
//     //       message: t("message_recruits_not_found"),
//     //     };
//     //   }

//     //   return response;
//     // } catch (error) {
//     //   let message = generateMessage(error.code);
//     //   response = {
//     //     status: 401,
//     //     data: null,
//     //     message: message || error.message,
//     //   };
//     //   return response;
//     // }
// };

// export const createRecruitAsync = async (payload) => {

//   console.log("payload is", payload)
//   try {
//     const querySnapshot = await api.post(`/recruits`, payload)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_recruits_create_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: querySnapshot?.data?.message,
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const recruitRef = collection(database, "recruits");

//   //   const q = query(
//   //     recruitRef,
//   //     where("createdBy", "==", payload?.createdBy),
//   //     where("emailId", "==", payload.emailId)
//   //   );

//   //   const querySnapshot = await getDocs(q);
//   //   if (querySnapshot.empty) {
//   //     const q = query(
//   //       recruitRef,
//   //       where("createdBy", "==", payload?.createdBy),
//   //       where("contactNo", "==", payload.contactNo)
//   //     );
//   //     const querySnapshot = await getDocs(q);
//   //     if (querySnapshot.empty) {
//   //       await setDoc(
//   //         doc(database, "recruits", Number(payload._id).toString()),
//   //         {
//   //           ...payload,
//   //           isOnBoarded: false,
//   //           createdAt: new Date(),
//   //         }
//   //       );
//   //       response = {
//   //         status: 200,
//   //         message: t("message_recruit_new_successful"),
//   //       };
//   //     } else {
//   //       response = {
//   //         status: 401,
//   //         message: t("message_recruit_contactNo_exists"),
//   //       };
//   //     }
//   //   } else {
//   //     response = {
//   //       status: 401,
//   //       message: t("message_recruit_email_exists"),
//   //     };
//   //   }
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const updateRecruitAsync = async (payload, id) => {

//   try {
//     const querySnapshot = await api.put(`/recruits/${id}`, payload)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_recruits_updated_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_recruits_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await updateDoc(doc(database, "recruits", String(id)), {
//   //     ...payload,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_recruit_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const fetchRecruitDetailAsync = async (id) => {

//   try {
//     const querySnapshot = await api.get(`/recruits/${id}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };console.log("payload is", querySnapshot.data)
//     } else {
//       response = {
//         status: 400,
//         message: t("message_recruits_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const docRef = doc(database, "recruits", id);
//   //   const docSnap = await getDoc(docRef);

//   //   if (docSnap.exists()) {
//   //     response = {
//   //       status: 200,
//   //       data: docSnap.data(),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 401,
//   //       data: null,
//   //       message: "recruit details not found",
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const deleteRecruitAsync = async (id) => {

//   try {
//     const querySnapshot = await api.delete(`/recruits/${id}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("recruits_deleted_succeful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_recruits_not_found"),
//       };
//     }
//     return response;
//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 400,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const docRef = doc(database, "recruits", String(id));
//   //   await deleteDoc(docRef);

//   //   response = {
//   //     status: 200,
//   //     message: "recruit deleted succesfully...",
//   //   };

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 400,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

export const fetchRecruitAsync = async (uid) => await api.get(`/recruits`).then((res) => res.data).catch((err) => err);

export const fetchRecruitByRoleAsync = async (uid) => await api.get(`/recruits/assign`).then((res) => res.data).catch((err) => err);

export const createRecruitAsync = async (payload) => await api.post(`/recruits`, payload).then((res) => res.data).catch((err) => err);

export const updateRecruitAsync = async (payload, id) => await api.put(`/recruits/${id}`, payload).then((res) => res.data).catch((err) => err);

export const fetchRecruitDetailAsync = async (id) => await api.get(`/recruits/${id}`).then((res) => res.data).catch((err) => err);

export const deleteRecruitAsync = async (id) => await api.delete(`/recruits/${id}`).then((res) => res.data).catch((err) => err);
