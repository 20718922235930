import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  SUB_CATEGORY_DELETE,
  SUB_CATEGORY_DELETE_FAILURE,
  SUB_CATEGORY_DELETE_SUCCESS,
  SUB_CATEGORY_DETAIL,
  SUB_CATEGORY_DETAIL_FAILURE,
  SUB_CATEGORY_DETAIL_SUCCESS,
  SUB_CATEGORY_LIST,
  SUB_CATEGORY_LIST_BY_ROLE,
  SUB_CATEGORY_LIST_BY_ROLE_FAILURE,
  SUB_CATEGORY_LIST_BY_ROLE_SUCCESS,
  SUB_CATEGORY_LIST_FAILURE,
  SUB_CATEGORY_LIST_SUCCESS,
  SUB_CATEGORY_NEW,
  SUB_CATEGORY_NEW_FAILURE,
  SUB_CATEGORY_NEW_SUCCESS,
  SUB_CATEGORY_UPDATE,
  SUB_CATEGORY_UPDATE_FAILURE,
  SUB_CATEGORY_UPDATE_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createSubCategoryAsync,
  deleteSubCategoryAsync,
  fetchSubCategoryAsync,
  fetchSubCategoryByRoleAsync,
  fetchSubCategoryDetailAsync,
  updateSubCategoryAsync,
} from "../../../services/inventory/sub-category.service.js";

export function* watchFetchSubCategory() {
  yield takeLatest(SUB_CATEGORY_LIST, fetchSubCategoryList);
}

export function* watchFetchSubCategoryByRole() {
  yield takeLatest(SUB_CATEGORY_LIST_BY_ROLE, fetchSubCategoryListByRole);
}

export function* watchCreateSubCategory() {
  yield takeLatest(SUB_CATEGORY_NEW, createNewSubCategory);
}

export function* watchUpdateSubCategory() {
  yield takeLatest(SUB_CATEGORY_UPDATE, updateSubCategory);
}

export function* watchGetDetailSubCategory() {
  yield takeLatest(SUB_CATEGORY_DETAIL, fetchSubCategoryDetail);
}

export function* watchDeleteSubCategory() {
  yield takeLatest(SUB_CATEGORY_DELETE, deleteSubCategory);
}

function* fetchSubCategoryList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchSubCategoryAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SUB_CATEGORY_LIST_SUCCESS,
        payload: { subCategories: response.data },
      });
    } else {
      yield put({ type: SUB_CATEGORY_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUB_CATEGORY_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchSubCategoryListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchSubCategoryByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SUB_CATEGORY_LIST_BY_ROLE_SUCCESS,
        payload: { subCategoriesByRole: response.data },
      });
    } else {
      yield put({ type: SUB_CATEGORY_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUB_CATEGORY_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewSubCategory({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createSubCategoryAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: SUB_CATEGORY_NEW_SUCCESS,
        payload: { data: response.data },
      });
    } else {
      yield put({ type: SUB_CATEGORY_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUB_CATEGORY_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateSubCategory({ payload }) {
  const { data, id } = payload;

  try {
    const response = yield call(updateSubCategoryAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: SUB_CATEGORY_UPDATE_SUCCESS,
        payload: { subCategory: response.data },
      });
    } else {
      yield put({ type: SUB_CATEGORY_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUB_CATEGORY_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchSubCategoryDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchSubCategoryDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SUB_CATEGORY_DETAIL_SUCCESS,
        payload: { subCategory: response.data },
      });
    } else {
      yield put({ type: SUB_CATEGORY_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUB_CATEGORY_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteSubCategory({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteSubCategoryAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SUB_CATEGORY_DELETE_SUCCESS,
        payload: { id: id },
      });      
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SUB_CATEGORY_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SUB_CATEGORY_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchSubCategory),
    fork(watchFetchSubCategoryByRole),
    fork(watchCreateSubCategory),
    fork(watchUpdateSubCategory),
    fork(watchGetDetailSubCategory),
    fork(watchDeleteSubCategory),
  ]);
}
