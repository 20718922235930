import {
  USER_DELETE,
  USER_DELETE_SUCCESS,
  USER_DETAIL,
  USER_DETAIL_SUCCESS,
  USER_LIST,
  USER_LIST_BY_ROLE,
  USER_LIST_BY_ROLE_FAILURE,
  USER_LIST_BY_ROLE_SUCCESS,
  USER_LIST_SUCCESS,
  USER_NEW,
  USER_NEW_SUCCESS,
  USER_UPDATE,
  USER_UPDATE_SUCCESS,
} from "../types";

const initialState = {
  users: [],
  userDetail: null,
  createOrUpdated: false,

  // users by role
  usersByRole: [],
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_DELETE:
    case USER_DETAIL:
    case USER_NEW:
    case USER_UPDATE:
    case USER_LIST:
      return { ...state, createOrUpdated: false, userDetail: null };

    case USER_LIST_SUCCESS:
      return { ...state, users: payload.users };

    case USER_LIST_BY_ROLE:
      return { ...state, usersByRole: [] };
    case USER_LIST_BY_ROLE_SUCCESS:
      return { ...state, usersByRole: payload.usersByRole };
    case USER_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case USER_DETAIL_SUCCESS:
      return { ...state, userDetail: payload.user };

    case USER_DELETE_SUCCESS:
      return {
        ...state,
        users: state.users.filter((item) => item.id !== payload.id),
      };

    case USER_NEW_SUCCESS:
    case USER_UPDATE_SUCCESS:
      return { ...state, createOrUpdated: true };

    default:
      return { ...state };
  }
}
