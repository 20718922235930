import {
  MASTER_LIST,
  MASTER_LIST_SUCCESS,
  CREATE_MASTER_SUCCESS,
  UPDATE_MASTER_SUCCESS,
  UPDATE_MASTER_STATUS_SUCCESS,
  UPDATE_MASTER_STATUS,
  UPDATE_MASTER,
  MASTER_LIST_BY_ROLE,
  MASTER_LIST_BY_ROLE_SUCCESS,
  CREATE_MASTER,
  ADD_NEW_MASTER_SUCCESS,
  ADD_NEW_MASTER,
} from "../types";
import {
  CURRENCY_SLUG,
  DESIGNATION_SLUG,
  EMPLOYMENT_STATUS_SLUG,
  JOB_TYPE_SLUG,
  RECRUITMENT_STATUS_SLUG,
} from "../../config/constants";

const initialState = {
  jobTypeList: [],
  recruitmentStatusList: [],
  employmentStatusList: [],
  designationList: [],
  currencyList: [],
  createdOrUpdated: false,

  jobTypeListByRole: [],
  recruitmentStatusListByRole: [],
  employmentStatusListByRole: [],
  designationListByRole: [],
  currencyListByRole: [],

  jobTypeCreated: false,
  recruitmentStatusCreated: false,
  employmentStatusCreated: false,
  designationCreated: false,
  currencyCreated: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  switch (action.type) {
    case MASTER_LIST:
    case MASTER_LIST_BY_ROLE:
    case CREATE_MASTER:
    case UPDATE_MASTER:
    case UPDATE_MASTER_STATUS:
      return { ...state, createdOrUpdated: false };

    case MASTER_LIST_SUCCESS: {
      const { slug, data } = action.payload;

      if (slug === JOB_TYPE_SLUG) {
        return { ...state, jobTypeList: data };
      } else if (slug === RECRUITMENT_STATUS_SLUG) {
        return { ...state, recruitmentStatusList: data };
      } else if (slug === EMPLOYMENT_STATUS_SLUG) {
        return { ...state, employmentStatusList: data };
      } else if (slug === DESIGNATION_SLUG) {
        return { ...state, designationList: data };
      } else if (slug === CURRENCY_SLUG) {
        return { ...state, currencyList: data };
      }
      return { ...state };
    }

    case MASTER_LIST_BY_ROLE_SUCCESS: {
      const { slug, data } = action.payload;

      if (slug === JOB_TYPE_SLUG) {
        return { ...state, jobTypeListByRole: data };
      } else if (slug === RECRUITMENT_STATUS_SLUG) {
        return { ...state, recruitmentStatusListByRole: data };
      } else if (slug === EMPLOYMENT_STATUS_SLUG) {
        return { ...state, employmentStatusListByRole: data };
      } else if (slug === DESIGNATION_SLUG) {
        return { ...state, designationListByRole: data };
      } else if (slug === CURRENCY_SLUG) {
        return { ...state, currencyListByRole: data };
      }
      return { ...state };
    }

    case ADD_NEW_MASTER: {
      const { slug } = action.payload;
      if (slug === JOB_TYPE_SLUG) {
        return { ...state, jobTypeCreated: false };
      } else if (slug === RECRUITMENT_STATUS_SLUG) {
        return { ...state, recruitmentStatusCreated: false };
      } else if (slug === EMPLOYMENT_STATUS_SLUG) {
        return { ...state, employmentStatusCreated: false };
      } else if (slug === DESIGNATION_SLUG) {
        return { ...state, designationCreated: false };
      } else if (slug === CURRENCY_SLUG) {
        return { ...state, currencyCreated: false };
      }
      return { ...state };
    }

    case ADD_NEW_MASTER_SUCCESS:{
      const { slug, data } = action.payload;

      if (slug === JOB_TYPE_SLUG) {
        return { ...state, jobTypeCreated: true, jobTypeListByRole: [...state.jobTypeListByRole, data], createdOrUpdated: true };
      } else if (slug === RECRUITMENT_STATUS_SLUG) {
        return { ...state, recruitmentStatusCreated: true, recruitmentStatusListByRole: [...state.recruitmentStatusListByRole, data], createdOrUpdated: true };
      } else if (slug === EMPLOYMENT_STATUS_SLUG) {
        return { ...state, employmentStatusCreated: true, employmentStatusListByRole: [...state.employmentStatusListByRole, data], createdOrUpdated: true };
      } else if (slug === DESIGNATION_SLUG) {
        return { ...state, designationCreated: true, designationListByRole: [...state.designationListByRole, data], createdOrUpdated: true };
      } else if (slug === CURRENCY_SLUG) {
        return { ...state, currencyCreated: true, currencyListByRole: [...state.currencyListByRole, data], createdOrUpdated: true };
      }
      return { ...state, createdOrUpdated: true };
    }
    
    case CREATE_MASTER_SUCCESS:
    case UPDATE_MASTER_SUCCESS: {
      return { ...state, createdOrUpdated: true };
    }

    case UPDATE_MASTER_STATUS_SUCCESS: {
      return { ...state, createdOrUpdated: true };
    }

    default:
      return { ...state };
  }
}
