import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  createMastersListAsync,
  deleteMastersListAsync,
  fetchMastersListAsync,
  fetchMastersListDetailAsync,
  updateMastersListAsync,
} from "../../services/addMasters.service";
import { hideLoader, showMessage } from "../lem/action";
import {
  MASTER_LIST_DELETE,
  MASTER_LIST_DELETE_FAILURE,
  MASTER_LIST_DELETE_SUCCESS,
  MASTER_LIST_DETAIL,
  MASTER_LIST_DETAIL_FAILURE,
  MASTER_LIST_DETAIL_SUCCESS,
  MASTER_LIST_ALL,
  MASTER_LIST_ALL_FAILURE,
  MASTER_LIST_ALL_SUCCESS,
  MASTER_LIST_NEW,
  MASTER_LIST_NEW_FAILURE,
  MASTER_LIST_NEW_SUCCESS,
  MASTER_LIST_UPDATE,
  MASTER_LIST_UPDATE_FAILURE,
  MASTER_LIST_UPDATE_SUCCESS,
} from "../types";

export function* watchFetchMastersListAll() {
  yield takeLatest(MASTER_LIST_ALL, fetchMastersListAll);
}

export function* watchCreateMastersList() {
  yield takeLatest(MASTER_LIST_NEW, createMastersList);
}

export function* watchUpdateMastersList() {
  yield takeLatest(MASTER_LIST_UPDATE, updateMastersList);
}

export function* watchGetDetailMastersList() {
  yield takeLatest(MASTER_LIST_DETAIL, fetchMastersListDetail);
}

export function* watchDeleteMastersList() {
  yield takeLatest(MASTER_LIST_DELETE, deleteMastersList);
}

function* fetchMastersListAll({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(fetchMastersListAsync, uid);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: MASTER_LIST_ALL_SUCCESS,
        payload: { masterLists: response.data },
      });
    } else {
      yield put({ type: MASTER_LIST_ALL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: MASTER_LIST_ALL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createMastersList({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createMastersListAsync, data);
    yield put(hideLoader());
    // console.log("responce", response.message)
    if (response.status === 200) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: MASTER_LIST_NEW_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: MASTER_LIST_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: MASTER_LIST_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateMastersList({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateMastersListAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200) {
      // yield put(showMessage("success", response.message));      
      yield put({
        type: MASTER_LIST_UPDATE_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: MASTER_LIST_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: MASTER_LIST_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchMastersListDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchMastersListDetailAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: MASTER_LIST_DETAIL_SUCCESS,
        payload: { MasterList: response.data },
      });
    } else {
      yield put({ type: MASTER_LIST_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: MASTER_LIST_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteMastersList({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteMastersListAsync, id);
    yield put(hideLoader());
    if (response.status === 200) {
      yield put({
        type: MASTER_LIST_DELETE_SUCCESS,
        payload: { id: id },
      });
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: MASTER_LIST_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: MASTER_LIST_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchMastersListAll),
    fork(watchCreateMastersList),
    fork(watchUpdateMastersList),
    fork(watchGetDetailMastersList),
    fork(watchDeleteMastersList),
  ]);
}
