import {
  ADD_NEW_SUPPLIER,
  ADD_NEW_SUPPLIER_SUCCESS,
  SUPPLIER_DELETE_SUCCESS,
  SUPPLIER_DETAIL,
  SUPPLIER_DETAIL_SUCCESS,
  SUPPLIER_LIST,
  SUPPLIER_LIST_BY_ROLE,
  SUPPLIER_LIST_BY_ROLE_FAILURE,
  SUPPLIER_LIST_BY_ROLE_SUCCESS,
  SUPPLIER_LIST_FAILURE,
  SUPPLIER_LIST_SUCCESS,
  SUPPLIER_NEW,
  SUPPLIER_NEW_SUCCESS,
  SUPPLIER_UPDATE,
  SUPPLIER_UPDATE_SUCCESS,
} from "../../types.js";

const initialState = {
  suppliers: [],
  supplierDetail: null,
  createOrUpdated: false,

  // Brands by role
  suppliersByRole: [],

  supplierAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SUPPLIER_LIST:
    case SUPPLIER_NEW:
    case SUPPLIER_UPDATE:
    case SUPPLIER_DETAIL:
      return {
        ...state,
        createOrUpdated: false,
        supplierDetail: null,
      };

    case SUPPLIER_NEW_SUCCESS:
    case SUPPLIER_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case SUPPLIER_DETAIL_SUCCESS: {
      return { ...state, supplierDetail: payload.supplier };
    }

    case ADD_NEW_SUPPLIER:
      return {
        ...state,
        supplierAdded: false,
        newSupplierData: null,
      };

    case ADD_NEW_SUPPLIER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        createOrUpdated: true,
        supplierAdded: true,
        newSupplierData: data,
        suppliersByRole: [...state.suppliersByRole, data],
      };
    }

    case SUPPLIER_LIST_SUCCESS:
      return { ...state, suppliers: payload.suppliers };
    case SUPPLIER_LIST_FAILURE:
      return { ...state };

    case SUPPLIER_LIST_BY_ROLE:
      return { ...state, suppliersByRole: [], 
        supplierAdded: false,
        newSupplierData: null, };
        
    case SUPPLIER_LIST_BY_ROLE_SUCCESS:
      return { ...state, suppliersByRole: payload.suppliersByRole };
    case SUPPLIER_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case SUPPLIER_DELETE_SUCCESS:
      return {
        ...state,
        suppliers: state.suppliers.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
