import api from "./api"
// export const fetchRolesListAsync = async (uid) => {

//   try {
//     // const querySnapshot = await api.get(`/role?createdBy=${uid}`)
//     const querySnapshot = await api.get(`/role`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };
//     } else {
//       response = {
//         status: 400,
//         message: t("message_roles_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const roleRef = collection(database, "roles");

//   //   const q = query(roleRef, where("createdBy", "==", String(uid)));

//   //   const querySnapshot = await getDocs(q);

//   //   if (!querySnapshot.empty) {
//   //     const dataArr = [];
//   //     querySnapshot.forEach((doc) => {
//   //       dataArr.push(doc.data());
//   //     });
//   //     response = {
//   //       status: 200,
//   //       data: dataArr,
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 400,
//   //       message: t("message_roles_not_found"),
//   //     };
//   //   }
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }

// };

// export const createRolesAsync = async (payload) => {

//   try {
//     const querySnapshot = await api.post(`/role`, payload)
//     // console.log("querySnapshot", querySnapshot)
//     // if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_roles_rights_create_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: querySnapshot?.data?.message,
//       };
//     }
//     return response;

//   } catch (error) {
//     // console.log("error", error);
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const roleRef = collection(database, "roles");
//   //   const q = query(
//   //     roleRef,
//   //     where("createdBy", "==", String(payload.createdBy)),
//   //     where("roleName", "==", String(payload.roleName))
//   //   );

//   //   const querySnapshot = await getDocs(q);
//   //   if (!querySnapshot.empty) {
//   //     response = {
//   //       status: 409,
//   //       message: "Role already exists",
//   //     };
//   //   } else {
//   //     await setDoc(doc(database, "roles", Number(payload._id).toString()), {
//   //       ...payload,
//   //       createdAt: new Date(),
//   //     });
//   //     response = {
//   //       status: 200,
//   //       message: t("message_role_new_successful"),
//   //     };
//   //   }
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }

// };

// export const updateRolesAsync = async (payload, id) => {

//   try {
//     const querySnapshot = await api.put(`/role/${id}`, payload)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_roles_rights_updated_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_roles_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await updateDoc(doc(database, "roles", String(id)), {
//   //     ...payload,
//   //     lastModified: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_role_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }

// };

// export const fetchRolesDetailAsync = async (id) => {

//   try {
//     const querySnapshot = await api.get(`/role/${id}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         data: querySnapshot?.data?.data,
//       };console.log("payload is", querySnapshot.data)
//     } else {
//       response = {
//         status: 400,
//         message: t("message_roles_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const roleRef = collection(database, "roles");

//   //   const docRef = doc(database, "roles", id);
//   //   const docSnap = await getDoc(docRef);

//   //   if (docSnap.exists()) {
//   //     response = {
//   //       status: 200,
//   //       data: docSnap.data(),
//   //     };
//   //   } else {
//   //     response = {
//   //       status: 401,
//   //       data: null,
//   //       message: "role details not found",
//   //     };
//   //   }

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }

// };

// export const deleteRolesAsync = async (id) => {

//   try {
//     const querySnapshot = await api.delete(`/role/${id}`)
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("role_deleted_succeful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_roles_not_found"),
//       };
//     }
//     return response;
//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 400,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const docRef = doc(database, "roles", String(id));
//   //   await deleteDoc(docRef);

//   //   response = {
//   //     status: 200,
//   //     message: "Role deleted succesfully...",
//   //   };

//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 400,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }

// };

export const fetchRolesListAsync = async (uid) => await api.get(`/role`).then((res) => res.data).catch((err) => err);

export const fetchRolesListByRoleAsync = async (uid) => await api.get(`/role/assign`).then((res) => res.data).catch((err) => err);

export const createRolesAsync = async (payload) => await api.post(`/role`, payload).then((res) => res.data).catch((err) => err);

export const updateRolesAsync = async (payload, id) => await api.put(`/role/${id}`, payload).then((res) => res.data).catch((err) => err);

export const fetchRolesDetailAsync = async (id) => await api.get(`/role/${id}`).then((res) => res.data).catch((err) => err);

export const deleteRolesAsync = async (id) => await api.delete(`/role/${id}`).then((res) => res.data).catch((err) => err);
