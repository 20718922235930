import api from "./api";

export const fetchAdminAsync = async (payload) =>
  await api
    .get(`/admins`)
    .then((res) => res.data)
    .catch((err) => err);

export const createAdminAsync = async (payload) =>
  await api
    .post(`/auth/register`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateAdminAsync = async (payload, id) =>
  await api
    .put(`/admins/${id}?email=${payload.companyProfile.email}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateAdminStatusAsync = async (payload, id) =>
  await api
    .put(`/admins/status/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchAdminDetailAsync = async (id, email) =>
  await api
    .get(`/admins/${id}?email=${email}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteAdminAsync = async (id) =>
  await api
    .delete(`/admins/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
