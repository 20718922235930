import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  ADD_NEW_CUSTOMER,
  ADD_NEW_CUSTOMER_FAILURE,
  ADD_NEW_CUSTOMER_SUCCESS,
  CUSTOMER_DELETE,
  CUSTOMER_DELETE_FAILURE,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DETAIL,
  CUSTOMER_DETAIL_FAILURE,
  CUSTOMER_DETAIL_SUCCESS,
  CUSTOMER_LIST,
  CUSTOMER_LIST_BY_ROLE,
  CUSTOMER_LIST_BY_ROLE_FAILURE,
  CUSTOMER_LIST_BY_ROLE_SUCCESS,
  CUSTOMER_LIST_FAILURE,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_NEW,
  CUSTOMER_NEW_FAILURE,
  CUSTOMER_NEW_SUCCESS,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_FAILURE,
  CUSTOMER_UPDATE_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action";
import {
  createCustomerAsync,
  deleteCustomerAsync,
  fetchCustomerAsync,
  fetchCustomerByRoleAsync,
  fetchCustomerDetailAsync,
  updateCustomerAsync,
} from "../../services/account/customer.service.js";

export function* watchFetchCustomerByRole() {
  yield takeLatest(CUSTOMER_LIST_BY_ROLE, fetchCustomerByRole);
}

export function* watchFetchCustomer() {
  yield takeLatest(CUSTOMER_LIST, fetchCustomer);
}

export function* watchCreateCustomer() {
  yield takeLatest(CUSTOMER_NEW, createNewCustomer);
}

export function* watchUpdateCustomer() {
  yield takeLatest(CUSTOMER_UPDATE, updateCustomer);
}

export function* watchGetDetailCustomer() {
  yield takeLatest(CUSTOMER_DETAIL, fetchCustomerDetail);
}

export function* watchDeleteCustomer() {
  yield takeLatest(CUSTOMER_DELETE, deleteCustomer);
}

export function* watchAddNewCustomer() {
  yield takeLatest(ADD_NEW_CUSTOMER, addNewCustomer);
}

function* fetchCustomer({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchCustomerAsync, payload);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CUSTOMER_LIST_SUCCESS,
        payload: { customers: response.data },
      });
    } else {
      yield put({ type: CUSTOMER_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CUSTOMER_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchCustomerByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchCustomerByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CUSTOMER_LIST_BY_ROLE_SUCCESS,
        payload: { customersByRole: response.data },
      });
    } else {
      yield put({ type: CUSTOMER_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CUSTOMER_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewCustomer({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createCustomerAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: CUSTOMER_NEW_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: CUSTOMER_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CUSTOMER_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateCustomer({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateCustomerAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: CUSTOMER_UPDATE_SUCCESS,
        payload: { customer: response.data },
      });
    } else {
      yield put({ type: CUSTOMER_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CUSTOMER_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchCustomerDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchCustomerDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CUSTOMER_DETAIL_SUCCESS,
        payload: { customer: response.data },
      });
    } else {
      yield put({ type: CUSTOMER_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CUSTOMER_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteCustomer({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteCustomerAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));      
      yield put({
        type: CUSTOMER_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: CUSTOMER_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CUSTOMER_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* addNewCustomer({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createCustomerAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: ADD_NEW_CUSTOMER_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ADD_NEW_CUSTOMER_FAILURE });
      yield put(showMessage("error", typeof response.message != "string" ? response.message?.toString() : response.message));
    }
  } catch (error) {
    yield put({ type: ADD_NEW_CUSTOMER_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchCustomerByRole),
    fork(watchFetchCustomer),
    fork(watchCreateCustomer),
    fork(watchUpdateCustomer),
    fork(watchGetDetailCustomer),
    fork(watchDeleteCustomer),
    fork(watchAddNewCustomer),
  ]);
}
