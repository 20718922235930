import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  SALES_RETURN_DELETE,
  SALES_RETURN_DELETE_FAILURE,
  SALES_RETURN_DELETE_SUCCESS,
  SALES_RETURN_DETAIL,
  SALES_RETURN_DETAIL_FAILURE,
  SALES_RETURN_DETAIL_SUCCESS,
  SALES_RETURN_LIST,
  SALES_RETURN_LIST_BY_ROLE,
  SALES_RETURN_LIST_BY_ROLE_FAILURE,
  SALES_RETURN_LIST_BY_ROLE_SUCCESS,
  SALES_RETURN_LIST_FAILURE,
  SALES_RETURN_LIST_SUCCESS,
  SALES_RETURN_NEW,
  SALES_RETURN_NEW_FAILURE,
  SALES_RETURN_NEW_SUCCESS,
  SALES_RETURN_PAYMENT_UPDATE,
  SALES_RETURN_PAYMENT_UPDATE_FAILURE,
  SALES_RETURN_PAYMENT_UPDATE_SUCCESS,
  SALES_RETURN_STATUS_UPDATE,
  SALES_RETURN_UPDATE,
  SALES_RETURN_UPDATE_FAILURE,
  SALES_RETURN_UPDATE_SUCCESS,
} from "../../types.js";

import { hideLoader, showMessage } from "../../lem/action.js";
import {
  createSalesReturnAsync,
  deleteSalesReturnAsync,
  fetchSalesReturnAsync,
  fetchSalesReturnByRoleAsync,
  fetchSalesReturnDetailAsync,
  updateSalesReturnAsync,
  updateSalesReturnPaymentInfoAsync,
  updateSalesReturnStatusAsync,
} from "../../../services/sales/sales-return.service.js";

export function* watchFetchSalesReturn() {
  yield takeLatest(SALES_RETURN_LIST, fetchSalesReturnList);
}

export function* watchFetchSalesReturnByRole() {
  yield takeLatest(SALES_RETURN_LIST_BY_ROLE, fetchSalesReturnListByRole);
}

export function* watchCreateSalesReturn() {
  yield takeLatest(SALES_RETURN_NEW, createNewSalesReturn);
}

export function* watchUpdateSalesReturn() {
  yield takeLatest(SALES_RETURN_UPDATE, updateSalesReturn);
}

export function* watchUpdateSalesReturnStatus() {
  yield takeLatest(SALES_RETURN_STATUS_UPDATE, updateSalesReturnStatus);
}

export function* watchUpdateSalesReturnPaymentInfo() {
  yield takeLatest(SALES_RETURN_PAYMENT_UPDATE, updateSalesReturnPaymentInfo);
}

export function* watchGetDetailSalesReturn() {
  yield takeLatest(SALES_RETURN_DETAIL, fetchSalesReturnDetail);
}

export function* watchDeleteSalesReturn() {
  yield takeLatest(SALES_RETURN_DELETE, deleteSalesReturn);
}

function* fetchSalesReturnList({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchSalesReturnAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SALES_RETURN_LIST_SUCCESS,
        payload: { salesReturns: response.data },
      });
    } else {
      yield put({ type: SALES_RETURN_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_RETURN_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchSalesReturnListByRole({ payload }) {
  // const { uid } = payload;
  try {
    const response = yield call(fetchSalesReturnByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SALES_RETURN_LIST_BY_ROLE_SUCCESS,
        payload: { salesReturnsByRole: response.data },
      });
    } else {
      yield put({ type: SALES_RETURN_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_RETURN_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewSalesReturn({ payload }) {
  const { data } = payload;

  try {
    const response = yield call(createSalesReturnAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_RETURN_NEW_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_RETURN_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_RETURN_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateSalesReturn({ payload }) {
  const { data, id } = payload;
  
  try {
    const response = yield call(updateSalesReturnAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_RETURN_UPDATE_SUCCESS,
        payload: { salesReturn: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_RETURN_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_RETURN_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateSalesReturnStatus({ payload }) {
  const { data, id} = payload;
  
  try {
    const response = yield call(updateSalesReturnStatusAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_RETURN_UPDATE_SUCCESS,
        payload: { salesReturn: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_RETURN_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_RETURN_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateSalesReturnPaymentInfo({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateSalesReturnPaymentInfoAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_RETURN_PAYMENT_UPDATE_SUCCESS,
        payload: { salesReturn: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_RETURN_PAYMENT_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_RETURN_PAYMENT_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchSalesReturnDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchSalesReturnDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: SALES_RETURN_DETAIL_SUCCESS,
        payload: { salesReturnDetail: response.data },
      });
    } else {
      yield put({ type: SALES_RETURN_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_RETURN_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteSalesReturn({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteSalesReturnAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: SALES_RETURN_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: SALES_RETURN_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: SALES_RETURN_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchSalesReturn),
    fork(watchFetchSalesReturnByRole),
    fork(watchCreateSalesReturn),
    fork(watchUpdateSalesReturn),
    fork(watchUpdateSalesReturnStatus),
    fork(watchUpdateSalesReturnPaymentInfo),
    fork(watchGetDetailSalesReturn),
    fork(watchDeleteSalesReturn),
  ]);
}
