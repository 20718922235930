import { t } from "i18next";
import { generateMessage } from "./firebaseMessage";
import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

// export const fetchProfilesAsync = async (uid) => {
//   try {
//     // const ProfilequerySnapshot = await api.get(`/company-profile?createdBy=${uid}`)
//     const ProfilequerySnapshot = await api.get(`/company-profile`)
//     if (ProfilequerySnapshot?.data.data) {
//       // const BankquerySnapshot = await api.get(`/bank-details?createdBy=${uid}`)
//       const BankquerySnapshot = await api.get(`/bank-details`)
//       // console.log("BankquerySnapshot", BankquerySnapshot);
//       if (BankquerySnapshot?.data.data) {
//         response = {
//           status: 200,
//           data: { ...ProfilequerySnapshot.data?.data , bankDetails: { ...BankquerySnapshot.data?.data } },
//         };

//       } else {
//         response = {
//           status: 200,
//           data: { ...ProfilequerySnapshot.data?.data },
//         };
//       }
//     } else {
//       response = {
//         status: 400,
//         message: t("message_company_profile_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   const docRef = doc(database, "companyProfile", uid);
//   //   const docSnap = await getDoc(docRef);

//   //   if (docSnap.exists()) {
//   //   const bankDetailRef = doc(database, "companyBankDetails", uid);
//   //   const bankDetailsSnap = await getDoc(bankDetailRef);
//   //     if(bankDetailsSnap.exists()){
//   //       response = {
//   //         status: 200,
//   //         data: { profile: { ...docSnap.data() },bankDetails:{...bankDetailsSnap.data()} },
//   //       };
//   //     }else{
//   //     response = {
//   //       status: 200,
//   //       data: { profile: { ...docSnap.data() } },
//   //     };
//   //   }
//   //   } else {
//   //     response = {
//   //       status: 400,
//   //       data: null,
//   //       message: t("message_error_fetching_profile"),
//   //     };
//   //   }
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error,
//   //   };
//   //   return response;
//   // }
// };

// export const addProfileDetailsAsync = async (payload) => {

//   try {
//     const querySnapshot = await api.post(`/company-profile`, payload)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_company_profile_update_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: querySnapshot?.data?.message,
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await setDoc(doc(database, "companyProfile", payload.createdBy), {
//   //     ...payload,
//   //     updatedAt: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_profile_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

// export const addProfileBankDetailsAsync = async (payload) => {

//   try {
//     const querySnapshot = await api.post(`/bank-details`, payload)
//    // console.log("querySnapshot", querySnapshot);
//     if (querySnapshot?.data?.status == 200 || querySnapshot?.data?.status == 201) {
//       response = {
//         status: 200,
//         message: t("message_bank_details_update_successful"),
//       };

//     } else {
//       response = {
//         status: 400,
//         message: t("message_bank_details_not_found"),
//       };
//     }
//     return response;

//   } catch (error) {
//     let message = generateMessage(error.code);
//     response = {
//       status: 401,
//       data: null,
//       message: message || error.message,
//     };
//     return response;
//   }

//   // try {
//   //   await setDoc(doc(database, "companyBankDetails", payload.createdBy), {
//   //     ...payload,
//   //     updatedAt: new Date(),
//   //   });
//   //   response = {
//   //     status: 200,
//   //     message: t("message_profile_update_successful"),
//   //   };
//   //   return response;
//   // } catch (error) {
//   //   let message = generateMessage(error.code);
//   //   response = {
//   //     status: 401,
//   //     data: null,
//   //     message: message || error.message,
//   //   };
//   //   return response;
//   // }
// };

export const fetchProfilesAsync = async (uid) => {
  try {
    // const ProfilequerySnapshot = await api.get(`/company-profile?createdBy=${uid}`)
    const ProfilequerySnapshot = await api.get(`/company-profile`);
    if (ProfilequerySnapshot?.data.data) {
      // const BankquerySnapshot = await api.get(`/bank-details?createdBy=${uid}`)
      const BankquerySnapshot = await api.get(`/bank-details`);
      // console.log("BankquerySnapshot", BankquerySnapshot);
      if (BankquerySnapshot?.data.data) {
        response = {
          status: 200,
          data: {
            ...ProfilequerySnapshot.data?.data,
            bankDetails: { ...BankquerySnapshot.data?.data },
          },
        };
      } else {
        response = {
          status: 200,
          data: { ...ProfilequerySnapshot.data?.data },
        };
      }
    } else {
      response = {
        status: 400,
        message: t("message_company_profile_not_found"),
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }

  // try {
  //   const docRef = doc(database, "companyProfile", uid);
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //   const bankDetailRef = doc(database, "companyBankDetails", uid);
  //   const bankDetailsSnap = await getDoc(bankDetailRef);
  //     if(bankDetailsSnap.exists()){
  //       response = {
  //         status: 200,
  //         data: { profile: { ...docSnap.data() },bankDetails:{...bankDetailsSnap.data()} },
  //       };
  //     }else{
  //     response = {
  //       status: 200,
  //       data: { profile: { ...docSnap.data() } },
  //     };
  //   }
  //   } else {
  //     response = {
  //       status: 400,
  //       data: null,
  //       message: t("message_error_fetching_profile"),
  //     };
  //   }
  //   return response;
  // } catch (error) {
  //   let message = generateMessage(error.code);
  //   response = {
  //     status: 401,
  //     data: null,
  //     message: message || error,
  //   };
  //   return response;
  // }
};

export const fetchProfileByRoleAsync = async (uid) => {
  try {
    // const ProfilequerySnapshot = await api.get(`/company-profile?createdBy=${uid}`)
    const ProfilequerySnapshot = await api.get(`/company-profile/assign`);
    if (ProfilequerySnapshot?.data.data) {
      // const BankquerySnapshot = await api.get(`/bank-details?createdBy=${uid}`)
      const BankquerySnapshot = await api.get(`/bank-details/assign`);
      // console.log("BankquerySnapshot", BankquerySnapshot);
      if (BankquerySnapshot?.data.data) {
        response = {
          status: 200,
          data: {
            ...ProfilequerySnapshot.data?.data,
            bankDetails: { ...BankquerySnapshot.data?.data },
          },
        };
      } else {
        response = {
          status: 200,
          data: { ...ProfilequerySnapshot.data?.data },
        };
      }
    } else {
      response = {
        status: 400,
        message: t("message_company_profile_not_found"),
      };
    }
    return response;
  } catch (error) {
    let message = generateMessage(error.code);
    response = {
      status: 401,
      data: null,
      message: message || error.message,
    };
    return response;
  }

  // try {
  //   const docRef = doc(database, "companyProfile", uid);
  //   const docSnap = await getDoc(docRef);

  //   if (docSnap.exists()) {
  //   const bankDetailRef = doc(database, "companyBankDetails", uid);
  //   const bankDetailsSnap = await getDoc(bankDetailRef);
  //     if(bankDetailsSnap.exists()){
  //       response = {
  //         status: 200,
  //         data: { profile: { ...docSnap.data() },bankDetails:{...bankDetailsSnap.data()} },
  //       };
  //     }else{
  //     response = {
  //       status: 200,
  //       data: { profile: { ...docSnap.data() } },
  //     };
  //   }
  //   } else {
  //     response = {
  //       status: 400,
  //       data: null,
  //       message: t("message_error_fetching_profile"),
  //     };
  //   }
  //   return response;
  // } catch (error) {
  //   let message = generateMessage(error.code);
  //   response = {
  //     status: 401,
  //     data: null,
  //     message: message || error,
  //   };
  //   return response;
  // }
};

export const addProfileDetailsAsync = async (payload) =>
  await api
    .post(`/company-profile`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const addProfileBankDetailsAsync = async (payload) =>
  await api
    .post(`/bank-details`, payload)
    .then((res) => res.data)
    .catch((err) => err);
