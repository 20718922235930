import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  GENERATE_TICKET_ID,
  GENERATE_TICKET_ID_FAILURE,
  GENERATE_TICKET_ID_SUCCESS,
  TICKET_DELETE,
  TICKET_DELETE_FAILURE,
  TICKET_DELETE_SUCCESS,
  TICKET_DETAIL,
  TICKET_DETAIL_FAILURE,
  TICKET_DETAIL_SUCCESS,
  TICKET_LIST,
  TICKET_LIST_BY_ROLE,
  TICKET_LIST_BY_ROLE_FAILURE,
  TICKET_LIST_BY_ROLE_SUCCESS,
  TICKET_LIST_FAILURE,
  TICKET_LIST_SUCCESS,
  TICKET_NEW,
  TICKET_NEW_FAILURE,
  TICKET_NEW_SUCCESS,
  TICKET_UPDATE,
  TICKET_UPDATE_FAILURE,
  TICKET_UPDATE_SUCCESS,
} from "../types.js";

import { hideLoader, showMessage } from "../lem/action";
import {
  createTicketAsync,
  deleteTicketAsync,
  fetchTicketAsync,
  fetchTicketByRoleAsync,
  fetchTicketDetailAsync,
  generateTicketIDAsync,
  updateTicketAsync,
} from "../../services/ticket.service";

export function* watchFetchTicket() {
  yield takeLatest(TICKET_LIST, fetchTicket);
}

export function* watchFetchTicketByRole() {
  yield takeLatest(TICKET_LIST_BY_ROLE, fetchTicketByRole);
}

export function* watchGenerateTicketID() {
  yield takeLatest(GENERATE_TICKET_ID, generateTicketID);
}

export function* watchCreateTicket() {
  yield takeLatest(TICKET_NEW, createNewTicket);
}

export function* watchUpdateTicket() {
  yield takeLatest(TICKET_UPDATE, updateTicket);
}

export function* watchGetDetailTicket() {
  yield takeLatest(TICKET_DETAIL, fetchTicketDetail);
}

export function* watchDeleteTicket() {
  yield takeLatest(TICKET_DELETE, deleteTicket);
}

function* fetchTicket({ payload }) {
  const { uid, haveBeenAssigned } = payload;
  try {
    const response = yield call(fetchTicketAsync, uid, haveBeenAssigned);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: TICKET_LIST_SUCCESS,
        payload: { tickets: response.data },
      });
    } else {
      yield put({ type: TICKET_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: TICKET_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchTicketByRole({ payload }) {
  const { uid, haveBeenAssigned } = payload;
  try {
    const response = yield call(fetchTicketByRoleAsync, uid, haveBeenAssigned);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: TICKET_LIST_BY_ROLE_SUCCESS,
        payload: { ticketsByRole: response.data },
      });
    } else {
      yield put({ type: TICKET_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: TICKET_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* generateTicketID({ payload }) {
  const { uid } = payload;
  try {
    const response = yield call(generateTicketIDAsync, uid);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: GENERATE_TICKET_ID_SUCCESS,
        payload: { ticketID: response.data },
      });
    } else {
      yield put({ type: GENERATE_TICKET_ID_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: GENERATE_TICKET_ID_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewTicket({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createTicketAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: TICKET_NEW_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: TICKET_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: TICKET_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateTicket({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateTicketAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: TICKET_UPDATE_SUCCESS,
        payload: { ticket: response.data },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: TICKET_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: TICKET_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchTicketDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchTicketDetailAsync, id);
    // console.log(response.data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: TICKET_DETAIL_SUCCESS,
        payload: { ticket: response.data },
      });
    } else {
      yield put({ type: TICKET_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: TICKET_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteTicket({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(deleteTicketAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: TICKET_DELETE_SUCCESS,
        payload: { id: id },
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: TICKET_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: TICKET_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchTicket),
    fork(watchFetchTicketByRole),
    fork(watchGenerateTicketID),
    fork(watchCreateTicket),
    fork(watchUpdateTicket),
    fork(watchGetDetailTicket),
    fork(watchDeleteTicket),
  ]);
}
