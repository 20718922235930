import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";
import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchHolidayAsync = async (uid) =>
  await api
    .get(`/holidays`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchHolidayByRoleAsync = async (uid) =>
  await api
    .get(`/holidays/assign`)
    .then((res) => res.data)
    .catch((err) => err);

// export const generateHolidayNoAsync = async (uid) => {
//   const response = await generateID(uid, "Employee");
//   return response;
// };

export const createHolidayAsync = async (payload) =>
  await api
    .post(`/holidays`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateHolidayAsync = async (payload, id) =>
  await api
    .put(`/holidays/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchHolidayDetailAsync = async (id) =>
  await api
    .get(`/holidays/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteHolidayAsync = async (id, recruitId) =>
  api
    .delete(`/holidays/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
