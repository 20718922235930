import {
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";
import { t } from "i18next";
import { database } from "../config/firebase-config";
import { generateMessage } from "./firebaseMessage";
import { generateID } from "./idGenerator.service";
import api from "./api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchEmployeeAttendanceAsync = async (uid) =>
  await api
    .get(`/employee-attendances`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchEmployeeAttendanceByRoleAsync = async (uid) =>
  await api
    .get(`/employee-attendances/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const generateSalarySlipNoAsync = async (uid) => {
  const response = await generateID(uid, "Salary slip");
  return response;
};

export const fetchAttendanceReportAsync = async (employee, monthAndYear) =>
  await api
    .get(`/attendance-report?${monthAndYear}${employee}`)
    .then((res) => res.data)
    .catch((err) => err);

export const createEmployeeAttendanceAsync = async (payload) =>
  await api
    .post(`/employee-attendances`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateEmployeeAttendanceAsync = async (payload, id) =>
  await api
    .put(`/employee-attendances/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchEmployeeAttendanceDetailAsync = async (id) =>
  await api
    .get(`/employee-attendances/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteEmployeeAttendanceAsync = async (
  id,
  attendanceId,
  employeeId
) =>
  await api
    .delete(`/employee-attendances/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
