import {
  SALES_DELETE_SUCCESS,
  SALES_DETAIL,
  SALES_DETAIL_SUCCESS,
  SALES_LIST,
  SALES_LIST_BY_ROLE,
  SALES_LIST_BY_ROLE_FAILURE,
  SALES_LIST_BY_ROLE_SUCCESS,
  SALES_LIST_FAILURE,
  SALES_LIST_SUCCESS,
  SALES_NEW,
  SALES_NEW_SUCCESS,
  SALES_PAYMENT_UPDATE,
  SALES_PAYMENT_UPDATE_SUCCESS,
  SALES_STATUS_UPDATE,
  SALES_STATUS_UPDATE_SUCCESS,
  SALES_STOCK_REPORT_LIST_FAILURE,
  SALES_STOCK_REPORT_LIST_SUCCESS,
  SALES_UPDATE,
  SALES_UPDATE_SUCCESS,
  STOCK_REPORT_LIST,
} from "../../types.js";

const initialState = {
  sales: [],
  saleOrders: [],
  salesDetail: null,
  createOrUpdated: false,
  paymentUpdated: false,

  // Brands by role
  salesByRole: [],

  saleAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SALES_LIST:
    case STOCK_REPORT_LIST:
    case SALES_UPDATE:
    case SALES_STATUS_UPDATE:
    case SALES_DETAIL:
    case SALES_PAYMENT_UPDATE:
      return {
        ...state,
        createOrUpdated: false,
        paymentUpdated: false,
        salesDetail: null,
        saleOrders: [],
      };

    case SALES_NEW:
      return {
        ...state,
        saleAdded: false,
        newProductData: null,
      };

    case SALES_NEW_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        createOrUpdated: true,
        saleAdded: true,
        newProductData: data,
        salesByRole: [...state.salesByRole, data],
      };
    }

    case SALES_STATUS_UPDATE_SUCCESS:
    case SALES_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case SALES_PAYMENT_UPDATE_SUCCESS: {
      return { ...state, paymentUpdated: true };
    }

    case SALES_DETAIL_SUCCESS: {
      return { ...state, salesDetail: payload.sale };
    }

    case SALES_LIST_SUCCESS:
      return { ...state, sales: payload.sales };
    case SALES_LIST_FAILURE:
      return { ...state, sales: [] };

    case SALES_STOCK_REPORT_LIST_SUCCESS:
      return { ...state, saleOrders: payload.saleOrders };
    case SALES_STOCK_REPORT_LIST_FAILURE:
      return { ...state, saleOrders: [] };

    case SALES_LIST_BY_ROLE:
      return { ...state, salesByRole: [] };
    case SALES_LIST_BY_ROLE_SUCCESS:
      return { ...state, salesByRole: payload.salesByRole };
    case SALES_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case SALES_DELETE_SUCCESS:
      return {
        ...state,
        sales: state.sales.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
