import { all, call, put, fork, takeLatest } from "redux-saga/effects";
import {
  createCodeSetupAsync,
  deleteCodeSetupAsync,
  fetchCodeSetupAsync,
  fetchCodeSetupByRoleAsync,
  fetchCodeSetupDetailAsync,
  updateCodeSetupAsync,
} from "../../services/codeSetup.service";
import { hideLoader, showMessage } from "../lem/action";
import {
  ADD_NEW_CODE_SETUP,
  ADD_NEW_CODE_SETUP_FAILURE,
  ADD_NEW_CODE_SETUP_SUCCESS,
  CODE_SETUP_DELETE,
  CODE_SETUP_DELETE_FAILURE,
  CODE_SETUP_DELETE_SUCCESS,
  CODE_SETUP_DETAIL,
  CODE_SETUP_DETAIL_FAILURE,
  CODE_SETUP_DETAIL_SUCCESS,
  CODE_SETUP_LIST,
  CODE_SETUP_LIST_BY_ROLE,
  CODE_SETUP_LIST_BY_ROLE_FAILURE,
  CODE_SETUP_LIST_BY_ROLE_SUCCESS,
  CODE_SETUP_LIST_FAILURE,
  CODE_SETUP_LIST_SUCCESS,
  CODE_SETUP_NEW,
  CODE_SETUP_NEW_FAILURE,
  CODE_SETUP_NEW_SUCCESS,
  CODE_SETUP_UPDATE,
  CODE_SETUP_UPDATE_FAILURE,
  CODE_SETUP_UPDATE_SUCCESS,
} from "../types";

export function* watchFetchCodeSetupList() {
  yield takeLatest(CODE_SETUP_LIST, fetchCodeSetupList);
}

export function* watchFetchCodeSetupListByRole() {
  yield takeLatest(CODE_SETUP_LIST_BY_ROLE, fetchCodeSetupListByRole);
}

export function* watchCreateCodeSetup() {
  yield takeLatest(CODE_SETUP_NEW, createNewCodeSetup);
}

export function* watchAddNewCodeSetup() {
  yield takeLatest(ADD_NEW_CODE_SETUP, addNewCodeSetup);
}

export function* watchUpdateCodeSetup() {
  yield takeLatest(CODE_SETUP_UPDATE, updateCodeSetup);
}

export function* watchGetDetailCodeSetup() {
  yield takeLatest(CODE_SETUP_DETAIL, fetchCodeSetupDetail);
}

export function* watchDeleteCodeSetup() {
  yield takeLatest(CODE_SETUP_DELETE, deleteCodeSetup);
}

function* fetchCodeSetupList({ payload }) {
  // const { uid } = payload;
  try {
    // const response = yield call(fetchCodeSetupAsync, uid);
    const response = yield call(fetchCodeSetupAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CODE_SETUP_LIST_SUCCESS,
        payload: { codeSetups: response.data },
      });
    } else {
      yield put({ type: CODE_SETUP_LIST_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_LIST_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchCodeSetupListByRole({ payload }) {
  // const { uid } = payload;
  try {
    // const response = yield call(fetchCodeSetupAsync, uid);
    const response = yield call(fetchCodeSetupByRoleAsync);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CODE_SETUP_LIST_BY_ROLE_SUCCESS,
        payload: { codeSetupsByRole: response.data },
      });
    } else {
      yield put({ type: CODE_SETUP_LIST_BY_ROLE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_LIST_BY_ROLE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* createNewCodeSetup({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createCodeSetupAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: CODE_SETUP_NEW_SUCCESS,
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: CODE_SETUP_NEW_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_NEW_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* addNewCodeSetup({ payload }) {
  const { data } = payload;
  try {
    const response = yield call(createCodeSetupAsync, data);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put({
        type: ADD_NEW_CODE_SETUP_SUCCESS,
        payload: {data: response.data},
      });
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: ADD_NEW_CODE_SETUP_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: ADD_NEW_CODE_SETUP_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* updateCodeSetup({ payload }) {
  const { data, id } = payload;
  try {
    const response = yield call(updateCodeSetupAsync, data, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
      yield put({
        type: CODE_SETUP_UPDATE_SUCCESS,
      });
    } else {
      yield put({ type: CODE_SETUP_UPDATE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_UPDATE_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* fetchCodeSetupDetail({ payload }) {
  const { id } = payload;
  try {
    const response = yield call(fetchCodeSetupDetailAsync, id);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CODE_SETUP_DETAIL_SUCCESS,
        payload: { codeSetup: response.data },
      });
    } else {
      yield put({ type: CODE_SETUP_DETAIL_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_DETAIL_FAILURE });
    yield put(showMessage("error", error));
  }
}

function* deleteCodeSetup({ payload }) {
  const { id, moduleSlug } = payload;
  try {
    const response = yield call(deleteCodeSetupAsync, id, moduleSlug);
    yield put(hideLoader());
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: CODE_SETUP_DELETE_SUCCESS,
        payload: { id: id },
      });
      // yield put(showMessage("success", response.message));
      yield put(showMessage("info", response.message));
    } else {
      yield put({ type: CODE_SETUP_DELETE_FAILURE });
      yield put(showMessage("error", response.message));
    }
  } catch (error) {
    yield put({ type: CODE_SETUP_DELETE_FAILURE });
    yield put(showMessage("error", error));
  }
}

export default function* rootSaga() {
  yield all([
    fork(watchFetchCodeSetupList),
    fork(watchFetchCodeSetupListByRole),
    fork(watchCreateCodeSetup),
    fork(watchAddNewCodeSetup),
    fork(watchUpdateCodeSetup),
    fork(watchGetDetailCodeSetup),
    fork(watchDeleteCodeSetup),
  ]);
}
