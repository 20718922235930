import api from "../api";

let response = {
  status: null,
  data: null,
  message: "",
};

export const fetchBrandAsync = async (uid) =>
  await api
    .get(`/brands`)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchBrandByRoleAsync = async (uid) =>
  await api
    .get(`/brands/assign`)
    .then((res) => res.data)
    .catch((err) => err);

export const createBrandAsync = async (payload) =>
  await api
    .post(`/brands`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const updateBrandAsync = async (payload, id) =>
  await api
    .put(`/brands/${id}`, payload)
    .then((res) => res.data)
    .catch((err) => err);

export const fetchBrandDetailAsync = async (id) =>
  await api
    .get(`/brands/${id}`)
    .then((res) => res.data)
    .catch((err) => err);

export const deleteBrandAsync = async (id) =>
  await api
    .delete(`/brands/${id}`)
    .then((res) => res.data)
    .catch((err) => err);
