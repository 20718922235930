import {
  ADD_NEW_CUSTOMER,
  ADD_NEW_CUSTOMER_SUCCESS,
  CUSTOMER_DELETE_SUCCESS,
  CUSTOMER_DETAIL,
  CUSTOMER_DETAIL_SUCCESS,
  CUSTOMER_LIST,
  CUSTOMER_LIST_BY_ROLE,
  CUSTOMER_LIST_BY_ROLE_FAILURE,
  CUSTOMER_LIST_BY_ROLE_SUCCESS,
  CUSTOMER_LIST_FAILURE,
  CUSTOMER_LIST_SUCCESS,
  CUSTOMER_NEW,
  CUSTOMER_NEW_SUCCESS,
  CUSTOMER_UPDATE,
  CUSTOMER_UPDATE_SUCCESS,
} from "../types.js";

const initialState = {
  customers: [],
  customerDetail: null,
  createOrUpdated: false,

  // Customers by role
  customersByRole: [],
  customerAdded: false,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case CUSTOMER_LIST:
    case CUSTOMER_NEW:
    case CUSTOMER_UPDATE:
    case CUSTOMER_DETAIL:
      return { ...state, createOrUpdated: false, customerDetail: null };

    case CUSTOMER_NEW_SUCCESS:
    case CUSTOMER_UPDATE_SUCCESS: {
      return { ...state, createOrUpdated: true };
    }

    case ADD_NEW_CUSTOMER:
      return {
        ...state,
        customerAdded: false,
        newCustomerData: null,
      };

    case ADD_NEW_CUSTOMER_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        createOrUpdated: true,
        customerAdded: true,
        newCustomerData: data,
        customersByRole: [...state.customersByRole, data],
      };
    }

    case CUSTOMER_DETAIL_SUCCESS: {
      return { ...state, customerDetail: payload.customer };
    }

    case CUSTOMER_LIST_SUCCESS:
      return { ...state, customers: payload.customers };
    case CUSTOMER_LIST_FAILURE:
      return { ...state };

    case CUSTOMER_LIST_BY_ROLE:
      return { ...state, customersByRole: [] };
    case CUSTOMER_LIST_BY_ROLE_SUCCESS:
      return { ...state, customersByRole: payload.customersByRole };
    case CUSTOMER_LIST_BY_ROLE_FAILURE:
      return { ...state };

    case CUSTOMER_DELETE_SUCCESS:
      return {
        ...state,
        customers: state.customers.filter((item) => item.id !== payload.id),
      };

    default:
      return { ...state };
  }
}
